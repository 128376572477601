import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
//
import { Menu } from '@uikit/Menu';
import { NavLi } from '@blocks/navLi';
import { PATH_NAMES } from '@data/dict';
import { Dropdown } from '@uikit/Dropdown';
import { storageDb } from '@api/storageApi';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { CONNECTION_STATUSES } from '@models/index';
import { ROUTES_ACCESS_RIGHTS } from '@const/access';
import { MenuDivider } from '@uikit/Menu/MenuDivider';
import { getPayPageState } from '@redux/pay/selectors';
import { FeedbackComponent } from '@components/feedback';
import { getMailingData } from '@redux/mailing/selectors';
import { getFilialName, getLinkHref } from '@helpers/index';
import { getAccountsData } from '@redux/accounts/selectors';
import { getAccounts } from '@redux/accounts/accountsThunks';
import { useAppDispatch, useAppSelector } from '@store/store';
import { ACCOUNT_NO_CONNECTION_STATUS } from '@const/accounts';
import { moveToSendOutStart } from '@redux/mailing/mailingSlice';
import { setSelectedFilial } from '@redux/accounts/accountsSlice';
// eslint-disable-next-line
import { ABCommercialSideNav } from '@blocks/abCommercialSideNav';
import { ABNewCommercialSideNav } from '@blocks/abNewCommercialSideNav';
import { ReviewOnMarketplaceBanner } from '@blocks/reviewOnMarketplaceBanner';
import { REACH_GOAL_EVENT_NAME, YANDEX_METRIKA_ID } from '@const/yandexMetrika';

interface ISidedNavProps {
  /**
   * Флаг показывает или скрывает пункт меню "Список операций"
   * @param {boolean}
   */
  isSalon: boolean;
  /**
   * Опциональный флаг показывает или скрывает пункт меню "Филиалы"
   * @param {boolean}
   */
  isMobile?: boolean;
  /**
   * Callback вызывается при нажатии на пункт меню
   * @param {() => void}
   */
  onClickHandler: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const SidedNav = memo(
  ({ isSalon, isMobile, onClickHandler, className = '' }: ISidedNavProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const pathName = useLocation().pathname;
    const [isMailingOpen, setMailingMenuOpen] = useState<boolean>(false);
    const [isFriendOpen, setFriendMenuOpen] = useState<boolean>(false);
    const [isTemplateOpen, setTemplateMenuOpen] = useState<boolean>(false);
    const [isPartnerMenuOpen, setPartnerMenuOpen] = useState<boolean>(false);
    const [isSettingMenuOpen, setSettingMenuOpen] = useState<boolean>(false);

    // получаем из LS флаг является ли выбранный аккаунт аккаунтом рассылкой
    const { sendOutAccount: isSendOutAccount } = storageDb.getSelectedFilialData();
    const {
      data,
      isRetries,
      selectedFilial,
      isSalonConsultant,
      isLoading: isFilialListFetching,
    } = useAppSelector(getAccountsData);
    const { isShowCalendar } = useAppSelector(getMailingData);
    const {
      data: { reviewBanner },
    } = useAppSelector(getPayPageState);

    const accId = selectedFilial?.accId || '';

    const isGetConnectionStatus = pathName !== PATH_NAMES.CONNECTION;

    const isInstanceStatusGreen = selectedFilial?.status === CONNECTION_STATUSES.AUTHENTICATED;

    // Получаем массив прав если он есть и проверяем наличие нобходимых прав
    const rights = selectedFilial?.rights;
    const isOwner = rights?.includes(ROUTES_ACCESS_RIGHTS.OWNER);
    const isConnection = rights?.includes(ROUTES_ACCESS_RIGHTS.MANAGE_WAPP);
    const isSettings = rights?.includes(ROUTES_ACCESS_RIGHTS.TRANSLATION_SWITCH);
    const isMailing = selectedFilial?.rights.includes(ROUTES_ACCESS_RIGHTS.SENDOUTS);

    const isShowMenuForSalon = isSalon || isSalonConsultant;

    const isShowLoyaltyMenu = selectedFilial?.testLoyalty || false;

    // Закрывает меню в мобильном разрешении
    const closeMenu = useCallback(() => {
      if (isMobile) onClickHandler();
    }, [isMobile, onClickHandler]);

    // Тогл для открытия выпадающего списка в меню Рассылки
    const mailingMenuToggle = useCallback(() => {
      setMailingMenuOpen(prevState => !prevState);
    }, []);

    // Тогл для открытия выпадающего списка в меню Приведи друга
    const friendMenuToggle = useCallback(() => {
      setFriendMenuOpen(prevState => !prevState);
    }, []);

    // Тогл для открытия выпадающего списка в меню Шаблоны
    const templateMenuToggle = useCallback(() => {
      setTemplateMenuOpen(prevState => !prevState);
    }, []);

    // Тогл для открытия выпадающего меню Партнерской программы
    // eslint-disable-next-line
    const partnerMenuToggle = useCallback(() => {
      setPartnerMenuOpen(prevState => !prevState);
    }, []);

    // Тогл для открытия выпадающего меню настроек
    const settingsMenuToggle = useCallback(() => {
      if (isOwner) {
        setSettingMenuOpen(prevState => !prevState);
      }
    }, [isOwner]);

    // Открывает ландинг бютибота в новом окне
    // const changePageOnClick = useCallback(() => {
    //   const target = '_blank';
    //   const newWindow = window.open(
    //     CONFIG.ADMIN_BOT_LANDING_PAGE_LINK,
    //     target,
    //     'noopener,noreferrer',
    //   );
    //   if (newWindow) {
    //     newWindow.opener = null;
    //   }
    // }, []);

    const { bannerData } = useAppSelector(getAccountsData);

    const changeNewCommercialOnClick = useCallback(() => {
      try {
        // eslint-disable-next-line
        // @ts-ignore
        ym(YANDEX_METRIKA_ID, 'reachGoal', REACH_GOAL_EVENT_NAME.BANNER_BUTTON_CLICK);
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      }

      const target = '_blank';
      const newWindow = window.open(bannerData.infoUrl, target, 'noopener,noreferrer');
      if (newWindow) {
        newWindow.opener = null;
      }
    }, [bannerData.infoUrl]);

    // Запрашивает состояние инстанса для отображения статуса в блоке выбора филиала
    useEffect(() => {
      if (selectedFilial?.status === ACCOUNT_NO_CONNECTION_STATUS.N_A && isGetConnectionStatus) {
        dispatch(getAccounts({ checkStatus: isGetConnectionStatus }));
      }
    }, [selectedFilial?.accId, selectedFilial?.status, isGetConnectionStatus, dispatch]);

    // Запрашивает список филиалов при рендеренге компонента если нет списка филиалов в store
    useEffect(() => {
      if (
        !data.length &&
        !(
          pathName === PATH_NAMES.START_PAGE ||
          pathName === PATH_NAMES.AUTO_CONNECT ||
          pathName === PATH_NAMES.AUTO_CONNECT_FORM ||
          pathName === PATH_NAMES.YCLIENTS_START_PAGE
        )
      ) {
        dispatch(getAccounts({ checkStatus: isGetConnectionStatus }));
      }
    }, [pathName, data, dispatch, isGetConnectionStatus]);

    // Запрашивает список филиалов повторно при ошибке токена и если нет списка филиалов в store
    useEffect(() => {
      if (isRetries && !data.length) {
        setTimeout(() => dispatch(getAccounts({ checkStatus: isGetConnectionStatus })), 100);
      }
    }, [dispatch, isRetries, data, isGetConnectionStatus]);

    // Эффект открывает нужные пункты меню в зависимости от URL
    useEffect(() => {
      if (
        pathName.includes(PATH_NAMES.TEMPLATE_LIST) ||
        pathName.includes(PATH_NAMES.TEMPLATE) ||
        pathName.includes(PATH_NAMES.NEW_WR_TEMPLATE) ||
        pathName.includes(PATH_NAMES.REVIEWS) ||
        pathName.includes(PATH_NAMES.REVIEW) ||
        pathName.includes(PATH_NAMES.NEW_REVIEW) ||
        pathName.includes(PATH_NAMES.NEW_TEMPLATE) ||
        pathName.includes(PATH_NAMES.TEMPLATE_LIST_STATISTICS)
      ) {
        setTemplateMenuOpen(true);
      } else {
        setTemplateMenuOpen(false);
      }
      if (pathName.includes(PATH_NAMES.INVITE_FRIENDS)) {
        setFriendMenuOpen(true);
      } else {
        setFriendMenuOpen(false);
      }
      if (
        pathName.includes(PATH_NAMES.MAILING) ||
        pathName.includes(PATH_NAMES.STARTED_MAILING) ||
        pathName.includes(PATH_NAMES.STATISTICS) ||
        pathName.includes(PATH_NAMES.MAILING_DETAIL_STATS)
      ) {
        setMailingMenuOpen(true);
      } else {
        setMailingMenuOpen(false);
      }
      if (
        (pathName.includes(PATH_NAMES.SETTINGS) || pathName.includes(PATH_NAMES.ACCESSES)) &&
        isOwner
      ) {
        setSettingMenuOpen(true);
      } else {
        setSettingMenuOpen(false);
      }
      if (
        pathName.includes(PATH_NAMES.PARTNERS_PROGRAM) ||
        pathName.includes(PATH_NAMES.LIST_OF_OPERATION) ||
        pathName.includes(PATH_NAMES.PAYMENT_HISTORY) ||
        pathName.includes(PATH_NAMES.ADD_LEADS)
      ) {
        setPartnerMenuOpen(true);
      } else {
        setPartnerMenuOpen(false);
      }
    }, [pathName, isOwner]);

    // Эффект при перезагрузке страницы считывает записанный в LS номер филиала
    // находит филиал в списке и записывает данные выбранного филиала в state
    useEffect(() => {
      if (!accId && data.length) {
        const { accId: accIdFromDB } = storageDb.getSelectedFilialData();
        const filial = data.filter(item => item.accId === accIdFromDB)[0];
        if (filial && accIdFromDB !== accId) {
          dispatch(
            setSelectedFilial({
              accId: filial.accId,
              branchId: filial.branchId,
              filialName: filial.address,
              isSendOutAccount: filial.sendOutAccount,
              rights: filial.rights,
            }),
          );
          // amplitudeSetUserId(filial.branchId);
        }
      }
    }, [isFilialListFetching, accId, dispatch, data]);

    // Определяем находимся ли мы на странице задачи или новой задачи
    const isTask = useMemo(
      () => pathName.includes(PATH_NAMES.TEMPLATE) || pathName.includes(PATH_NAMES.REVIEW),
      [pathName],
    );

    // Записывает данные выбранного филиала в state
    const selectFilial = useCallback(
      (selectedFilialAccId: string) => {
        const { pathname } = window.location;
        const [filial] = data.filter(item => item.accId === selectedFilialAccId);
        dispatch(
          setSelectedFilial({
            accId: filial.accId,
            branchId: filial.branchId,
            filialName: filial.address,
            isSendOutAccount: filial.sendOutAccount,
            rights: filial.rights,
          }),
        );
        // amplitudeSetUserId(filial.branchId);

        navigate(getLinkHref({ route: pathname, accId: selectedFilialAccId }));
      },
      [data, dispatch, navigate],
    );

    // Если мы находимся на странице задачи или новой задачи, при изменении филиала
    // переводим пользователя на список регулярных задач
    const selectFilialHandler = useCallback(
      (selectedFilialAccId: string) => () => {
        if (isTask) {
          selectFilial(selectedFilialAccId);
          navigate(
            getLinkHref({ route: PATH_NAMES.TEMPLATE_LIST_ALL, accId: selectedFilialAccId }),
          );
        } else {
          selectFilial(selectedFilialAccId);
        }
      },
      [isTask, selectFilial, navigate],
    );

    // Подготавливает список филиалов для отображения
    const filialList = useMemo(
      () =>
        data.map(item => (
          <MenuItem
            key={item.accId}
            text={<span>{item.address}</span>}
            onClick={selectFilialHandler(item.accId)}
            className={`${accId === item.accId ? '!bg-ivory' : ''} z-[51]`}
          />
        )),
      [data, selectFilialHandler, accId],
    );

    // Обрезает длинное название филиала до 21 символа и добавляет ...
    const filialName = useMemo(
      () => getFilialName(selectedFilial?.address || ''),
      [selectedFilial?.address],
    );

    const moveToSendOutStartHandler = useCallback(() => {
      closeMenu();
      dispatch(moveToSendOutStart());
    }, [closeMenu, dispatch]);

    const memoizedDropDown = useMemo(
      () => (
        <Dropdown
          view='outlined'
          color='success'
          position='bottom-left'
          className={`w-[17.5rem] !bg-left-4 ${
            isInstanceStatusGreen ? '!bg-connected' : '!bg-connectionError'
          } !bg-no-repeat z-[51]`}
          text={<span>{filialName}</span>}
          title={selectedFilial?.address}
          content={
            <Menu
              className={`${filialList.length === 1 ? `one_item_ul` : ''} z-[51]`}
              view='raised'>
              {filialList}
            </Menu>
          }
        />
      ),
      [filialName, selectedFilial?.address, isInstanceStatusGreen, filialList],
    );

    const animatedHeight = 'transition-max-height duration-700 ease-in-out';

    return (
      <div className={`flex-col z-[51] ${isShowCalendar ? '!hidden' : ''} ${className}`}>
        {isShowMenuForSalon ? <div>{memoizedDropDown}</div> : null}
        {(isOwner || isConnection) && isShowMenuForSalon ? (
          <MenuDivider color='default' className='!mt-[1.5rem] !mb-[0.5rem] !mx-0' />
        ) : null}
        <nav className='min-w-[17.5rem]'>
          <ul className='w-full'>
            {isMobile && isShowMenuForSalon ? (
              <NavLi
                onClick={closeMenu}
                href={PATH_NAMES.ACCOUNTS}
                active={pathName.includes(PATH_NAMES.ACCOUNTS)}>
                {t('BASE_TEMPLATE_PAGE_TEXT.menu.filials')}
              </NavLi>
            ) : null}

            {/* Настройка доступа к разделу подключения для админа или по специальному разрешению */}

            {(isOwner || isConnection) && isShowMenuForSalon ? (
              <NavLi
                onClick={closeMenu}
                active={pathName.includes(PATH_NAMES.CONNECTION)}
                href={getLinkHref({ route: PATH_NAMES.CONNECTION, accId })}>
                {t('BASE_TEMPLATE_PAGE_TEXT.menu.connection')}
              </NavLi>
            ) : null}

            {!isSendOutAccount && isShowMenuForSalon ? (
              <>
                <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
                <NavLi
                  isDropDown
                  onClick={closeMenu}
                  isOpen={isTemplateOpen}
                  toggleDropdown={templateMenuToggle}
                  href={getLinkHref({ route: PATH_NAMES.TEMPLATE_LIST_ALL, accId })}
                  active={pathName.includes(PATH_NAMES.TEMPLATE_LIST_ALL)}>
                  {t('BASE_TEMPLATE_PAGE_TEXT.menu.templates')}
                </NavLi>
                <div
                  className={`${
                    isTemplateOpen ? 'max-h-[10rem]' : 'max-h-0'
                  } overflow-hidden ${animatedHeight}`}>
                  <NavLi
                    isSecondary
                    onClick={closeMenu}
                    active={pathName.includes(PATH_NAMES.TEMPLATE_LIST_EVENT)}
                    href={getLinkHref({ route: PATH_NAMES.TEMPLATE_LIST_EVENT, accId })}>
                    {t('BASE_TEMPLATE_PAGE_TEXT.menu.eventTemplates')}
                  </NavLi>
                  <NavLi
                    isSecondary
                    onClick={closeMenu}
                    active={pathName.includes(PATH_NAMES.TEMPLATE_LIST_TIME)}
                    href={getLinkHref({ route: PATH_NAMES.TEMPLATE_LIST_TIME, accId })}>
                    {t('BASE_TEMPLATE_PAGE_TEXT.menu.timeTemplates')}
                  </NavLi>
                  <NavLi
                    isSecondary
                    onClick={closeMenu}
                    href={getLinkHref({ route: PATH_NAMES.REVIEWS, accId })}
                    active={
                      pathName.includes(PATH_NAMES.REVIEWS) || pathName.includes(PATH_NAMES.REVIEW)
                    }>
                    {t('BASE_TEMPLATE_PAGE_TEXT.menu.reviews')}
                  </NavLi>
                  <NavLi
                    isSecondary
                    onClick={closeMenu}
                    href={getLinkHref({ route: PATH_NAMES.TEMPLATE_LIST_STATISTICS, accId })}
                    active={
                      pathName.includes(PATH_NAMES.TEMPLATE_LIST_STATISTICS) ||
                      pathName.includes(PATH_NAMES.TEMPLATE_STAT)
                    }>
                    {t('BASE_TEMPLATE_PAGE_TEXT.menu.templatesStatistics')}
                  </NavLi>
                </div>
              </>
            ) : null}

            {/* Настройка доступа к разделу рассылок для админа или по специальному разрешению */}

            {(isOwner || isMailing) && isShowMenuForSalon ? (
              <>
                <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
                <NavLi
                  isDropDown
                  onClick={closeMenu}
                  isOpen={isMailingOpen}
                  toggleDropdown={mailingMenuToggle}
                  href={getLinkHref({ route: PATH_NAMES.MAILING, accId })}>
                  {t('BASE_TEMPLATE_PAGE_TEXT.menu.sendOut')}
                </NavLi>
                <div
                  className={`${
                    isMailingOpen ? 'max-h-[7.5rem]' : 'max-h-0'
                  } overflow-hidden ${animatedHeight}`}>
                  <NavLi
                    isSecondary
                    onClick={moveToSendOutStartHandler}
                    active={pathName.includes(PATH_NAMES.MAILING)}
                    href={getLinkHref({ route: PATH_NAMES.MAILING, accId })}>
                    {t('BASE_TEMPLATE_PAGE_TEXT.menu.newSendOut')}
                  </NavLi>
                  <NavLi
                    isSecondary
                    onClick={closeMenu}
                    active={pathName.includes(PATH_NAMES.STARTED_MAILING)}
                    href={getLinkHref({ route: PATH_NAMES.STARTED_MAILING, accId })}>
                    {t('BASE_TEMPLATE_PAGE_TEXT.menu.startedSendOut')}
                  </NavLi>
                  <NavLi
                    isSecondary
                    onClick={closeMenu}
                    active={
                      pathName.includes(PATH_NAMES.STATISTICS) ||
                      pathName.includes(PATH_NAMES.MAILING_DETAIL_STATS)
                    }
                    href={getLinkHref({ route: PATH_NAMES.STATISTICS, accId })}>
                    {t('BASE_TEMPLATE_PAGE_TEXT.menu.sendOutStatistics')}
                  </NavLi>
                </div>
              </>
            ) : null}
            {!isSendOutAccount && isShowMenuForSalon && isShowLoyaltyMenu ? (
              <>
                <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
                <NavLi
                  isDropDown
                  onClick={closeMenu}
                  isOpen={isFriendOpen}
                  toggleDropdown={friendMenuToggle}
                  href={getLinkHref({ route: PATH_NAMES.INVITE_FRIEND_LIST, accId })}>
                  {t('BASE_TEMPLATE_PAGE_TEXT.menu.inviteFriend')}
                </NavLi>
                <div
                  className={`${
                    isFriendOpen ? 'max-h-[7.5rem]' : 'max-h-0'
                  } overflow-hidden ${animatedHeight}`}>
                  <NavLi
                    isSecondary
                    onClick={moveToSendOutStartHandler}
                    active={pathName.includes(PATH_NAMES.INVITE_FRIEND_LIST)}
                    href={getLinkHref({ route: PATH_NAMES.INVITE_FRIEND_LIST, accId })}>
                    {t('BASE_TEMPLATE_PAGE_TEXT.menu.inviteFriendTemplates')}
                  </NavLi>
                  <NavLi
                    isSecondary
                    onClick={closeMenu}
                    active={pathName.includes(PATH_NAMES.INVITE_FRIEND_STATISTICS)}
                    href={getLinkHref({ route: PATH_NAMES.INVITE_FRIEND_STATISTICS, accId })}>
                    {t('BASE_TEMPLATE_PAGE_TEXT.menu.inviteFriendStatistics')}
                  </NavLi>
                </div>
              </>
            ) : null}
            {!isSendOutAccount && isShowMenuForSalon ? (
              <>
                <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
                <NavLi
                  active={pathName.includes(PATH_NAMES.AUTO_RESPONSE)}
                  href={getLinkHref({ route: PATH_NAMES.AUTO_RESPONSE, accId })}>
                  {t('BASE_TEMPLATE_PAGE_TEXT.menu.autoResponse')}
                </NavLi>
              </>
            ) : null}
            {isShowMenuForSalon ? (
              <>
                <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
                <NavLi
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.BLACKLIST)}
                  href={getLinkHref({ route: PATH_NAMES.BLACKLIST, accId })}>
                  {t('BASE_TEMPLATE_PAGE_TEXT.menu.blacklist')}
                </NavLi>
              </>
            ) : null}

            {/* Настройка доступа к разделу настроек для админа или по специальному разрешению */}

            {(isOwner || isSettings) && isShowMenuForSalon ? (
              <>
                <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
                <NavLi
                  isDropDown={isOwner}
                  onClick={closeMenu}
                  isOpen={isSettingMenuOpen}
                  toggleDropdown={settingsMenuToggle}
                  active={pathName.includes(PATH_NAMES.SETTINGS)}
                  href={getLinkHref({ route: PATH_NAMES.SETTINGS, accId })}>
                  {t('BASE_TEMPLATE_PAGE_TEXT.menu.settings')}
                </NavLi>
                <div
                  className={`${
                    isSettingMenuOpen ? 'max-h-[2.5rem]' : 'max-h-0'
                  } overflow-hidden ${animatedHeight}`}>
                  <NavLi
                    isSecondary
                    onClick={closeMenu}
                    active={pathName.includes(PATH_NAMES.ACCESSES)}
                    href={getLinkHref({ route: PATH_NAMES.ACCESSES, accId })}>
                    {t('BASE_TEMPLATE_PAGE_TEXT.menu.access')}
                  </NavLi>
                </div>
              </>
            ) : null}

            <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
            <NavLi
              isDropDown
              onClick={closeMenu}
              isOpen={isPartnerMenuOpen}
              toggleDropdown={partnerMenuToggle}
              href={getLinkHref({ route: PATH_NAMES.PARTNERS_PROGRAM, accId })}>
              {t('BASE_TEMPLATE_PAGE_TEXT.menu.partnersProgram')}
            </NavLi>
            <div
              className={`${
                isPartnerMenuOpen
                  ? isSalonConsultant || !isSalonConsultant
                    ? 'max-h-[10rem]'
                    : 'max-h-[5rem]'
                  : 'max-h-0'
              } overflow-hidden ${animatedHeight}`}>
              <NavLi
                isSecondary
                onClick={closeMenu}
                href={getLinkHref({ route: PATH_NAMES.PARTNERS_PROGRAM, accId })}
                active={pathName.includes(PATH_NAMES.PARTNERS_PROGRAM)}>
                {t('BASE_TEMPLATE_PAGE_TEXT.menu.conditionsAndPartners')}
              </NavLi>
              {!isSalon || isSalonConsultant ? (
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.LIST_OF_OPERATION)}
                  href={getLinkHref({ route: PATH_NAMES.LIST_OF_OPERATION, accId })}>
                  {t('BASE_TEMPLATE_PAGE_TEXT.menu.operationList')}
                </NavLi>
              ) : null}
              <NavLi
                isSecondary
                onClick={closeMenu}
                active={pathName.includes(PATH_NAMES.PAYMENT_HISTORY)}
                href={getLinkHref({ route: PATH_NAMES.PAYMENT_HISTORY, accId })}>
                {t('BASE_TEMPLATE_PAGE_TEXT.menu.payHistory')}
              </NavLi>
              {!isSalon || isSalonConsultant ? (
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.ADD_LEADS)}
                  href={getLinkHref({ route: PATH_NAMES.ADD_LEADS, accId })}>
                  {t('BASE_TEMPLATE_PAGE_TEXT.menu.addLeads')}
                </NavLi>
              ) : null}
            </div>
            {isShowMenuForSalon ? (
              <>
                <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
                <NavLi
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.CHAT_HISTORY)}
                  href={`${PATH_NAMES.CHAT_HISTORY}?acc_id=${selectedFilial?.accId}`}>
                  {t('BASE_TEMPLATE_PAGE_TEXT.menu.chatHistory')}
                </NavLi>
              </>
            ) : null}
            {isShowMenuForSalon ? (
              <>
                <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
                {/*  */}
                <NavLi
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.PAY)}
                  href={getLinkHref({ route: PATH_NAMES.PAY, accId })}>
                  {t('BASE_TEMPLATE_PAGE_TEXT.menu.payment')}
                </NavLi>
                <MenuDivider color='default' className='!my-[0.5rem] !mx-0 !border-t-4' />
              </>
            ) : null}
            {/* {!selectedFilial?.isAdminBotIntegrated ? (
              <ABCommercialSideNav changePageOnClick={changePageOnClick} />
            ) : null} */}
            {bannerData.imageUrl && isShowMenuForSalon ? (
              <ABNewCommercialSideNav
                imgURL={bannerData.imageUrl}
                changePageOnClick={changeNewCommercialOnClick}
                className='mt-2'
              />
            ) : null}
            {pathName.includes(PATH_NAMES.PAY) && reviewBanner ? (
              <ReviewOnMarketplaceBanner className='mt-6' />
            ) : null}
          </ul>
        </nav>
        <FeedbackComponent className='my-8' />
      </div>
    );
  },
);

SidedNav.displayName = 'SidedNav';
