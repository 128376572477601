import { PATH_NAMES } from '@data/dict';
import { TTemplateListData } from '@api/types';
import { QUERY_PARAMS } from '@const/apiConstants';
import { TTemplateInfo } from '@redux/templateList/types';

/**
 * Функция для преобразования ответа сервера с данными страницы списка шаблонов для хранилища Redux.
 *
 * @param {TTemplateListData[]} data - Ответ сервера с данными страницы списка шаблонов.
 * @param {string} accId - Идентификатор аккаунта.
 * @returns {TTemplateInfo[]} - Массив объектов с преобразованными данными шаблонов для хранилища Redux.
 */
const getMappedTemplateListPageResponse = (
  data: TTemplateListData[],
  accId: string,
): TTemplateInfo[] => {
  return data.map(({ active, id, title, name, time, event, time_mark, send_only_lost_client }) => ({
    active,
    id,
    title,
    name,
    time,
    event,
    templateSendTime: time_mark,
    sendOnlyLostClient: send_only_lost_client,
    link: `${PATH_NAMES.TEMPLATE}?${[QUERY_PARAMS.ACC_ID]}=${accId}&${QUERY_PARAMS.ID}=${id.slice(
      1,
    )}`,
  }));
};

export { getMappedTemplateListPageResponse };
