import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Visits } from '@blocks/visits';
import { EmojiClickData } from 'emoji-picker-react';
import { VisitQuantity } from '@blocks/visitQuantity';
import { DivWithLabel } from '@components/divWithLabel';
import { TEXTAREA_TEXT_MAX_LENGTH } from '@const/common';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getTemplatePageData } from '@redux/template/selectors';
import { FormBlackListInput } from '@components/formBlackListInput';
import { TemplateToggleSwitch } from '@blocks/templateToggleSwitch';
import { TemplateRecordStatus } from '@blocks/templateRecordStatus';
import { TEMPLATE_DATA_KEY_NAMES, TTemplatePageData } from '@redux/template/models';
import { TemplateInputWithDropdownList } from '@components/templateInputWithDropdownList';
import {
  TIME_PERIODS,
  WHEN_SEND_VALUE,
  WHEN_SEND_2_VALUES,
  TEMPLATE_EVENT_KEY_NAME,
} from '@const/templates';
import {
  setVisits,
  addBalloonTP,
  selectOnchange,
  deleteBalloonTP,
  setVisitQuantity,
  setNewBalloonValueTP,
  setSendOnlyLostClient,
} from '@redux/template/templateSlice';
import {
  TBalloonsKeyNames,
  TDivOnClickHandler,
  TDivOnKeyUpHandler,
  TDivOnInputHandler,
  TOnEmojiViewChange,
  TSetViewEmojiPicker,
  TDeleteBalloonSetTP,
  TSimpleOnchangeHandler,
  TSetSendOnlyLostClient,
  TVisitQuantityOnChangeHandler,
} from '@shared/types';

type TTemplateSendConditionProps = {
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Флаг успешного завершения получения данных
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * Callback срабатывает ввод текста
   * @param {TDivOnInputHandler}
   */
  divOnInputHandler: TDivOnInputHandler;
  /**
   * Callback срабатывает на клик по диву
   * @param {TDivOnClickHandler}
   */
  divOnClickHandler: TDivOnClickHandler;
  /**
   * Callback устанавливает положение курсора
   * @param {TDivOnKeyUpHandler}
   */
  setCursorPosition: TDivOnKeyUpHandler;
  /**
   * Опциональный параметр callback для записи выбранного эмодзи в textarea
   * @param {(emoji: EmojiClickData, event: MouseEvent) => void}
   */
  setEmojiInTextarea?: (emoji: EmojiClickData, event: MouseEvent) => void;
  /**
   * Опциональный параметр
   * @param {TSetViewEmojiPicker}
   */
  setViewEmojiPicker?: TSetViewEmojiPicker;
  /**
   * Опциональный параметр callback для открытия emojiPicker
   * @param {() => void}
   */
  onEmojiViewChange: TOnEmojiViewChange;
  /**
   * Данные шаблона
   * @param {TTemplatePageData}
   */
  data: TTemplatePageData;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const TemplateSendCondition = ({
  divOnClickHandler,
  setCursorPosition,
  divOnInputHandler,
  onEmojiViewChange,
  isFetchingSuccess,
  setEmojiInTextarea,
  setViewEmojiPicker,
  selectedFilialAccId,
  isEditTemplatesAccess,
  data,
  className = '',
}: TTemplateSendConditionProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    visits,
    textSms,
    whenSend,
    whenSend2,
    blacklist,
    clientCame,
    quantityTo,
    textCancel,
    quantityFrom,
    onlineRecord,
    confirmRecord,
    whenSend2Days,
    clientConfirmed,
    textConfirmation,
    clientDidNotCome,
    clientExpectation,
    sendOnlyLostClient,
  } = data;

  const { specialKeyDict, textareaKeysData, blacklistNewValue } =
    useAppSelector(getTemplatePageData);

  // Обработчик изменения чекбоксов
  const selectOnchangeHandler: TSimpleOnchangeHandler = useCallback(
    event => {
      const { name, checked } = event.currentTarget;
      dispatch(selectOnchange({ name, checked }));
    },
    [dispatch],
  );

  // Удаляет "шарик" по его значению
  const deleteBalloon: TDeleteBalloonSetTP = useCallback(
    (value, keyName) => () => {
      const tKeyName = keyName as TBalloonsKeyNames;
      dispatch(deleteBalloonTP({ keyName: tKeyName, value }));
    },
    [dispatch],
  );

  // Обрабатывает изменения количества визитов
  const visitQuantityOnChangeHandler: TVisitQuantityOnChangeHandler = useCallback(
    ({ newQuantityFromValue, newQuantityToValue }) => {
      dispatch(
        setVisitQuantity({
          quantityFrom: newQuantityFromValue,
          quantityTo: newQuantityToValue,
        }),
      );
    },
    [dispatch],
  );

  // Обрабатывает изменения visits
  const visitsOnChangeHandler = useCallback(
    (value: string) => {
      dispatch(setVisits({ value }));
    },
    [dispatch],
  );

  // Обрабатывает изменение newBalloonValue для поля добавления в ЧС
  const newBlackListBalloonValueHandler = useCallback(
    (newValue: string) => {
      dispatch(setNewBalloonValueTP({ value: newValue, name: TEMPLATE_DATA_KEY_NAMES.BLACKLIST }));
    },
    [dispatch],
  );

  // Добавляет новый по событию Blur шарик и очищает инпут
  const addBalloonToBlackListOnBlurHandlerEvent = useCallback(
    (newValue: string) => {
      if (newValue.length > 10) {
        dispatch(addBalloonTP({ keyName: TEMPLATE_DATA_KEY_NAMES.BLACKLIST, value: newValue }));
      }
    },
    [dispatch],
  );

  // Обрабатывает изменения переключателя "Отправлять только потеряшимся клиентам"
  const setSendOnlyLostClientHandler: TSetSendOnlyLostClient = useCallback(
    isAfterEvent => event => {
      const { checked } = event.currentTarget;
      dispatch(setSendOnlyLostClient({ checked, isAfterEvent }));
    },
    [dispatch],
  );

  const hideSendConditions =
    (whenSend === WHEN_SEND_VALUE.EVENT && whenSend2 === TEMPLATE_EVENT_KEY_NAME.INTEGRATION) ||
    whenSend === WHEN_SEND_VALUE.BIRTHDAY;

  const shouldRenderTemplateRecordStatus = !(
    whenSend2 === TEMPLATE_EVENT_KEY_NAME.RECORD_CANCELED ||
    whenSend2 === TEMPLATE_EVENT_KEY_NAME.RECORD_DONE
  );

  // Параметр указывает что шаблон отправляется после события или нет
  const isAfter = useMemo(
    () =>
      (whenSend === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION &&
        whenSend2 === WHEN_SEND_2_VALUES.ALL_DAY_REC_AFTER &&
        +whenSend2Days >= TIME_PERIODS.WEEK_IN_DAYS) ||
      (whenSend === WHEN_SEND_VALUE.AFTER_END && +whenSend2Days >= TIME_PERIODS.WEEK_IN_HOURS),
    [whenSend, whenSend2, whenSend2Days],
  );

  // Параметр указывает что шаблон отправляется до события или нет
  const isBefore = useMemo(
    () => WHEN_SEND_VALUE.BEFORE_START === whenSend || +whenSend2 >= 0,
    [whenSend, whenSend2],
  );

  // Параметр указывает что шаблон является шаблоном по времени
  const isTimeEvent = useMemo(
    () => WHEN_SEND_VALUE.BEFORE_START === whenSend || WHEN_SEND_VALUE.AFTER_END === whenSend,
    [whenSend],
  );

  // Параметр указывает что шаблон отправляется всем у кого есть запись
  const isAllDayRecsNotifications = useMemo(
    () => WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION === whenSend,
    [whenSend],
  );

  const isShowConfirmRecordBlock =
    whenSend === WHEN_SEND_VALUE.BEFORE_START ||
    (whenSend === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION && isBefore);

  const isShowConformRecordTextBlock =
    (confirmRecord && whenSend === WHEN_SEND_VALUE.BEFORE_START) ||
    (whenSend === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION && isBefore && confirmRecord);

  const isBirthday = whenSend === WHEN_SEND_VALUE.BIRTHDAY;

  const isDisableClientDidNotCome = isAfter && sendOnlyLostClient;

  return (
    <div className={className}>
      {hideSendConditions ? null : (
        <>
          <p className='font-medium text-black text-h3Mobile mt-10 mb-4'>
            {t('TEMPLATE_PAGE_TEXT.sendCondition')}
          </p>
          {shouldRenderTemplateRecordStatus ? (
            <TemplateRecordStatus
              isAfter={isAfter}
              isBefore={isBefore}
              isTimeEvent={isTimeEvent}
              clientDidNotCome={clientDidNotCome}
              clientExpectation={clientExpectation}
              clientCame={clientCame}
              clientConfirmed={clientConfirmed}
              disabled={!isEditTemplatesAccess}
              onChangeHandler={selectOnchangeHandler}
              isDisableClientDidNotCome={isDisableClientDidNotCome}
              isAllDayRecsNotifications={isAllDayRecsNotifications}
              className='mb-8'
            />
          ) : null}
          <TemplateInputWithDropdownList
            isBirthday={false}
            isFetchingSuccess={isFetchingSuccess}
            selectedFilialAccId={selectedFilialAccId}
            isEditTemplatesAccess={isEditTemplatesAccess}
            data={data}
            className='mb-8'
          />
          <VisitQuantity
            quantityFrom={quantityFrom}
            quantityTo={quantityTo}
            disabled={!isEditTemplatesAccess}
            textColor='text-stormGray tracking-[0.0275em]'
            onChangeHandler={visitQuantityOnChangeHandler}
            className='mb-6'
          />
          <Visits
            visits={visits}
            disabled={!isEditTemplatesAccess}
            onChangeHandler={visitsOnChangeHandler}
          />
          <FormBlackListInput
            isShowInfoIcon
            value={blacklist}
            textColor='text-black'
            textSize='text-h3Mobile mb-4'
            newValue={blacklistNewValue}
            deleteBalloon={deleteBalloon}
            disabled={!isEditTemplatesAccess}
            inputId={TEMPLATE_DATA_KEY_NAMES.BLACKLIST}
            variant={TEMPLATE_DATA_KEY_NAMES.BLACKLIST}
            setNewValue={newBlackListBalloonValueHandler}
            addBalloonOnBlur={addBalloonToBlackListOnBlurHandlerEvent}
            placeholder={t('TEMPLATE_PAGE_TEXT.blacklistPlaceholder')}
            className='my-10'
          />
          <>
            <h2 className='font-medium mb-4 text-black text-[1.25rem] leading-8 tracking-[0.004em]'>
              {t('TEMPLATE_PAGE_TEXT.additionalSendConditions')}
            </h2>
            {isAfter ? (
              <>
                <TemplateToggleSwitch
                  value={sendOnlyLostClient}
                  disabled={!isEditTemplatesAccess}
                  onChangeHandler={setSendOnlyLostClientHandler(isAfter)}
                  htmlFor={TEMPLATE_DATA_KEY_NAMES.SEND_ONLY_LOST_CLIENTS}
                  headerText={t('TEMPLATE_PAGE_TEXT.sendOnlyLostClientHeader')}
                  descriptionText={t('TEMPLATE_PAGE_TEXT.sendOnlyLostClientDescription')}
                />
                <hr className='border-none bg-quartz1 h-[1px] my-5' />
              </>
            ) : null}
            <TemplateToggleSwitch
              value={onlineRecord}
              disabled={!isEditTemplatesAccess}
              onChangeHandler={selectOnchangeHandler}
              htmlFor={TEMPLATE_DATA_KEY_NAMES.ONLINE_RECORD}
              headerText={t('TEMPLATE_PAGE_TEXT.onlineRecordHeader')}
              descriptionText={t('TEMPLATE_PAGE_TEXT.onlineRecordDescription')}
              className='mb-5'
            />
            <hr className='border-none bg-quartz1 h-[1px] mb-5' />
            {isShowConfirmRecordBlock ? (
              <>
                <TemplateToggleSwitch
                  value={confirmRecord}
                  disabled={!isEditTemplatesAccess}
                  onChangeHandler={selectOnchangeHandler}
                  htmlFor={TEMPLATE_DATA_KEY_NAMES.CONFIRM_RECORD}
                  headerText={t('TEMPLATE_PAGE_TEXT.confirmRecordHeader')}
                  descriptionText={t('TEMPLATE_PAGE_TEXT.confirmRecordDescription')}
                />
                {!confirmRecord ? <hr className='border-none bg-quartz1 h-[1px] my-5' /> : null}
              </>
            ) : null}
            {isShowConformRecordTextBlock ? (
              <>
                <DivWithLabel
                  isEmoji
                  setEmojiInTextarea={setEmojiInTextarea}
                  showEmojiPicker={onEmojiViewChange({
                    textAreaName: TEMPLATE_DATA_KEY_NAMES.TEXT_CONFIRMATION,
                    textAreaValue: textSms,
                  })}
                  value={textConfirmation}
                  specialKeyDict={specialKeyDict}
                  disabled={!isEditTemplatesAccess}
                  textareaKeysData={textareaKeysData}
                  maxLength={TEXTAREA_TEXT_MAX_LENGTH}
                  divOnInputHandler={divOnInputHandler}
                  divOnClickHandler={divOnClickHandler}
                  setCursorPosition={setCursorPosition}
                  setViewEmojiPicker={setViewEmojiPicker}
                  htmlFor={TEMPLATE_DATA_KEY_NAMES.TEXT_CONFIRMATION}
                  placeholder={t('TEMPLATE_PAGE_TEXT.confirmRecordPlaceholder')}
                  className='pl-[4rem] mt-[0.875rem] mb-8'
                />
                <DivWithLabel
                  isEmoji
                  setEmojiInTextarea={setEmojiInTextarea}
                  showEmojiPicker={onEmojiViewChange({
                    textAreaName: TEMPLATE_DATA_KEY_NAMES.TEXT_CANCEL,
                    textAreaValue: textCancel,
                  })}
                  value={textCancel}
                  specialKeyDict={specialKeyDict}
                  disabled={!isEditTemplatesAccess}
                  textareaKeysData={textareaKeysData}
                  maxLength={TEXTAREA_TEXT_MAX_LENGTH}
                  divOnInputHandler={divOnInputHandler}
                  divOnClickHandler={divOnClickHandler}
                  setCursorPosition={setCursorPosition}
                  setViewEmojiPicker={setViewEmojiPicker}
                  htmlFor={TEMPLATE_DATA_KEY_NAMES.TEXT_CANCEL}
                  placeholder={t('TEMPLATE_PAGE_TEXT.cancelRecordPlaceholder')}
                  className='pl-[4rem]'
                />
                <hr className='border-none bg-quartz1 h-[1px] my-5' />
              </>
            ) : null}
          </>
        </>
      )}
      {isBirthday ? (
        <div>
          <p className='font-medium text-black text-h3Mobile mt-16 mb-4'>
            {t('TEMPLATE_PAGE_TEXT.sendCondition')}
          </p>
          <TemplateInputWithDropdownList
            isBirthday
            isFetchingSuccess={isFetchingSuccess}
            selectedFilialAccId={selectedFilialAccId}
            isEditTemplatesAccess={isEditTemplatesAccess}
            data={data}
            className='mb-8'
          />
          <VisitQuantity
            quantityTo={quantityTo}
            quantityFrom={quantityFrom}
            disabled={!isEditTemplatesAccess}
            onChangeHandler={visitQuantityOnChangeHandler}
            className='mb-8'
          />
          <FormBlackListInput
            isShowInfoIcon
            value={blacklist}
            textColor='text-black'
            textSize='text-h3Mobile mb-4'
            newValue={blacklistNewValue}
            deleteBalloon={deleteBalloon}
            disabled={!isEditTemplatesAccess}
            inputId={TEMPLATE_DATA_KEY_NAMES.BLACKLIST}
            variant={TEMPLATE_DATA_KEY_NAMES.BLACKLIST}
            setNewValue={newBlackListBalloonValueHandler}
            addBalloonOnBlur={addBalloonToBlackListOnBlurHandlerEvent}
            placeholder={t('TEMPLATE_PAGE_TEXT.blacklistPlaceholder')}
            className='my-16'
          />
        </div>
      ) : null}
    </div>
  );
};
