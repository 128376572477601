import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'moment/locale/ru';
import { useTranslation } from 'react-i18next';
import MomentLocaleUtils from 'react-day-picker/moment';
//
import { Icon } from '@atoms/icon';
import { Toaster } from '@uikit/Toast';
import { createPortal } from 'react-dom';
import { ONE_WEEK_IN_MS } from '@const/common';
import { TSetSendingDate } from '@shared/types';
import { DateRangePicker } from '@uikit/DateTime/DateRangePicker';
import { getCustomShortcuts, getDateString } from '@helpers/index';

interface IDateRangePickerProps {
  /**
   * Дата начала периода
   * @param {Date}
   */
  startDate: Date;
  /**
   * Дата окончания периода
   * @param {Date}
   */
  endDate: Date;
  /**
   * Callback для установки диапазона дат в store
   * @param {(TSetSendingDate}
   */
  setSendingDate: TSetSendingDate;
  /**
   * Минимально возможный диапазон дат
   * @param {number}
   * @default {ONE_WEEK_IN_MS}
   */
  minDateRange?: number;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const DateRangeDashboardPicker = memo(
  ({
    endDate,
    startDate,
    setSendingDate,
    minDateRange = ONE_WEEK_IN_MS,
    className = '',
  }: IDateRangePickerProps) => {
    const { t } = useTranslation();
    const [showError, setShowError] = useState<boolean>(false);
    const [dates, setDates] = useState<Date[]>([startDate, endDate]);
    const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>(false);

    const showDatePickerHandler = useCallback(() => {
      setIsShowDatePicker(prevState => !prevState);
    }, []);

    const sendDatesHandler = useCallback(() => {
      if (dates[1].getTime() - dates[0].getTime() >= minDateRange) {
        setSendingDate({ startDate: dates[0], endDate: dates[1] });
        showDatePickerHandler();
      } else {
        setShowError(true);
      }
    }, [minDateRange, dates, setSendingDate, showDatePickerHandler]);

    const setDateHandler = useCallback(([start, end]: Date[]) => {
      setDates([start, end]);
      setShowError(false);
    }, []);

    // Объект настроек errorToastMessage
    const errorToast = useMemo(
      () => ({
        message: t('TEMPLATE_PAGE_TEXT.statistics.dateSelectError'),
        icon: <Icon variant='errorToast' className='ml-4' />,
        onDismiss: () => {
          setShowError(false);
        },
        timeout: 0,
      }),
      [t],
    );

    // реф для сообщения об ошибке
    const errorToastRef = useRef(errorToast);

    // Эффект показывает сообщение об ошибке при сохранении настроек
    useEffect(() => {
      if (showError) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        errorToastRef.current.show(errorToast);
      }
    }, [showError, errorToast]);

    const memoizedErrorToast = useMemo(
      () => (
        <Toaster
          maxToasts={1}
          view='smooth'
          color='danger'
          className='error'
          ref={errorToastRef}
          position='top-right'
          withoutClose={false}
        />
      ),
      [errorToastRef],
    );

    const getShortcuts = useMemo(() => getCustomShortcuts(), []);

    const dateRange = `${getDateString(startDate)} - ${getDateString(endDate)}`;

    return (
      <div className={`relative ${className}`}>
        <div
          onClick={showDatePickerHandler}
          className='border border-dGray rounded cursor-pointer flex items-center lg:justify-between text-blackText text-body2 leading-6 tracking-[0.0275em] px-3 h-10 w-full min-w-[16rem]'>
          <Icon variant='calendar' className='mr-2' />
          {dateRange}
        </div>
        {isShowDatePicker ? (
          <DateRangePicker
            locale='ru'
            view='raised'
            value={dates}
            color='success'
            highlightCurrentDay
            shortcuts={getShortcuts}
            onChange={setDateHandler}
            localeUtils={MomentLocaleUtils}
            className='!absolute top-12 z-[50] flex flex-col xl:flex-row *:flex sm:*:block *:flex-col sm:*:flex-initial'
          />
        ) : null}
        {isShowDatePicker
          ? createPortal(
              <div
                onClick={sendDatesHandler}
                className='absolute top-0 right-0 bottom-0 left-0 z-[1]'
              />,
              document.body,
            )
          : null}
        {memoizedErrorToast}
      </div>
    );
  },
);

DateRangeDashboardPicker.displayName = 'DateRangeDashboardPicker';
