import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
//
import { templateKeysDict } from '@data/dict';
import { getKeysData } from '@helpers/contentMaker';
import { TFilialData } from '@redux/accounts/models';
import { FILE_TYPES, TAppLanguage } from '@models/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { emptyTemplateMockData } from '@redux/template/mockData';
import { COMMON_TOAST, REG_EXP, TEMPLATE_WARNING } from '@const/common';
import { TBalloonsKeyNames, TParseDivValueToSlice } from '@shared/types';
import { IInitialState, initialState } from '@redux/template/initialState';
import {
  TKeyData,
  TTextareaNames,
  TTemplatePageData,
  TEMPLATE_DATA_KEY_NAMES,
} from '@redux/template/models';
import {
  saveTemplateData,
  getTemplateByIdData,
  getListOfCategoriesTS,
  copyTemplateToBranchThunk,
} from '@redux/template/templateThunks';
import {
  TStaffListItem,
  TRejectResponse,
  TRejectResponse2,
  REJECT_RESPONSE_KEY,
  REJECT_RESPONSE_2_KEY,
} from '@api/types';
import {
  numWord,
  addNewLines,
  getWrapperText,
  getMediaFileType,
  getRoundedMinutes,
  isFileSizeIncorrect,
  getWhenSendSelectOption,
  createAWhenSend2ItemList,
} from '@helpers/index';
import {
  FILIALS,
  WHEN_SEND_VALUE,
  TYPE_OF_TEMPLATE,
  WHEN_SEND2_VALUE,
  CONSENT_KEY_NAMES,
  TEMPLATE_EVENT_KEY_NAME,
  TEMPLATE_NEW_BALLOON_VALUE_POSTFIX,
  WHEN_SEND2_SELECT_OPTION_VALUE_NEW,
  WHEN_SEND_SELECT_OPTION_NEW_TEMPLATE,
} from '@const/templates';

export const templateSlice = createSlice({
  name: 'templateSlice',
  initialState,
  reducers: {
    setWhenSendOption(state: IInitialState) {
      state.whenSendSelectOption = WHEN_SEND_SELECT_OPTION_NEW_TEMPLATE;
    },
    clearTemplateState(state: IInitialState) {
      state.isError = false;
      state.isLoading = false;
      state.isFetching = false;
      state.isFileTypeError = false;
      state.isCopyTemplatesError = false;
      state.isGettingDropdownListError = false;
      state.isSavingTemplate = false;
      state.isTemplateSaveError = false;
      // state.isTemplateSaveSuccess = false;
      state.isCopyTeplatesError = false;
      state.isCopyTeplatesSuccess = false;
      state.isCopyTeplatesLoading = false;
      state.selectionEnd = 0;
      state.selectionStart = 0;
      state.mediaFileTooBigError = false;
      state.blurFromTextareaName = '';
      state.servicesNewValue = '';
      state.categoriesNewValue = '';
      state.productsNewValue = '';
      state.staffNewValue = '';
      state.clientCategoryNewValue = '';
      state.blacklistNewValue = '';
      state.isAccessDenied = false;
      state.templateTestFieldsParsError = '';
      state.filialsToCopy = [];
      state.rejectedFilialsToCopy = [];
    },
    setRejectedFilialsToCopy(state: IInitialState, action: PayloadAction<string[]>): void {
      state.rejectedFilialsToCopy = action.payload;
    },
    setFilialsToCopy(state: IInitialState, action: PayloadAction<TFilialData[]>): void {
      state.filialsToCopy = action.payload;
    },
    clearFilialsToCopy(state: IInitialState): void {
      state.filialsToCopy = [];
      state.isCopyTemplatesError = false;
      state.isCopyTemplatesSuccess = false;
      state.isCopyTemplatesLoading = false;
    },
    setIsFetching(state: IInitialState, { payload }: PayloadAction<boolean>) {
      state.isFetching = payload;
    },
    setTemplatePageData(
      state: IInitialState,
      { payload }: PayloadAction<{ data: TTemplatePageData }>,
    ) {
      state.isLoading = false;
      state.data = payload.data;
      state.oldData = payload.data;
    },
    setTemplatesName(state: IInitialState, { payload }: PayloadAction<{ title: string }>) {
      const { title } = payload;

      state.data.title = title;
    },
    setOldTemplateName(state: IInitialState, { payload }: PayloadAction<{ title: string }>) {
      const { title } = payload;
      state.data.title = title;
    },
    selectOnchange(
      state: IInitialState,
      { payload }: PayloadAction<{ name: string; checked: boolean }>,
    ) {
      const { name, checked } = payload;
      state.data[name] = checked;
    },
    onChangeTemplate(
      state: IInitialState,
      { payload }: PayloadAction<{ name: string; value: string }>,
    ) {
      const { name, value } = payload;

      const lang = i18n.language as TAppLanguage;

      if (
        state.data.whenSend === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION &&
        name === TEMPLATE_DATA_KEY_NAMES.WHEN_SEND2
      ) {
        if (value !== '0') {
          state.data.whenSend2Days = '1';
        }
      }

      if (value === TEMPLATE_EVENT_KEY_NAME.RECORD_CANCELED) {
        state.data.clientDidNotCome = true;
        state.data.clientExpectation = false;
        state.data.clientCame = false;
        state.data.clientConfirmed = false;
      }

      if (value === TEMPLATE_EVENT_KEY_NAME.RECORD_DONE) {
        state.data.clientDidNotCome = false;
        state.data.clientExpectation = false;
        state.data.clientCame = true;
        state.data.clientConfirmed = false;
      }

      if (name === TEMPLATE_DATA_KEY_NAMES.WHEN_SEND2) {
        const { whenSend } = state.data;
        const type =
          value === WHEN_SEND2_VALUE.INTEGRATION
            ? TYPE_OF_TEMPLATE.INTEGRATION
            : whenSend === WHEN_SEND_VALUE.BIRTHDAY
            ? TYPE_OF_TEMPLATE.BIRTHDAY
            : 'common';

        state.specialKeyDict = templateKeysDict[lang][type];
      }

      state.data[name] = value;
    },
    addMediaFile(
      state: IInitialState,
      { payload }: PayloadAction<{ files: FileList; isUploadedMediaFile?: boolean }>,
    ) {
      if (!payload.files) return;
      const { files, isUploadedMediaFile } = payload;
      const file = files[0];

      if (isFileSizeIncorrect(file)) {
        state.data.mediaFile = null;
        state.data.mediaFileURL = '';
        state.data.mediaFileName = '';
        state.mediaFileTooBigError = true;
        state.errorMessage = COMMON_TOAST.FILE_TO_BIG;
        return;
      }

      const fileType = getMediaFileType(file);
      const fileName = file.name;
      const index = fileName.lastIndexOf('/');
      const shortName = index > 0 ? fileName.slice(index + 1) : fileName;

      if (fileType === FILE_TYPES.UNKNOWN) {
        state.isFileTypeError = true;
        state.errorMessage = COMMON_TOAST.UNSUPPORTED_FILE;
        return;
      }

      if (fileType === FILE_TYPES.IMAGE || fileType === FILE_TYPES.VIDEO) {
        const mediaFileURL = URL.createObjectURL(file);

        state.data.files = file;
        state.data.error = false;
        state.data.mediaFileType = fileType;
        state.data.mediaFileName = shortName;
        state.data.mediaFileURL = mediaFileURL;

        if (isUploadedMediaFile) {
          state.oldData.files = file;
          state.oldData.error = false;
          state.oldData.mediaFileType = fileType;
          state.oldData.mediaFileName = shortName;
          state.oldData.mediaFileURL = mediaFileURL;
        }
      } else {
        if (isUploadedMediaFile) {
          state.oldData.mediaFileURL = null;
        }
        state.data.mediaFileURL = null;
      }

      state.data.mediaFileName = shortName;
      state.data.mediaFileType = fileType;
      state.fullFileName = index > 0 ? fileName : '';
      state.data.mediaFile = file;
      state.error = false;

      if (isUploadedMediaFile) {
        state.oldData.mediaFileName = shortName;
        state.oldData.mediaFileType = fileType;
        state.oldData.mediaFile = file;
      }
    },
    clearFiles(state: IInitialState) {
      state.data.files = null;
      state.data.error = false;
      state.data.mediaFileType = null;
      state.data.mediaFileName = '';
      state.data.mediaFileURL = '';
      state.fullFileName = '';
    },
    setNewBalloonValueTP(
      state: IInitialState,
      { payload }: PayloadAction<{ name: string; value: string }>,
    ) {
      const { name, value } = payload;

      if (name === TEMPLATE_DATA_KEY_NAMES.BLACKLIST) {
        state[`${name}${TEMPLATE_NEW_BALLOON_VALUE_POSTFIX}`] = value.replace(
          REG_EXP.onlyNumbers,
          '',
        );
      } else {
        state[`${name}${TEMPLATE_NEW_BALLOON_VALUE_POSTFIX}`] = value;
      }
    },
    clearNewBalloonValueTP(state: IInitialState, { payload }: PayloadAction<{ name: string }>) {
      const { name } = payload;
      state[`${name}${TEMPLATE_NEW_BALLOON_VALUE_POSTFIX}`] = '';
    },
    addBalloonTP(
      state: IInitialState,
      { payload }: PayloadAction<{ keyName: TBalloonsKeyNames; value: string }>,
    ) {
      const { keyName, value } = payload;
      if (!value) return;

      if (keyName === TEMPLATE_DATA_KEY_NAMES.BLACKLIST) {
        state.data[keyName] = state.data[keyName].concat(value);
        state[`${keyName}${TEMPLATE_NEW_BALLOON_VALUE_POSTFIX}`] = '';
      } else {
        state.data[keyName] = state.data[keyName].concat(value);
        state[`${keyName}${TEMPLATE_NEW_BALLOON_VALUE_POSTFIX}`] = '';
      }
    },
    deleteBalloonTP(
      state: IInitialState,
      { payload }: PayloadAction<{ keyName: TBalloonsKeyNames; value: string }>,
    ) {
      const { keyName, value } = payload;
      state.data[keyName] = state.data[keyName].filter(item => item !== value);
    },
    setVisitQuantity(
      state: IInitialState,
      { payload }: PayloadAction<{ quantityFrom: string; quantityTo: string }>,
    ) {
      const { quantityFrom, quantityTo } = payload;

      state.data.quantityTo = quantityTo;
      state.data.quantityFrom = quantityFrom;

      if (quantityTo && +quantityTo < +state.data.visits) {
        state.data.visits = quantityTo;
      }
    },
    setVisits(state: IInitialState, { payload }: PayloadAction<{ value: string }>) {
      const { value } = payload;

      if (state.data.quantityTo && value > state.data.quantityTo) {
        state.warningMessage = i18n.t('COMMON_TOAST_MESSAGES.VISITS_QUANTITY_ERROR');
        state.isShowWarningMessage = true;
        return;
      }

      if (value === '') {
        state.data.visits = '';
        return;
      }

      if (Number(value) < 1) {
        state.data.visits = '';
      } else {
        state.data.visits = value;
      }
    },
    setAbandonedTextareaData(
      state: IInitialState,
      {
        payload,
      }: PayloadAction<{
        name: TTextareaNames;
        selectionStart: number;
        selectionEnd: number;
      }>,
    ) {
      const { specialKeyDict } = state;
      const { name, selectionStart, selectionEnd } = payload;

      state.blurFromTextareaName = name;
      state.selectionEnd = selectionEnd;
      state.selectionStart = selectionStart;

      state.textareaKeysData = getKeysData(state.data[name], specialKeyDict);
    },
    wrapText(
      state: IInitialState,
      { payload }: PayloadAction<{ innerText: string; callback: TParseDivValueToSlice }>,
    ) {
      const { specialKeyDict } = state;
      const { innerText, callback } = payload;
      const { blurFromTextareaName, selectionEnd, selectionStart } = state;

      const textAreaValue = String(state.data[blurFromTextareaName]);

      const wrapSymbol = innerText.slice(0, 1);
      let wrapTextSymbols = '';

      if (wrapSymbol === '`') {
        wrapTextSymbols = '```';
      } else {
        wrapTextSymbols = wrapSymbol;
      }

      const newValueWithWrappedText = getWrapperText(
        textAreaValue,
        wrapTextSymbols,
        selectionStart,
        selectionEnd,
      );

      const delta = newValueWithWrappedText.length - textAreaValue.length;

      callback({
        text: newValueWithWrappedText,
        selectionStart: selectionEnd + delta,
        selectionEnd: selectionEnd + delta,
        specialKeyDict,
      });

      state.data[blurFromTextareaName] = newValueWithWrappedText;
    },
    addKeyInTextarea(
      state: IInitialState,
      {
        payload,
      }: PayloadAction<{
        innerText: string;
        callback: TParseDivValueToSlice;
      }>,
    ) {
      const { specialKeyDict } = state;
      const { innerText, callback } = payload;
      const { blurFromTextareaName, selectionEnd, selectionStart } = state;
      const textAreaValue = String(state.data[blurFromTextareaName]);

      const innerTextLength = innerText.length;

      const selectionStartOffset = selectionStart + innerTextLength;

      const selectionEndOffset = selectionEnd + innerTextLength;

      state.selectionStart = selectionStartOffset;
      state.selectionEnd = selectionEndOffset;

      const newTextAreaValue = `${textAreaValue.substring(
        0,
        selectionStart,
      )}${innerText}${textAreaValue.substring(selectionEnd)}`;

      state.data[blurFromTextareaName] = newTextAreaValue;

      state.textareaKeysData = getKeysData(newTextAreaValue, specialKeyDict);

      callback({
        text: newTextAreaValue,
        selectionStart: selectionStartOffset,
        selectionEnd: selectionEndOffset,
        specialKeyDict,
      });
    },
    setTextareaValue(
      state: IInitialState,
      {
        payload,
      }: PayloadAction<{
        value: string;
        name: TTextareaNames;
        selectionEnd: number;
        selectionStart: number;
        newTextareaKeysData: TKeyData[];
      }>,
    ) {
      const { name, selectionStart, selectionEnd, value, newTextareaKeysData } = payload;

      state.selectionStart = selectionStart;
      state.selectionEnd = selectionEnd;

      state.textareaKeysData = newTextareaKeysData;

      state.data[name] = value;
    },
    textAreaOnClickHandlerTP(
      state: IInitialState,
      {
        payload,
      }: PayloadAction<{
        name: TTextareaNames;
      }>,
    ) {
      const { name } = payload;
      const { specialKeyDict } = state;

      const text = state.data[name];

      state.textareaKeysData = getKeysData(text, specialKeyDict);
    },
    setWhenSend(state: IInitialState, { payload }: PayloadAction<{ whenSend: string }>) {
      const { whenSend } = payload;
      const lang = i18n.language as TAppLanguage;

      if (WHEN_SEND_VALUE.BEFORE_START === whenSend) {
        state.data.whenSend2 = '';
        state.data.whenSend2Days = '0';
        state.data.whenSend2Min = '15';
      }
      if (WHEN_SEND_VALUE.AFTER_END === whenSend) {
        state.data.whenSend2 = '';
        state.data.whenSend2Days = '0';
        state.data.whenSend2Min = '15';
        state.data.sendOnlyLostClient = false;
      }
      if (WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION === whenSend) {
        state.data.whenSend2 = '1';
        state.data.whenSend2Days = '1';
      }
      if (WHEN_SEND_VALUE.EVENT === whenSend) {
        state.data.whenSend2 = TEMPLATE_EVENT_KEY_NAME.RECORD_CREATE;
      }
      if (WHEN_SEND_VALUE.CONSENT === whenSend) {
        state.data.whenSend2 = CONSENT_KEY_NAMES.AFTER_CREATE_RECORD;
      }
      if (WHEN_SEND_VALUE.BIRTHDAY === whenSend) {
        state.data.whenSend2 = '0';
        state.data.whenSend2Days = '1';
        state.data.sendOnlyLostClient = false;
      }

      const { whenSend2 } = state.data;

      const type =
        whenSend2 === WHEN_SEND2_VALUE.INTEGRATION
          ? TYPE_OF_TEMPLATE.INTEGRATION
          : whenSend === WHEN_SEND_VALUE.BIRTHDAY
          ? TYPE_OF_TEMPLATE.BIRTHDAY
          : 'common';

      state.specialKeyDict = templateKeysDict[lang][type];

      state.data.whenSend = whenSend;
    },
    setWhenSend2Days(
      state: IInitialState,
      { payload }: PayloadAction<{ value: string; maxValue: number; minValue: number }>,
    ) {
      const { value, maxValue, minValue } = payload;
      const newValue = +value.replace(REG_EXP.onlyNumbers, '');
      state.data.whenSend2Days =
        newValue > maxValue
          ? String(maxValue)
          : newValue <= minValue
          ? String(minValue)
          : String(newValue);
    },
    setWhenSend2Min(state: IInitialState, { payload }: PayloadAction<string>) {
      const newValue = +payload.replace(REG_EXP.onlyNumbers, '');
      state.data.whenSend2Min = String(newValue);
    },
    roundedWhenSend2Min(state: IInitialState) {
      state.data.whenSend2Min = String(getRoundedMinutes(+state.data.whenSend2Min, 15));
    },
    setSendOnlyLostClient(
      state: IInitialState,
      { payload }: PayloadAction<{ checked: boolean; isAfterEvent: boolean }>,
    ) {
      const { checked, isAfterEvent } = payload;

      if (checked && isAfterEvent && state.data.clientDidNotCome) {
        state.data.clientDidNotCome = false;
        state.isShowWarningMessage = true;
        state.warningMessage = i18n.t(TEMPLATE_WARNING.DISABLE_CLIENT_DID_NOT_COME_CHECKBOX);
      }
      state.data.sendOnlyLostClient = checked;
    },
    clearCreatedTemplateData(state: IInitialState) {
      state.createdTemplateId = '';
      state.isTemplateIdUpdate = false;
      state.isTemplateSaveSuccess = false;
    },
    clearAllSuccessState(state: IInitialState) {
      state.isShowWarningMessage = false;
      state.isTemplateSaveSuccess = false;
      state.isCopyTemplatesSuccess = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(copyTemplateToBranchThunk.pending, (state: IInitialState) => {
      state.isCopyTemplatesError = false;
      state.isCopyTemplatesLoading = true;
      state.isCopyTemplatesSuccess = false;
    });
    builder.addCase(
      copyTemplateToBranchThunk.fulfilled,
      (state: IInitialState, { payload }: PayloadAction<number>) => {
        const lang = i18n.language as TAppLanguage;

        const filialToCopyLength = payload;

        const filials = FILIALS[lang];

        const successMessage = i18n.t('TEMPLATE_PAGE_TEXT.templateCopy.templateCopied', {
          templateCopiedNumber: filialToCopyLength,
          filials: numWord(filialToCopyLength, filials),
        });

        state.isCopyTemplatesLoading = false;
        state.isCopyTemplatesSuccess = true;
        state.successMessage = successMessage;
      },
    );
    builder.addCase(
      copyTemplateToBranchThunk.rejected,
      (
        state: IInitialState,
        { payload }: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in payload) {
            const { detail } = payload;
            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
            state.isCopyTemplatesError = true;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
          if (REJECT_RESPONSE_KEY.STATUS in payload) {
            const { status } = payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isCopyTemplatesError = true;
              state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
            }
          }
        } else {
          state.isCopyTemplatesError = true;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
        state.isCopyTemplatesLoading = false;
      },
    );
    builder.addCase(getTemplateByIdData.pending, (state: IInitialState) => {
      const { isFetching } = state;
      if (!isFetching) {
        state.isLoading = true;
      }
      state.isError = false;
      state.isSuccess = false;
    });
    builder.addCase(
      getTemplateByIdData.fulfilled,
      (state: IInitialState, { payload }: PayloadAction<TTemplatePageData | void>) => {
        state.isLoading = false;
        state.isFetching = false;
        if (payload) {
          const { whenSend, whenSend2 } = payload;
          const lang = i18n.language as TAppLanguage;

          if (!WHEN_SEND2_SELECT_OPTION_VALUE_NEW.eng[whenSend][whenSend2]) {
            state.whenSend2SelectOption = createAWhenSend2ItemList({
              whenSend: payload.whenSend,
              whenSend2: payload.whenSend2,
              optionList: WHEN_SEND2_SELECT_OPTION_VALUE_NEW,
            });
          }

          const type =
            whenSend2 === WHEN_SEND2_VALUE.INTEGRATION
              ? TYPE_OF_TEMPLATE.INTEGRATION
              : whenSend === WHEN_SEND_VALUE.BIRTHDAY
              ? TYPE_OF_TEMPLATE.BIRTHDAY
              : 'common';

          state.specialKeyDict = templateKeysDict[lang][type];

          state.whenSendSelectOption = getWhenSendSelectOption({
            whenSend,
            fullWhenSendSelectOption: WHEN_SEND_SELECT_OPTION_NEW_TEMPLATE,
          });

          state.data = payload;
          state.oldData = payload;
        } else {
          state.data = emptyTemplateMockData;
          state.oldData = emptyTemplateMockData;
        }
        if (state.isRetries) {
          state.retries = 1;
          state.isRetries = false;
        }
        state.isLoading = false;
        state.isSuccess = true;
      },
    );
    builder.addCase(
      getTemplateByIdData.rejected,
      (
        state: IInitialState,
        { payload }: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in payload) {
            const { detail } = payload;
            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
              state.isRetries = true;
              state.retries -= 1;
            } else {
              state.isError = true;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in payload) {
            const { status } = payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(saveTemplateData.pending, (state: IInitialState) => {
      state.isSavingTemplate = true;
      state.isTemplateSaveError = false;
      state.templateTestFieldsParsError = '';
    });
    builder.addCase(saveTemplateData.fulfilled, (state: IInitialState) => {
      state.isSavingTemplate = false;
      state.isTemplateSaveSuccess = true;
      state.successMessage = COMMON_TOAST.SAVE_SUCCESS;

      state.oldData = state.data;

      state.whenSendSelectOption = getWhenSendSelectOption({
        whenSend: state.data.whenSend,
        fullWhenSendSelectOption: WHEN_SEND_SELECT_OPTION_NEW_TEMPLATE,
      });
    });
    builder.addCase(
      saveTemplateData.rejected,
      (
        state: IInitialState,
        { payload }: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (payload && REJECT_RESPONSE_KEY.ERROR in payload) {
          state.templateTestFieldsParsError = addNewLines(payload.error);
        }
        if (payload && REJECT_RESPONSE_KEY.STATUS in payload) {
          const { status } = payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isTemplateSaveError = true;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
          state.isSavingTemplate = false;
        } else if (payload && REJECT_RESPONSE_2_KEY.DETAIL in payload) {
          const { detail } = payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isTemplateSaveError = true;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
        } else {
          state.isTemplateSaveError = true;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
        state.isSavingTemplate = false;
      },
    );
    builder.addCase(
      getListOfCategoriesTS.fulfilled,
      (
        state: IInitialState,
        { payload }: PayloadAction<{ data: TStaffListItem[]; categoryName: string } | void>,
      ) => {
        if (!payload) return;
        const { data, categoryName } = payload;
        const newStaffList = data.map(item => [String(item.id), item.name]);
        if (newStaffList.length) {
          state.categoriesDataList[categoryName] = Object.fromEntries(newStaffList);
        } else {
          state.categoriesDataList[categoryName] = {
            '-': i18n.t('TEMPLATE_PAGE_TEXT.categoriesDataListNotRecord'),
          };
        }
      },
    );
    builder.addCase(
      getListOfCategoriesTS.rejected,
      (
        state: IInitialState,
        { payload }: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (payload && REJECT_RESPONSE_KEY.STATUS in payload) {
          const { status } = payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isGettingStaffListError = true;
          }
        }
        if (payload && REJECT_RESPONSE_2_KEY.DETAIL in payload) {
          const { detail } = payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isGettingStaffListError = true;
          }
        }
      },
    );
  },
});

export const templateReducer = templateSlice.reducer;
export const {
  wrapText,
  setVisits,
  clearFiles,
  setWhenSend,
  addMediaFile,
  addBalloonTP,
  setIsFetching,
  selectOnchange,
  deleteBalloonTP,
  setWhenSend2Min,
  addKeyInTextarea,
  onChangeTemplate,
  setFilialsToCopy,
  setVisitQuantity,
  setTemplatesName,
  setTextareaValue,
  setWhenSend2Days,
  setWhenSendOption,
  clearFilialsToCopy,
  clearTemplateState,
  setOldTemplateName,
  roundedWhenSend2Min,
  setTemplatePageData,
  clearAllSuccessState,
  setNewBalloonValueTP,
  setSendOnlyLostClient,
  clearNewBalloonValueTP,
  clearCreatedTemplateData,
  setRejectedFilialsToCopy,
  setAbandonedTextareaData,
} = templateSlice.actions;
