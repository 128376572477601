import { TChannel } from '@api/types';
import { TSettingsBillData } from '@const/settings';
import { TChanelMoveDirection } from '@shared/types';

export type TSettingsData = {
  [key: string]: string | string[] | TSettingsBillData | TChannel | boolean | undefined;
  exceptionMastersNames: string[];
  phonesDontShowInBot: string[];
  smsProvider: string;
  smsApikey: string;
  smsSenderName: string;
  smsLogin: string;
  smsPass: string;
  billData: TSettingsBillData;
  replyMsgCheck: boolean;
  savePhonesYcl: boolean;
  smsConfirm: boolean;
  enableReplyInBot: boolean;
  sendContact: boolean;
  sendFromTime: string;
  sendToTime: string;
  phone: string;
  disableChat: boolean;
  channelPriorityData?: TChannel[];
};

export enum SETTINGS_DATA_KEY_NAMES {
  SMS_CONFIRM = 'smsConfirm',
  PHONE = 'phone',
  EXCEPTION_MASTER_NAMES = 'exceptionMastersNames',
  PHONES_DONT_SHOW_IN_BOT = 'phonesDontShowInBot',
  SMS_PROVIDER = 'smsProvider',
  SMS_API_KEY = 'smsApikey',
  SMS_SENDER_NAME = 'smsSenderName',
  SMS_LOGIN = 'smsLogin',
  SMS_PASSWORD = 'smsPass',
  BUILD_DATA = 'billData',
  ENABLE_CHAT_YCLIENTS = 'disableChat',
  REPLAY_MESSAGE_CHECK = 'replyMsgCheck',
  SAVE_PHONE_YCLIENTS = 'savePhonesYcl',
  ENABLE_REPLAY_IN_BOT = 'enableReplyInBot',
  SEND_CONTACT = 'sendContact',
  SEND_FROM_TIME = 'sendFromTime',
  SEND_TO_TIME = 'sendToTime',
  CHANNEL_PRIORITY = 'channelPriority',
}

export type TChanelNames = 'Telegram' | 'SMS' | 'Whatsapp';

export enum CHANNEL_NAMES {
  WA = 'Whatsapp',
  TG = 'Telegram',
  SMS = 'SMS',
}

export const CHANNEL_MOVE_DIRECTION: Record<'UP' | 'DOWN', TChanelMoveDirection> = {
  UP: 'up',
  DOWN: 'down',
};
