import { CONNECTION_STATUSES, TQRCodeData } from '@models/index';
import { CONNECTION_TG_STATUS, TELEGRAM_BOT_CONNECTION_STEPS } from '@const/connect';
import {
  TConnectionTGStatuses,
  TConnectionTGStatusData,
  TConnectionWAStatusData,
} from '@redux/connection/types';

export const connectionWAInitialData: TConnectionWAStatusData = {
  status: CONNECTION_STATUSES.AUTHENTICATED,
  reference: '',
  messages: [],
};

export const CONNECTION_TG_REQUEST_STATUSES: TConnectionTGStatuses[] = [
  'error',
  'success',
  'restart',
  'logout',
  'phoneSend',
  'pinSend',
  'passwordSend',
];

export type TPhoneConnectionStatus = 'success' | 'loading' | 'error';

export enum PHONE_CONNECTION_STEP {
  FIRST,
  SECOND,
}

export const connectionTGInitialData: TConnectionTGStatusData = {
  tgStatus: CONNECTION_TG_STATUS.NOT_PAID,
  phone: '',
  isError: false,
  isInputError: false,
  pin: '',
  password: '',
  stepNumber: TELEGRAM_BOT_CONNECTION_STEPS.FIRST,
  requestStatus: 'success',
  errorMessage: '',
};

export interface IInitialState {
  isRefresh: boolean;
  isLoadingStatusRefresh: boolean;
  qrData: TQRCodeData | undefined;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isFetching: boolean;
  isFetchingQR: boolean;
  isGetQRError: boolean;
  isRestartingBot: boolean;
  isCleanQueryError: boolean;
  isBotRestartError: boolean;
  isBotStoppingQuery: boolean;
  isBotStoppingError: boolean;
  isFetchingCleanQuery: boolean;
  isAccessToConnectionDenied: boolean;
  waData: TConnectionWAStatusData;
  tgData: TConnectionTGStatusData;
  phoneConnectionStep: PHONE_CONNECTION_STEP;
  phoneConnectionStatus: TPhoneConnectionStatus;
  uniqueCode: string;
  phoneNumber: string;
  oldPhoneNumber: string;
  errorMessage: string;
  successMessage: string;
}

export const initialState: IInitialState = {
  isRefresh: false,
  qrData: undefined,
  isError: false,
  isLoading: false,
  isSuccess: false,
  isFetching: false,
  isFetchingQR: false,
  isGetQRError: false,
  isRestartingBot: false,
  isCleanQueryError: false,
  isBotRestartError: false,
  isBotStoppingError: false,
  isBotStoppingQuery: false,
  isFetchingCleanQuery: false,
  isLoadingStatusRefresh: false,
  isAccessToConnectionDenied: false,
  phoneConnectionStep: PHONE_CONNECTION_STEP.FIRST,
  errorMessage: '',
  successMessage: '',
  phoneConnectionStatus: 'success',
  phoneNumber: '',
  oldPhoneNumber: '',
  uniqueCode: '',
  tgData: connectionTGInitialData,
  waData: connectionWAInitialData,
};
