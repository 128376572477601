import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { REG_EXP } from '@const/common';
import { TRejectResponseData } from '@models/index';
import { TBlackListData } from '@redux/blackList/models';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { addNewLines, validatePhoneNumber } from '@helpers/index';
import { BLACKLIST_TOAST, NUMBER_MAX_LENGTH } from '@const/blacklist';
import { REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { IInitialState, initialState, TNewNumberInputs } from '@redux/blackList/initialState';
import {
  getBlackList,
  getSendOutBlackList,
  addNumberToBlacklist,
  deleteNumberFromBlacklist,
  addNumberToSendOutBlacklist,
  deleteNumberFromSendOutBlacklist,
} from '@redux/blackList/blackListThunks';

export const blackListSlice = createSlice({
  name: 'blackList',
  initialState,
  reducers: {
    setNewNumberToCommonList(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const newNumber = validatePhoneNumber(action.payload.value.replace(REG_EXP.onlyNumbers, ''));

      if (newNumber.length < NUMBER_MAX_LENGTH) {
        state.newNumberToCommonList = action.payload.value.replace(REG_EXP.onlyNumbers, '');
      }
    },
    setNewNumberToUsubscribersList(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const newNumber = validatePhoneNumber(action.payload.value.replace(REG_EXP.onlyNumbers, ''));

      if (newNumber.length < NUMBER_MAX_LENGTH) {
        state.newNumberToUnsubscribersList = action.payload.value.replace(REG_EXP.onlyNumbers, '');
      }
    },
    setSearchString(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const searchString = action.payload.value;
      state.searchString = searchString;

      if (!searchString) {
        state.commonFilteredList = state.commonList;
        state.unsubscribersFilteredList = state.unsubscribersList;
      } else {
        state.commonFilteredList = state.commonList.filter(item =>
          item.number.includes(state.searchString),
        );
        state.unsubscribersFilteredList = state.unsubscribersFilteredList.filter(item =>
          item.number.includes(state.searchString),
        );
      }
    },
    clearNewNumber(state: IInitialState, action: PayloadAction<{ inputName: TNewNumberInputs }>) {
      state[action.payload.inputName] = '';
    },
    showAddNumberSuccessMessage(state: IInitialState) {
      state.isAddSuccess = true;
    },
    hideAddNumberSuccessMessage(state: IInitialState) {
      state.isAddSuccess = false;
    },
    showDeletingSuccessMessage(state: IInitialState) {
      state.isDeleteSuccess = true;
    },
    hideDeletingSuccessMessage(state: IInitialState) {
      state.isDeleteSuccess = false;
    },
    hideAllErrorToast(state: IInitialState) {
      state.errorMessage = '';
      state.status = 'success';
    },
    hideAllSuccessToast(state: IInitialState) {
      state.successMessage = '';
      state.status = 'success';
    },
  },
  extraReducers: builder => {
    builder.addCase(getBlackList.pending, (state: IInitialState) => {
      state.status = 'loadingBlackList';
    });
    builder.addCase(
      getBlackList.fulfilled,
      (state: IInitialState, action: PayloadAction<TBlackListData[] | void>) => {
        if (action.payload) {
          const data = action.payload;

          state.status = 'success';
          state.commonList = data;
          state.commonFilteredList = data;
        }
      },
    );
    builder.addCase(
      getBlackList.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
    builder.addCase(getSendOutBlackList.pending, (state: IInitialState) => {
      state.status = 'loadingBlackList';
    });
    builder.addCase(
      getSendOutBlackList.fulfilled,
      (state: IInitialState, action: PayloadAction<TBlackListData[] | void>) => {
        if (action.payload) {
          const data = action.payload;

          state.status = 'success';
          state.unsubscribersList = data;
          state.unsubscribersFilteredList = data;
        }
      },
    );
    builder.addCase(
      getSendOutBlackList.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
    builder.addCase(addNumberToBlacklist.pending, (state: IInitialState) => {
      state.errorMessage = '';
      state.status = 'addingToBlackList';
    });
    builder.addCase(
      addNumberToBlacklist.fulfilled,
      (state: IInitialState, action: PayloadAction<TBlackListData | undefined>) => {
        if (action.payload) {
          state.status = 'success';
          state.newNumberToCommonList = '';
          // state.isAddSuccess = true;
          state.successMessage = BLACKLIST_TOAST.SUCCESS_ADD_NUMBER;
          // state.isAddingNumber = false;

          state.commonList.push(action.payload);
          state.commonFilteredList.push(action.payload);
        }
      },
    );
    builder.addCase(
      addNumberToBlacklist.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isAddingNumberError = true;
          }
        }
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isAddingNumberError = true;
          }
        }
        if (action.payload && REJECT_RESPONSE_KEY.OK in action.payload) {
          state.isAddingNumber = false;
          state.isAddingNumberError = true;
          state.errorMessage = addNewLines(action.payload.status);
          return;
        }
        if (!action.payload) {
          state.isAddingNumberError = true;
        }
        state.isAddingNumber = false;
        state.errorMessage = BLACKLIST_TOAST.ERROR_ADD_NUMBER;
      },
    );
    builder.addCase(addNumberToSendOutBlacklist.pending, (state: IInitialState) => {
      state.errorMessage = '';
      state.status = 'addingToSendOutBlackList';
    });
    builder.addCase(
      addNumberToSendOutBlacklist.fulfilled,
      (state: IInitialState, action: PayloadAction<TBlackListData | undefined>) => {
        if (action.payload) {
          state.status = 'success';
          state.newNumberToUnsubscribersList = '';
          // state.isAddSuccess = true;
          state.successMessage = BLACKLIST_TOAST.SUCCESS_ADD_NUMBER;
          // state.isAddingNumber = false;

          state.unsubscribersList.push(action.payload);
          state.unsubscribersFilteredList.push(action.payload);
        }
      },
    );
    builder.addCase(
      addNumberToSendOutBlacklist.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isAddingNumberError = true;
          }
        }
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isAddingNumberError = true;
          }
        }
        if (action.payload && REJECT_RESPONSE_KEY.OK in action.payload) {
          state.isAddingNumber = false;
          state.isAddingNumberError = true;
          state.errorMessage = addNewLines(action.payload.status);
          return;
        }
        if (!action.payload) {
          state.isAddingNumberError = true;
        }
        state.isAddingNumber = false;
        state.errorMessage = BLACKLIST_TOAST.ERROR_ADD_NUMBER;
      },
    );
    builder.addCase(deleteNumberFromBlacklist.pending, (state: IInitialState) => {
      state.errorMessage = '';
      state.status = 'deletingFromBlackList';
    });
    builder.addCase(
      deleteNumberFromBlacklist.fulfilled,
      (state: IInitialState, action: PayloadAction<string | undefined>) => {
        if (action.payload) {
          const newList = state.commonList.filter(item => item.number !== action.payload);

          state.status = 'success';
          state.commonList = newList;
          state.newNumberToCommonList = '';
          state.commonFilteredList = newList;
          state.successMessage = BLACKLIST_TOAST.SUCCESS_DELETE_NUMBER;
        }
      },
    );
    builder.addCase(
      deleteNumberFromBlacklist.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.status = 'errorDeletingFromBlackList';
          }
        }
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isAddingNumber = false;
          } else {
            state.status = 'errorDeletingFromBlackList';
          }
        }
        if (action.payload && REJECT_RESPONSE_KEY.OK in action.payload) {
          state.status = 'errorDeletingFromBlackList';
          state.errorMessage = addNewLines(action.payload.status);
          return;
        }
        if (!action.payload) {
          state.status = 'errorDeletingFromBlackList';
        }
        state.errorMessage = BLACKLIST_TOAST.ERROR_DELETE_NUMBER;
      },
    );
    builder.addCase(deleteNumberFromSendOutBlacklist.pending, (state: IInitialState) => {
      state.errorMessage = '';
      state.status = 'deletingFromBlackList';
    });
    builder.addCase(
      deleteNumberFromSendOutBlacklist.fulfilled,
      (state: IInitialState, action: PayloadAction<string | undefined>) => {
        if (action.payload) {
          const newList = state.unsubscribersList.filter(item => item.number !== action.payload);

          state.status = 'success';
          state.unsubscribersList = newList;
          state.newNumberToUnsubscribersList = '';
          state.unsubscribersFilteredList = newList;
          state.successMessage = BLACKLIST_TOAST.SUCCESS_DELETE_NUMBER;
        }
      },
    );
    builder.addCase(
      deleteNumberFromSendOutBlacklist.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.status = 'errorDeletingFromBlackList';
          }
        }
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isAddingNumber = false;
          } else {
            state.status = 'errorDeletingFromBlackList';
          }
        }
        if (action.payload && REJECT_RESPONSE_KEY.OK in action.payload) {
          state.status = 'errorDeletingFromBlackList';
          state.errorMessage = addNewLines(action.payload.status);
          return;
        }
        if (!action.payload) {
          state.status = 'errorDeletingFromBlackList';
        }
        state.errorMessage = BLACKLIST_TOAST.ERROR_DELETE_NUMBER;
      },
    );
  },
});

export const {
  clearNewNumber,
  setSearchString,
  hideAllErrorToast,
  hideAllSuccessToast,
  setNewNumberToCommonList,
  showDeletingSuccessMessage,
  hideDeletingSuccessMessage,
  showAddNumberSuccessMessage,
  hideAddNumberSuccessMessage,
  setNewNumberToUsubscribersList,
} = blackListSlice.actions;

export const blackListReducer = blackListSlice.reducer;
