import React, { memo } from 'react';
import { Icon } from '@blueprintjs/core';

type TProps = {
  /**
   * Текст в callout
   * @param {string}
   */
  calloutText: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};
export const CustomWarningCallout = memo(({ calloutText, className = '' }: TProps) => {
  return (
    <div className={className}>
      <div className='bg-[#FFF6ED] rounded-lg flex items-center px-3 py-4 min-h-[3.75rem] max-w-[40.6875rem]'>
        <div className='flex items-center justify-center mr-2 h-[1.75rem] w-[1.75rem]'>
          <Icon size={20} color='#FB982E' icon='info-sign' />
        </div>
        <span className='text-balticSea leading-6 tracking-[0.0275rem]'>{calloutText}</span>
      </div>
    </div>
  );
});
