import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
//
import { Loader } from '@blocks/loader';
import useDebounce from '@hooks/useDebounce';
import { SORTING_TYPES } from '@const/common';
import { myTrackerApi } from '@api/myTrackerApi';
import { TOnChangeHandler } from '@shared/types';
import { QUERY_PARAMS } from '@const/apiConstants';
import { getCommonEventData } from '@helpers/index';
import { TablePagination } from '@material-ui/core';
import { MY_TRACKER_EVENTS } from '@helpers/myTracker';
import { TStatisticsData } from '@redux/statistics/models';
import { useAppDispatch, useAppSelector } from '@store/store';
import { IInitialState } from '@redux/statistics/initialState';
import { TAppLanguage, TSimpleStringObj } from '@models/index';
import { SortTypeDropdown } from '@components/sortTypeDropdown';
import { getStatisticsPageData } from '@redux/mailing/selectors';
import { DateRangeDashboardPicker } from '@components/dateRangePicker';
import { MailingStatisticsCard } from '@components/mailingStatisticsCard';
import { SearchInputWithButton } from '@components/searchInputWithButton';
import { PAGINATION_ROWS_OPTIONS, STATISTICS_SORT_DROPDOWN } from '@const/mailing';
import {
  setDateRangeSP,
  setRowsPerPageSP,
  setSortTypeDataSP,
  setSelectedPageSP,
  setSortDirectionSP,
  clearSearchStringSP,
  setTableSearchStringSP,
  setSearchDataFromQueryParams,
} from '@redux/statistics/statisticsSlice';

type TStatisticProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Данные таблицы
   * @param {TStatisticsData[]}
   */
  data: TStatisticsData[];
};

export const StatisticTemplate = memo(({ appLanguage, data }: TStatisticProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSearchEventSend = useRef(false);
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    myTrackerApi.sendoutStatisticsPadeOpen(
      getCommonEventData(MY_TRACKER_EVENTS.SENDOUT_STATISTICS_OPEN),
    );
  }, []);

  const setNewQueryParams = useCallback(
    (newQuery: TSimpleStringObj) => {
      const queryParams = Object.fromEntries(search.entries());
      setSearch({ ...queryParams, ...newQuery });
    },
    [search, setSearch],
  );

  const {
    sortedBy,
    isSuccess,
    isLoading,
    totalCount,
    currentPage,
    rowsPerPage,
    searchString,
    endDate: end,
    sortDirection,
    startDate: start,
  } = useAppSelector(getStatisticsPageData);

  const [searchS, setSearchS] = useState(searchString);

  const debouncedSearch = useDebounce(searchS, 300);

  // Обрабатывет изменения debouncedSearch, для оптимизации запросов
  useEffect(() => {
    if (debouncedSearch) {
      if (!isSearchEventSend.current) {
        isSearchEventSend.current = true;

        myTrackerApi.sendoutSearchStart(
          getCommonEventData(MY_TRACKER_EVENTS.SENDOUT_SEARCH_IN_STATISTICS),
        );
      }
      dispatch(setTableSearchStringSP({ value: debouncedSearch }));
      setNewQueryParams({ [QUERY_PARAMS.SEARCH_STRING]: String(debouncedSearch) });
    } else {
      dispatch(clearSearchStringSP());
    }
  }, [debouncedSearch, dispatch, setNewQueryParams]);

  useEffect(() => {
    const searchData = Object.fromEntries(search.entries()) as Record<keyof IInitialState, string>;
    dispatch(setSearchDataFromQueryParams(searchData));
  }, [search, dispatch]);

  // Обрабатывает изменение строки поиска
  const setSearchStringHandler: TOnChangeHandler = useCallback(
    event => {
      if (isLoading) return;

      const { value } = event.currentTarget;
      setSearchS(value);
    },
    [isLoading],
  );

  // Запускает фильтрацию
  const searchHandler = useCallback(() => {
    // dispatch(startSearch());
  }, []);

  // Обрабатывает очистку строки поиска
  const clearSearchStringHandler = useCallback(() => {
    setSearchS('');
  }, []);

  // Обрабатывает изменение диапазона дат
  const setDateRangeHandler = useCallback(
    ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
      if (isLoading) return;

      myTrackerApi.sendoutSearchByDateStart(
        getCommonEventData(MY_TRACKER_EVENTS.SENDOUT_PERIOD_SET_IN_STATISTICS),
      );

      const newStartDate = startDate.toISOString().slice(0, -5);
      const newEndDate = endDate.toISOString().slice(0, -5);

      dispatch(setDateRangeSP({ startDate: newStartDate, endDate: newEndDate }));
      setNewQueryParams({
        [QUERY_PARAMS.START_DATE]: String(newStartDate),
        [QUERY_PARAMS.END_DATE]: String(newEndDate),
      });
    },
    [dispatch, isLoading, setNewQueryParams],
  );

  // Обрабатывает изменение типа сортировки
  const setSortByHandler = useCallback(
    (fieldName: string) => () => {
      if (isLoading) return;

      myTrackerApi.sendoutSortStart(
        getCommonEventData(MY_TRACKER_EVENTS.SENDOUT_SORT_IN_STATISTICS),
      );

      dispatch(setSortTypeDataSP({ collumName: fieldName }));
      setNewQueryParams({ [QUERY_PARAMS.SORTED_BY]: String(fieldName) });
    },
    [dispatch, isLoading, setNewQueryParams],
  );

  // Обрабатывает изменение направления сортировки
  const setSortDirectionHandler = useCallback(() => {
    if (isLoading) return;

    myTrackerApi.sendoutSortStart(getCommonEventData(MY_TRACKER_EVENTS.SENDOUT_SORT_IN_STATISTICS));

    dispatch(setSortDirectionSP(sortDirection));
    const newSortDirection = String(
      sortDirection === SORTING_TYPES.ASCENDING
        ? SORTING_TYPES.DESCENDING
        : SORTING_TYPES.ASCENDING,
    );

    setNewQueryParams({
      [QUERY_PARAMS.SORT_DIRECTION]: newSortDirection,
    });
  }, [dispatch, isLoading, sortDirection, setNewQueryParams]);

  // Обрабатывает изменение страницы пагинации
  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      if (isLoading) return;

      dispatch(setSelectedPageSP(newPage));
      setNewQueryParams({ [QUERY_PARAMS.CURRENT_PAGE]: String(newPage) });
    },
    [dispatch, isLoading, setNewQueryParams],
  );

  // Обрабатывает изменение количества строк на странице
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isLoading) return;

      const { value } = event.target;
      dispatch(setRowsPerPageSP(Number(value)));
      setNewQueryParams({ [QUERY_PARAMS.ROWS_PER_PAGE]: String(value) });
    },
    [dispatch, isLoading, setNewQueryParams],
  );

  // Создает список карточек статистики
  const statisticsCardList = useMemo(
    () =>
      data.map(cardData => (
        <MailingStatisticsCard key={cardData.id} data={cardData} className='mb-6' />
      )),
    [data],
  );

  return (
    <div className='flex flex-col relative w-full'>
      {isLoading ? <Loader className='fixed top-[60%] left-[50%] bg-white z-50' /> : null}
      <SearchInputWithButton
        hideSearchButton
        searchString={searchS}
        disableSearchButton={false}
        startSearchHandler={searchHandler}
        setSearchStringHandler={setSearchStringHandler}
        clearSearchStringHandler={clearSearchStringHandler}
        buttonText={t('MAILING_PAGE_TEXT.statisticsTable.searchButton')}
        placeholder={t('MAILING_PAGE_TEXT.statisticsTable.searchPlaceholder')}
        className='mt-px mb-8 md:max-w-[25.3rem]'
      />
      <div className='flex flex-col sm:flex-row justify-between mb-8'>
        <DateRangeDashboardPicker
          startDate={new Date(start)}
          endDate={new Date(end)}
          minDateRange={0}
          setSendingDate={setDateRangeHandler}
          className='z-[999]'
        />
        <SortTypeDropdown
          sortedBy={sortedBy}
          sortDirection={sortDirection}
          setSortByHandler={setSortByHandler}
          setSortDirectionHandler={setSortDirectionHandler}
          dropdownOptionDict={STATISTICS_SORT_DROPDOWN[appLanguage]}
          textBeforeDropdown={t('MAILING_PAGE_TEXT.statisticsTable.sortBy')}
          className='mt-4 sm:mt-0'
        />
      </div>
      {statisticsCardList.length ? <div className='flex flex-col'>{statisticsCardList}</div> : null}
      {!isLoading && isSuccess && !data.length && searchString ? (
        <span>{t('MAILING_PAGE_TEXT.statisticsTable.nothingFound')}</span>
      ) : null}
      {!isLoading && !data.length && !searchString && isSuccess ? (
        <span>{t('MAILING_PAGE_TEXT.statisticsTable.noData')}</span>
      ) : null}
      {!isLoading && isSuccess ? (
        <TablePagination
          component='div'
          page={currentPage}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          rowsPerPageOptions={PAGINATION_ROWS_OPTIONS}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('MAILING_PAGE_TEXT.statisticsTable.paginatorText')}
          style={{ margin: '0, 30px' }}
        />
      ) : null}
    </div>
  );
});
