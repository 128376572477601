export type TTemplateInfo = {
  active: boolean;
  id: string;
  name: string;
  time: string;
  title: string;
  link: string;
  event: string;
  sendOnlyLostClient: boolean;
  templateSendTime?: string | undefined;
};

export type TTemplateInfoObj = Record<string, TTemplateInfo>;

export enum TASK_LIST_KEY_NAMES {
  ACTIVE = 'active',
  ID = 'id',
  NAME = 'name',
  TIME = 'time',
  LINK = 'link',
  TEMPLATE_SEND_TIME = 'templateSendTime',
}
