import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { IconWithTooltips } from '@components/iconWithTooltips';
import { THelpKeys, TWithOptionalChildren } from '@models/index';
import { IconWithDrawer } from '@components/iconWithDrawer';

interface ITextWithInfoIconProps extends TWithOptionalChildren {
  /**
   * Ключ для выбора текста в label текста берется из словаря по переданному ключу
   * @param {THelpKeys}
   */
  variant: THelpKeys;
  /**
   * Опциональный параметр, размер текста в label
   * @param {string}
   * @default
   */
  fontSize?: string;
  /**
   * Опциональный параметр, размер нижнего отступа для иконки (добавляется к mb-)
   * @param {string}
   * @default
   */
  marginBottom?: string;
  /**
   * Опциональный параметр отображает обучающую подсказку
   * @param {boolean}
   */
  isFirstTooltip?: boolean;
  /**
   * Флаг для сокрытия иконки с подсказкой
   * @param {boolean}
   */
  noIcon?: boolean;
  /**
   * Флаг меняет логику отображения иконки и текста подсказки
   * @param {boolean}
   */
  isShowHelpInDrawer?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TextWithInfoIcon = memo(
  ({
    noIcon,
    variant,
    children,
    marginBottom,
    fontSize = '',
    isFirstTooltip,
    isShowHelpInDrawer,
    className = '',
  }: ITextWithInfoIconProps) => {
    const { t } = useTranslation();

    return (
      <div
        className={`flex ${
          isShowHelpInDrawer ? 'flex-wrap' : ''
        } items-center font-medium font-inter ${className}`}>
        <p className={`m-0 mr-2 ${fontSize}`}>{t(`LABEL_TEXT.${variant}`)}</p>
        <div className={children ? 'mr-2' : ''}>{children}</div>
        {isShowHelpInDrawer ? (
          <IconWithDrawer
            noIcon={noIcon}
            tooltips={variant}
            isFirstTooltip={isFirstTooltip}
            className={marginBottom ? `mb-${marginBottom}` : 'mb-0'}
          />
        ) : (
          <IconWithTooltips
            noIcon={noIcon}
            tooltips={variant}
            isFirstTooltip={isFirstTooltip}
            className={marginBottom ? `mb-${marginBottom}` : 'mb-0'}
          />
        )}
      </div>
    );
  },
);

TextWithInfoIcon.displayName = 'TextWithInfoIcon';
