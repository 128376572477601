import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { inviteFriendsApi } from '@api/inviteFriendsApi';
import { TCopyTemplateData } from '@redux/template/models';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { REJECT_RESPONSE_KEY, TSwitchActionRequestData } from '@api/types';
import { inviteFriendsListMapper } from '@redux/inviteFriendList/inviteFriendsListMapper';
import { copyActionToBranchThunk } from '@redux/inviteFriendsAction/inviteFriendsActionThunk';
import { inviteFriendsActionMappers } from '@redux/inviteFriendsAction/inviteFriendsActionMappers';
import {
  TSwitchActionData,
  TInviteFriendListData,
  TInviteFriendBonusType,
} from '@redux/inviteFriendList/types';

/**
 * Thunk Запрашивает список всех шаблонов акций.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const getActionListThunk = createAsyncThunk<
  { list: TInviteFriendListData[]; order: number[] } | void,
  void,
  { rejectValue: TRejectResponseData }
>('inviteFriend/getActionList', (_, { dispatch, rejectWithValue }) => {
  return inviteFriendsApi
    .getActionList()
    .then(response => {
      return {
        list: inviteFriendsListMapper.actionListToSate(response.data.data),
        order: response.data.order,
      };
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getActionListThunk()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Удаляет шаблон по id
 * @param {string} id - id шаблона.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const deleteActionThunk = createAsyncThunk<
  string | void,
  string,
  { rejectValue: TRejectResponseData }
>('inviteFriendSlice/deleteActionThunk', (id: string, { dispatch, rejectWithValue }) => {
  return inviteFriendsApi
    .deleteAction(id)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && response.data.ok) {
        return id;
      }
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(response.data.status);
      }
    })
    .catch(error => {
      if (error?.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(deleteActionThunk(id)), 200);
      }
      return rejectWithValue(error?.response?.data);
    });
});

/**
 * Thunk переключает активность шаблона по id
 * @param {{ id: string; status: boolean }} id - id шаблона, status - новый статус шаблона.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const switchActionThunk = createAsyncThunk<
  { data: TSwitchActionData; loyaltyPermissionStatus: boolean } | void,
  TSwitchActionData,
  { rejectValue: TRejectResponseData }
>('inviteFriendSlice/switchActionThunk', (data, { dispatch, rejectWithValue }) => {
  const requestData: TSwitchActionRequestData = {
    status_template: !data.status,
    template_id: data.id,
  };

  return inviteFriendsApi
    .switchAction(requestData)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data) {
        return { data, loyaltyPermissionStatus: !!response.data.loyalty_permission_status };
      }
      // if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
      //   throw new Error(response.data.status);
      // }
    })
    .catch(error => {
      if (error?.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(switchActionThunk(data)), 200);
      }
      return rejectWithValue(error?.response?.data);
    });
});

/**
 * Thunk-экшен для копирования шаблона в текущий филиал.
 * @param {TCopyTemplateData} данные для копирования, список филиалов и id шаблона.
 * @returns {Promise<void>} - Данные шаблона или  void в случае ошибки.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
 */
export const copyActionToFilialThunk = createAsyncThunk<
  TInviteFriendListData | void,
  TCopyTemplateData,
  { rejectValue: TRejectResponseData }
>('inviteFriendSlice/copyActionToFilialThunk', (data, { dispatch, rejectWithValue }) => {
  return inviteFriendsApi
    .copyActionToBranch(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(response.data.status);
      }
      if (Array.isArray(response.data)) {
        const { id, title, bonusType, cardType, active } =
          inviteFriendsActionMappers.actionDataToStore(response.data[0]);

        return {
          id,
          title,
          cardType,
          bonusType: bonusType as TInviteFriendBonusType,
          isActive: active,
        };
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(copyActionToBranchThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});
