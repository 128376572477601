import { ONE_DAY_IN_MS } from '@const/common';
import { ACTIVE_SEND_OUT_STATUSES } from '@const/mailing';
import { TSendOutDetailsStatisticsData } from '@models/index';
import { TSendOutsData, TMailingTableData, TParsedXlsxFileData } from '@redux/mailing/models';
import { TCalendarResponseData, TMailingProcessData, TResponseWithOkAndData } from '@api/types';

export const keysMockData = {
  name: 'name',
  phone: 'phone',
  test: 'test',
  info: 'information',
};

export const mailingData: TMailingTableData[] = [
  {
    id: 123,
    date: '12.12.22',
    time: '12:00',
    text: 'test text3',
    process: '0/1000',
    status: ACTIVE_SEND_OUT_STATUSES.IS_PLANED,
    do: '',
  },
  {
    id: 234,
    date: '13.11.22',
    time: '12:00',
    text: 'test text1',
    process: '20/100',
    status: ACTIVE_SEND_OUT_STATUSES.IS_STARTED,
    do: '',
  },
  {
    id: 567,
    date: '14.06.22',
    time: '12:00',
    text: 'test text2',
    process: '10/100',
    status: ACTIVE_SEND_OUT_STATUSES.IS_ON_PAUSE,
    do: '',
  },
  {
    id: 568,
    date: '18.07.22',
    time: '12:00',
    text: '{name}, добрый вечер☀️\r\n\r\nПриглашаем Вас на свой *День рождения* 🎉\r\n*9 и 10 сентября* будем рады видеть вас в нашей студии! Мы проводим *Дни открытых дверей* 🤩\r\nНаших гостей ждут: \r\n🎈 SPA-уход для рук каждому гостю\r\n🎈 Скидки на абонементы, косметику *до 48%*\r\n🎈 Скидки на *новые разовые услуги* студии (LPG-массаж, косметические уходы для лица) *до 50%*\r\n🎈 Чай, кофе, горячий шоколад, лимонад\r\n🎈 Конфеты, печеньки, зефирки\r\n🎈 *Игристое* каждому гостю \r\n🎈 Приятная атмосфера праздника\r\n🎈 Подарки всем гостям и атмосферные фото\r\n🎈 *Беспроигрышная лотерея* для всех гостей\r\n\r\nПодробности в посте: https://www.instagram.com/p/Ch4WzhTN4KY/ или https://vk.com/saharvoskbgd?w=wall-169905524_959\r\n\r\nКакой абонемент с максимальной выгодой предложить для Вас: косметические уходы / LPG-массаж или SHR (лазерная) эпиляция? 🤗',
    process: '90/100',
    status: ACTIVE_SEND_OUT_STATUSES.IS_ON_PAUSE,
    do: '',
  },
];

export const emptyParsedXSLXFileData: TParsedXlsxFileData = {
  count: 0,
  sendOutBatchLen: 0,
  sendDays: 0,
  visitsMedian: 0,
  lastVisitTimeMedian: 0,
  duration: 0,
  allowedStart: '',
  immediateStart: false,
  instanceId: '',
  timeToMsk: 0,
  keys: {},
};

export const parsedXlsxFileMockData: TParsedXlsxFileData = {
  count: 501,
  sendDays: 1,
  sendOutBatchLen: 500,
  visitsMedian: 15,
  lastVisitTimeMedian: 7,
  duration: 86590000,
  allowedStart: '2024-05-18T14:54:30',
  immediateStart: true,
  instanceId: '',
  timeToMsk: 0,
  keys: keysMockData,
};

export const parsedXlsxFileResponseMockData: TResponseWithOkAndData<TMailingProcessData> = {
  ok: true,
  data: {
    count: 520,
    sendout_batch_len: 500,
    send_days: 1,
    visits_median: 0,
    last_visit_time_median: 0,
    duration: 86590000,
    instance_id: '',
    time_to_msk: 0,
    keys: keysMockData,
  },
};

export const SendOutDetailsStatisticsMockData: TSendOutDetailsStatisticsData[] = [
  {
    phone: '79167848284',
    name: 'Илья',
    recordTime: '-',
    replyTime: '-',
    service: '-',
    summaryCost: 0,
  },
];

export const sendOutsMockData: TSendOutsData[] = [
  {
    sendOutId: '123456',
    startDate: new Date(2024, 3, 1),
    endDate: new Date(2024, 3, 5),
    duration: 5 * ONE_DAY_IN_MS,
    isNew: false,
    isActiveSendOut: false,
    totalCount: 1800,
    daySendOutLimit: 500,
    remainder: 300,
  },
  {
    sendOutId: '123457',
    startDate: new Date(2024, 3, 20),
    endDate: new Date(2024, 3, 25),
    duration: 5 * ONE_DAY_IN_MS,
    isNew: false,
    isActiveSendOut: false,
    totalCount: 1900,
    daySendOutLimit: 500,
    remainder: 400,
  },
  {
    sendOutId: '123458',
    startDate: new Date(2024, 3, 26),
    endDate: new Date(2024, 3, 27),
    duration: 2 * ONE_DAY_IN_MS,
    isNew: false,
    isActiveSendOut: false,
    totalCount: 1600,
    daySendOutLimit: 500,
    remainder: 100,
  },
  {
    sendOutId: '123459',
    startDate: new Date(2024, 3, 27),
    endDate: new Date(2024, 3, 27),
    duration: 1,
    isNew: false,
    isActiveSendOut: false,
    totalCount: 1,
    daySendOutLimit: 500,
    remainder: 0,
  },
  {
    sendOutId: '123460',
    startDate: new Date(2024, 3, 28),
    endDate: new Date(2024, 3, 28),
    duration: ONE_DAY_IN_MS,
    isNew: false,
    isActiveSendOut: false,
    totalCount: 100,
    daySendOutLimit: 500,
    remainder: 400,
  },
  {
    sendOutId: '123461',
    startDate: new Date(2024, 3, 28),
    endDate: new Date(2024, 3, 28),
    duration: ONE_DAY_IN_MS,
    isNew: false,
    isActiveSendOut: true,
    totalCount: 1,
    daySendOutLimit: 500,
    remainder: 499,
  },
  {
    sendOutId: '123462',
    startDate: new Date(2024, 3, 28),
    endDate: new Date(2024, 3, 28),
    duration: ONE_DAY_IN_MS,
    isNew: false,
    isActiveSendOut: true,
    totalCount: 1,
    daySendOutLimit: 500,
    remainder: 499,
  },
];

export const emptySendOut: TSendOutsData = {
  isNew: false,
  sendOutId: '00000',
  startDate: null,
  endDate: null,
  duration: 0,
  isActiveSendOut: false,
  totalCount: 0,
  daySendOutLimit: 0,
  remainder: 0,
};

export const notAvailable: TCalendarResponseData[] = [
  {
    start_date: '2024-04-27T14:00:00',
    end_date: '2024-04-30T14:00:00',
    quota_balance: 400,
    duration: 1,
  },
  {
    start_date: '2024-05-01T14:00:00',
    end_date: '2024-05-11T06:00:00',
    quota_balance: 400,
    duration: 1,
  },
  {
    start_date: '2024-05-12T06:00:00',
    end_date: '2024-05-20T14:00:00',
    duration: 1,
    quota_balance: 0,
  },
  {
    start_date: '2024-05-21T14:00:00',
    end_date: '2024-05-30T08:00:00',
    duration: 1,
    quota_balance: 400,
  },
  {
    start_date: '2024-05-31T08:00:00',
    end_date: '2024-06-03T17:00:00',
    duration: 1,
    quota_balance: 0,
  },
  {
    start_date: '2024-06-04T17:00:00',
    end_date: '2024-06-15T11:00:00',
    duration: 1,
    quota_balance: 400,
  },
  {
    start_date: '2024-06-16T11:00:00',
    end_date: '2024-06-24T02:00:00',
    duration: 1,
    quota_balance: 50,
  },
  {
    start_date: '2024-06-25T02:00:00',
    end_date: '2024-07-05T08:00:00',
    duration: 1,
    quota_balance: 350,
  },
  {
    start_date: '2024-07-06T08:00:00',
    end_date: '2024-07-14T17:00:00',
    duration: 1,
    quota_balance: 100,
  },
  {
    start_date: '2024-07-15T17:00:00',
    end_date: '2024-07-21T13:00:00',
    duration: 1,
    quota_balance: 100,
  },
  {
    start_date: '2024-07-22T13:00:00',
    end_date: '2024-08-02T05:00:00',
    duration: 1,
    quota_balance: 400,
  },
  {
    start_date: '2024-08-03T05:00:00',
    end_date: '2024-08-09T14:00:00',
    duration: 1,
    quota_balance: 200,
  },
  {
    start_date: '2024-08-10T14:00:00',
    end_date: '2024-08-19T06:00:00',
    duration: 1,
    quota_balance: 300,
  },
  {
    start_date: '2024-08-20T06:00:00',
    end_date: '2024-08-24T16:00:00',
    duration: 1,
    quota_balance: 200,
  },
  {
    start_date: '2024-08-25T16:00:00',
    end_date: '2024-08-29T09:00:00',
    duration: 1,
    quota_balance: 0,
  },
  {
    start_date: '2024-08-30T09:00:00',
    end_date: '2024-09-07T19:00:00',
    duration: 1,
    quota_balance: 100,
  },
  {
    start_date: '2024-09-08T19:00:00',
    end_date: '2024-09-19T18:00:00',
    duration: 1,
    quota_balance: 300,
  },
  {
    start_date: '2024-09-20T18:00:00',
    end_date: '2024-09-22T13:00:00',
    duration: 1,
    quota_balance: 0,
  },
  {
    start_date: '2024-09-23T13:00:00',
    end_date: '2024-09-27T05:00:00',
    duration: 1,
    quota_balance: 100,
  },
  {
    start_date: '2024-09-28T05:00:00',
    end_date: '2024-10-08T13:00:00',
    duration: 1,
    quota_balance: 450,
  },
];

// export const notAvailableTimeParser = (
//   notAvailableData: TCalendarResponseData[],
//   daySendOutLimit: number,
// ) => {
//   return notAvailableData.map(item => {
//     const { start_date, end_date, quota_balance } = item;
//     const startDateTimeStamp = new Date(start_date).getTime();
//     const endDateTimeStamp = new Date(end_date).getTime();
//
//     return {
//       isNew: false,
//       startDate: new Date(start_date),
//       endDate: new Date(end_date),
//       duration: endDateTimeStamp - startDateTimeStamp,
//       isActiveSendOut: false,
//       sendOutId: '00000',
//       totalCount: 0,
//       daySendOutLimit,
//       remainder: daySendOutLimit - quota_balance,
//     };
//   });
// };
