import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Card } from '@components/card';
import { TAppLanguage } from '@models/index';
import { useAppDispatch } from '@store/store';
import { TTemplateOrderType } from '@api/types';
import { CustomSpinner } from '@blocks/customSpinner';
import { SortableItem } from '@components/sortableItem';
import { TemplateCardWrapper } from '@blocks/templateCardWrapper';
import { TActionOnTemplate, TTemplateAction } from '@shared/types';
import { TEMPLATE_SEND_TIME, TIME_EVENT_NAME } from '@const/templates';
import { setTemplateOrder } from '@redux/templateList/templateListSlice';
import { TTemplateInfo, TTemplateInfoObj } from '@redux/templateList/types';
import { EventTemplateListWrapper } from '@blocks/eventTemplateListWrapper';
import { setTemplatesOrderThunk } from '@redux/templateList/templateListThunks';

interface ICardsListProps {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Флаг шаблонов по времени
   * @param {boolean}
   */
  isEm: boolean;
  /**
   * Флаг шаблонов по событию
   * @param {boolean}
   */
  isTm: boolean;
  /**
   * Флаг состояния загрузки
   * @param {boolean}
   */
  isFetching: boolean;
  /**
   * Callback для копирования шаблона
   * @param {TActionOnTemplate}
   */
  duplicateTemplate: TActionOnTemplate;
  /**
   * Callback для удаления шаблона
   * @param {TTemplateAction}
   */
  deleteTemplate: TTemplateAction;
  /**
   * Callback для переключения активности шаблона шаблона
   * @param {TTemplateAction}
   */
  switchTask: TTemplateAction;
  /**
   * Флаг доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess: boolean;
  /**
   * Массив с Id для формирования списка DND
   * @param {number[]}
   */
  templateOrder: number[];
  /**
   * Объект с шаблонами для упрощения формирования списка компонентов
   * @param { TTemplateInfoObj }
   */
  templateInfoObj: TTemplateInfoObj;
  /**
   * Массив данных для отображения карточек шаблонов
   * @param {TTemplateInfo[]}
   */
  data: TTemplateInfo[];
}

export const CardList = memo(
  ({
    isEm,
    isTm,
    isFetching,
    switchTask,
    appLanguage,
    templateOrder,
    deleteTemplate,
    templateInfoObj,
    duplicateTemplate,
    isEditTemplatesAccess,
    data,
  }: ICardsListProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isBeforeDropdownOpen, setIsBeforeDropdownOpen] = useState<boolean>(true);
    const [isAfterDropdownOpen, setIsAfterDropdownOpen] = useState<boolean>(true);

    const beforeDropdownToggle = useCallback(() => {
      setIsBeforeDropdownOpen(prevState => !prevState);
    }, []);

    const afterDropdownToggle = useCallback(() => {
      setIsAfterDropdownOpen(prevState => !prevState);
    }, []);

    const generateCard = useCallback(
      (card: TTemplateInfo) => {
        const isTimeTemplate = card.event === TIME_EVENT_NAME.TIMING_MESSAGE;
        const isEventTemplate = card.event !== TIME_EVENT_NAME.TIMING_MESSAGE;

        return (
          <SortableItem key={card.id} id={+card.id.slice(1)}>
            <Card
              isTm={isTimeTemplate}
              isEm={isEventTemplate}
              appLanguage={appLanguage}
              duplicateTemplate={duplicateTemplate}
              isEditTemplatesAccess={isEditTemplatesAccess}
              key={card.id}
              data={card}
              deleteTask={deleteTemplate({
                id: card.id,
                state: card.active,
                tm: isTimeTemplate,
                em: isEventTemplate,
              })}
              switchTask={switchTask({
                id: card.id,
                state: card.active,
                tm: isTimeTemplate,
                em: isEventTemplate,
              })}
            />
          </SortableItem>
        );
      },
      [appLanguage, duplicateTemplate, isEditTemplatesAccess, deleteTemplate, switchTask],
    );

    const additionalTemplateOrder = useRef<number[]>([]);

    // Список всех шаблонов
    const cardsAll = useMemo(() => {
      if (isEm && isTm) {
        additionalTemplateOrder.current = [];

        const orderedTemplates = templateOrder.map(id => {
          const templateInfo = templateInfoObj[id];

          return templateInfo ? generateCard(templateInfo) : null;
        });

        if (orderedTemplates.length !== data.length) {
          const additionToOrderedTemplates = data.map(item => {
            if (!templateOrder.includes(+item.id.slice(1))) {
              additionalTemplateOrder.current.push(+item.id.slice(1));

              return generateCard(item);
            }
            return null;
          });

          if (additionToOrderedTemplates.length) {
            orderedTemplates.push(...additionToOrderedTemplates);
          }
        }
        return orderedTemplates;
      }
      return null;
    }, [isEm, isTm, data, generateCard, templateOrder, templateInfoObj, additionalTemplateOrder]);

    // Данные расположения шаблонов по времени
    const timeTemplateOrder: Record<string, number[]> = useMemo(
      () => ({
        before: [],
        after: [],
      }),
      [],
    );

    // Список шаблонов по времени
    const cardsBefore = useMemo(() => {
      timeTemplateOrder.before = [];

      if (!isEm && isTm) {
        return templateOrder.map(id => {
          const templateInfo = templateInfoObj[id];

          if (
            templateInfo &&
            templateInfo.templateSendTime?.includes(TEMPLATE_SEND_TIME.SEND_BEFORE_EVENT)
          ) {
            timeTemplateOrder.before.push(id);

            return generateCard(templateInfo);
          }

          return null;
        });
      }

      return null;
    }, [isEm, isTm, generateCard, templateOrder, templateInfoObj, timeTemplateOrder]);

    // Список шаблонов по времени
    const cardsAfter = useMemo(() => {
      timeTemplateOrder.after = [];

      if (!isEm && isTm) {
        return templateOrder
          .map(id => {
            const templateInfo = templateInfoObj[id];

            if (
              templateInfo &&
              templateInfo.templateSendTime?.includes(TEMPLATE_SEND_TIME.SEND_AFTER_EVENT)
            ) {
              timeTemplateOrder.after.push(id);

              return generateCard(templateInfo);
            }

            return null;
          })
          .filter(Boolean);
      }

      return null;
    }, [isEm, isTm, generateCard, templateInfoObj, templateOrder, timeTemplateOrder]);

    // Список шаблонов по событиям
    const cardsEvent = useMemo(() => {
      return isEm && !isTm
        ? templateOrder.map(id => {
            const templateInfo = templateInfoObj[id];

            return templateInfo ? generateCard(templateInfo) : null;
          })
        : null;
    }, [isEm, isTm, generateCard, templateInfoObj, templateOrder]);

    const setTemplateOrderHandler = useCallback(
      (templatesType: TTemplateOrderType) => (order: number[]) => {
        dispatch(
          setTemplatesOrderThunk({
            order,
            templatesType,
          }),
        );
        dispatch(setTemplateOrder(order));
      },
      [dispatch],
    );

    const setBeforeTimeTemplateOrder = useCallback(
      (order: number[]) => {
        const set = new Set([...order, ...timeTemplateOrder.after, ...templateOrder]);

        const newOrder = Array.from(set);

        dispatch(
          setTemplatesOrderThunk({
            order: newOrder,
            templatesType: 'timing',
          }),
        );
        dispatch(setTemplateOrder(newOrder));
      },
      [timeTemplateOrder.after, templateOrder, dispatch],
    );

    const setAfterTimeTemplateOrder = useCallback(
      (order: number[]) => {
        const set = new Set([...timeTemplateOrder.before, ...order, ...templateOrder]);

        const newOrder = Array.from(set);

        dispatch(
          setTemplatesOrderThunk({
            order: newOrder,
            templatesType: 'timing',
          }),
        );
        dispatch(setTemplateOrder(newOrder));
      },
      [timeTemplateOrder.before, templateOrder, dispatch],
    );

    return (
      <div className='px-4 pb-2 sm:px-0'>
        {isFetching ? <CustomSpinner /> : null}
        {isEm && isTm ? (
          <TemplateCardWrapper
            templateOrder={
              additionalTemplateOrder.current.length
                ? [...templateOrder, ...additionalTemplateOrder.current]
                : templateOrder
            }
            onDragEndCallback={setTemplateOrderHandler('all')}
            templateCard={cardsAll}
          />
        ) : null}
        {isEm && !isTm ? (
          <TemplateCardWrapper
            templateOrder={templateOrder}
            onDragEndCallback={setTemplateOrderHandler('event')}
            templateCard={cardsEvent}
          />
        ) : null}
        {!isEm && isTm ? (
          <>
            <EventTemplateListWrapper
              cartList={cardsBefore}
              isOpen={isBeforeDropdownOpen}
              onClickCallback={beforeDropdownToggle}
              templateOrder={timeTemplateOrder.before}
              onDragEndCallback={setBeforeTimeTemplateOrder}
              text={t('TEMPLATE_LIST_PAGE_TEXT.beforeVisit')}
              className='mb-10'
            />
            <EventTemplateListWrapper
              cartList={cardsAfter}
              isOpen={isAfterDropdownOpen}
              onClickCallback={afterDropdownToggle}
              templateOrder={timeTemplateOrder.after}
              onDragEndCallback={setAfterTimeTemplateOrder}
              text={t('TEMPLATE_LIST_PAGE_TEXT.afterVisit')}
            />
          </>
        ) : null}
      </div>
    );
  },
);

CardList.displayName = 'CardList';
