import React, { memo, useCallback } from 'react';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Callout } from '@uikit/Callout';
import { TAppLanguage } from '@models/index';
import { TSetSelectedRecipientHandler } from '@shared/types';
import { RecipientSelectItem } from '@blocks/recipientSelectItem';
import { TRecipientKeys } from '@redux/mailing/mailingInitialState';

type TProps = {
  /**
   * Выбранный язык в приложении
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Имя загруженного файла
   * @param {string}
   */
  fileName: string;
  /**
   * Callback для очистки загруженного файла
   * @param {() => void}
   */
  clearClientFileHandler: () => void;
  /**
   * Отправить клиентам согласившимся на рассылку
   * @param {boolean}
   */
  sendToConsentingRecipients: boolean;
  /**
   * Отправить клиентам от которых не получено согласие на рассылку
   * @param {boolean}
   */
  sendToNeutralRecipient: boolean;
  /**
   * Количество получателей от которых получено согласие на рассылку
   * @param {number}
   */
  consentingRecipientsLength: number;
  /**
   * Количество получателей от которых не получено согласие на рассылку
   * @param {number}
   */
  noConsentingRecipientsLength: number;
  /**
   * Количество получателей от которых получен отказ на получение рассылок
   * @param {number}
   */
  rejectRecipientsLength: number;
  /**
   * Общее количество получателей
   * @param {number}
   */
  totalRecipients: number;
  /**
   * Обрабатывает клики на на блоках выбора получателей рассылки
   * @param {TSetSelectedRecipientHandler}
   */
  setSelectedRecipientHandler: TSetSelectedRecipientHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const MailingSelectRecipients = memo(
  ({
    fileName,
    appLanguage,
    totalRecipients,
    clearClientFileHandler,
    rejectRecipientsLength,
    sendToNeutralRecipient,
    consentingRecipientsLength,
    sendToConsentingRecipients,
    setSelectedRecipientHandler,
    noConsentingRecipientsLength,
    className = '',
  }: TProps) => {
    const { t } = useTranslation();

    const boldTextStyle = 'font-bold text-[1.25rem] leading-8 tracking-[0.0075em]';

    const selectRecipientToSend = useCallback(
      (blockName: TRecipientKeys) => () => {
        setSelectedRecipientHandler(blockName);
      },
      [setSelectedRecipientHandler],
    );

    let selectedRecipientsNumber = 0;

    if (sendToConsentingRecipients) {
      selectedRecipientsNumber += consentingRecipientsLength;
    }

    if (sendToNeutralRecipient) {
      selectedRecipientsNumber += noConsentingRecipientsLength;
    }

    return (
      <div className={className}>
        <p className={`${boldTextStyle} mb-4`}>{t('MAILING_PAGE_TEXT.selectRecipients.header')}</p>
        {!consentingRecipientsLength ? (
          <Callout
            view='smooth'
            color='warning'
            icon={<Icon icon='info-sign' color='#FB982E' iconSize={20} className='mb-0.5' />}
            className='mb-4'>
            <p className='text-black leading-6 tracking-[0.0275em] my-1'>
              {t('MAILING_PAGE_TEXT.selectRecipients.warning')}
            </p>
          </Callout>
        ) : null}
        <div className='border border-quartz rounded-lg px-[1.75rem] py-6'>
          <div className='flex items-center justify-between'>
            <span className={`${boldTextStyle} break-all m:break-word`}>
              {t('MAILING_PAGE_TEXT.selectRecipients.file', { fileName })}
            </span>
            <div className='min-w-[2.5rem]'>
              <Button
                type='icon'
                view='outlined'
                color='default'
                icon='cross'
                onClick={clearClientFileHandler}
              />
            </div>
          </div>
          <hr className='border-none bg-quartz h-px my-4' />
          <div className='w-full'>
            <RecipientSelectItem
              isShowWarning={false}
              isRejectedBlock={false}
              appLanguage={appLanguage}
              checked={sendToConsentingRecipients}
              numberOfRecipients={consentingRecipientsLength}
              text={t('MAILING_PAGE_TEXT.selectRecipients.consent')}
              selectBlockHandler={selectRecipientToSend('sendToConsentingRecipients')}
              className='mb-2 sm:mb-0 max-w-[23rem]'
            />
            <div className='flex flex-col sm:flex-row w-full'>
              <RecipientSelectItem
                isShowWarning
                isRejectedBlock={false}
                appLanguage={appLanguage}
                checked={sendToNeutralRecipient}
                numberOfRecipients={noConsentingRecipientsLength}
                text={t('MAILING_PAGE_TEXT.selectRecipients.noConsent')}
                selectBlockHandler={selectRecipientToSend('sendToNeutralRecipient')}
                className='mb-2 sm:mb-0 w-full'
              />
            </div>
            <RecipientSelectItem
              isRejectedBlock
              checked={false}
              isShowWarning={false}
              appLanguage={appLanguage}
              numberOfRecipients={rejectRecipientsLength}
              text={t('MAILING_PAGE_TEXT.selectRecipients.reject')}
              selectBlockHandler={selectRecipientToSend('rejectRecipient')}
              className='max-w-[23rem]'
            />
          </div>
          <hr className='border-none bg-quartz h-px my-4' />
          <div className='flex flex-wrap items-center'>
            <div className='bg-aquaSpring rounded-full flex items-end px-4 py-2 mr-2'>
              <span className='font-medium text-[1.125rem] text-btnGreen leading-[1.5rem] tracking-[0.022em]'>
                {selectedRecipientsNumber}
              </span>
              <span className='text-tooltips leading-5 tracking-[0.022em]'>{`/${totalRecipients}`}</span>
            </div>
            <span className='text-darkGray leading-5'>
              {t('MAILING_PAGE_TEXT.selectRecipients.selectedRecipient')}
            </span>
          </div>
        </div>
      </div>
    );
  },
);

MailingSelectRecipients.displayName = 'MailingSelectRecipients';
