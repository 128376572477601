import { apiService } from '@api/ApiService';
import { ConfigENV } from '@const/httpConst';
import { QUERY_PARAMS } from '@const/apiConstants';
import {
  TEventErrorData,
  TCommonEventData,
  TReviewEventData,
  TSendoutEventData,
  TTemplateEventData,
  TMyTrackerEventData,
  TEventFullErrorData,
  TEditTemplateEventData,
  MY_TRACKER_END_POINT_URL,
} from '@helpers/myTracker';

const postEvent = ({
  endpointUrl,
  data = {},
  params,
}: {
  endpointUrl: string;
  data?: any;
  params?: Record<string, string>;
}) => apiService.post(`${ConfigENV.AUTO_CONNECT_BASE_URL}${endpointUrl}`, data, { params });

export const myTrackerApi = {
  generateEvent<T extends object>({
    path,
    data,
    requestParams,
  }: {
    path: string;
    data: T;
    requestParams?: Record<string, string>;
  }) {
    return postEvent({ endpointUrl: path, data, params: requestParams });
  },

  /**
   * Генерит событие авторизации в ЛК
   */
  lkLoginEvent(data: TMyTrackerEventData) {
    return this.generateEvent({
      path: MY_TRACKER_END_POINT_URL.LK_LOGIN,
      data: data.eventProperties,
    });
  },

  /**
   * Генерит событие когда клиент начал заполнение формы подключения ввел любой символ в поля
   * Ваш номер для связи или имя. Либо поменял чекбокс Где с вами лучше связаться?
   */
  startConnectRequestEvent(data: TMyTrackerEventData) {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.START_CONNECT_REQUEST,
      data: data.eventProperties,
    });
  },

  /**
   * Генерит событие когда клиент нажал на кнопку подключить самостоятельно
   */
  autoconnectButtonEvent(data: TMyTrackerEventData) {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.AUTOCONNECT_BUTTON,
      data: data.eventProperties,
    });
  },

  /**
   * Генерит событие открытия страницы автоподключения
   */
  autoconnectPageOpened(data: TMyTrackerEventData) {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.AUTOCONNECT_PAGE_OPEN,
      data: data.eventProperties,
    });
  },

  /**
   * Генерит событие открытия страницы подключения
   */
  openConnectionPage(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие нажатие на кнопку получить QR
   */
  getQrCodeEvent(data: TEventFullErrorData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.CONNECT_QR, data });
  },

  /**
   * Генерит событие успешного получения QR
   */
  qrCodeGotEvent() {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.CONNECT_QR_OPEN,
      params: { [QUERY_PARAMS.REQUEST]: '' },
    });
  },

  /**
   * Генерит событие нажатие на кнопку получить QR
   */
  getQrCodeError(data: TEventErrorData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.CONNECT_QE_ERROR, data });
  },

  /**
   * Генерит событие остановки(отключения) инстанса
   */
  connectionStop() {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.CONNECT_STOP });
  },

  /**
   * Генерит событие перезагрузки инстанса
   */
  connectionReload() {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.CONNECT_RELOAD });
  },

  /**
   * Генерит событие открытия видео инструкции
   */
  openVideoInstruction(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие открытия страницы со списком всех шаблонов
   */
  openAllTemplatePage() {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.OPEN_TEMPLATE_ALL,
      params: { [QUERY_PARAMS.REQUEST]: '' },
    });
  },

  /**
   * Генерит событие открытия страницы со списком шаблонов по событиям
   */
  openEventTemplatePage() {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.OPEN_TEMPLATE_EVENT });
  },

  /**
   * Генерит событие открытия страницы со списком шаблонов по времени
   */
  openTimeTemplatePage() {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.OPEN_TEMPLATE_TIME,
      params: { [QUERY_PARAMS.REQUEST]: '' },
    });
  },

  /**
   * Генерит событие открытия страницы шаблона
   */
  openOneTemplatePage(data: TTemplateEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.OPEN_TEMPLATE, data });
  },

  /**
   * Генерит событие создания шаблона
   */
  createTemplateEvent(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие редактирования шаблона
   */
  editTemplateEvent(data: TEditTemplateEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SAVE_TEMPLATE_CHANGES, data });
  },

  /**
   * Генерит событие включения шаблона
   */
  toggleTemplateOn(data: TTemplateEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.TOGGLE_TEMPLATE_ON, data });
  },

  /**
   * Генерит событие отключения шаблона
   */
  toggleTemplateOff(data: TTemplateEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.TOGGLE_TEMPLATE_OFF, data });
  },

  /**
   * Генерит событие открытия нового шаблона
   */
  openNewTemplate() {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.OPEN_NEW_TEMPLATE,
      params: { [QUERY_PARAMS.REQUEST]: '' },
    });
  },

  /**
   * Генерит событие открытия страницы со списком цепочек отзывов
   */
  openReviewListPage() {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.OPEN_TEMPLATE_CHAIN });
  },

  /**
   * Генерит событие открытия новой цепочки отзывов
   */
  openNewReview(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие сохранения новой цепочки отзывов
   */
  saveNewReview(data: TReviewEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SAVE_TEMPLATE_CHAIN, data });
  },

  /**
   * Генерит событие открытия страницы со списком цепочек отзывов
   */
  openTemplateStatsPage() {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.VIEW_TEMPLATE_STATS,
      params: { [QUERY_PARAMS.REQUEST]: '' },
    });
  },

  /**
   * Генерит событие открытия страницы со со статистикой
   */
  openTemplateStatsByTime(data: TCommonEventData) {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT,
      data,
      params: { [QUERY_PARAMS.REQUEST]: '' },
    });
  },

  /**
   * Генерит событие открытия страницы со со статистикой
   */
  openTemplateStatsByVersion(data: TCommonEventData) {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT,
      data,
      params: { [QUERY_PARAMS.REQUEST]: '' },
    });
  },

  /**
   * Генерит событие отправки статистики шаблона в TG
   */
  exportTemplateStats(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие открытия страницы рассылок
   */
  openSendoutPagePage() {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.OPEN_SENDOUT_PAGE });
  },

  /**
   * Генерит событие скачивания примера шаблона с контактами для рассыли
   */
  sendoutExampleTemplateDownload() {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.DOWNLOAD_TEMPLATE,
      params: { [QUERY_PARAMS.REQUEST]: '' },
    });
  },

  /**
   * Генерит событие загрузки файла с контактами для рассылки
   */
  sendoutXLSXFileUpload() {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.IMPORT_SENDOUT_LIST,
      params: { [QUERY_PARAMS.REQUEST]: '' },
    });
  },

  /**
   * Генерит событие ошибки загрузки файла с контактами для рассылки
   */
  sendoutXLSXFileUploadError(data: TSendoutEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SENDOU_LIST_ERROR, data });
  },

  /**
   * Генерит событие перехода на второй шаг рассылки рассылки
   */
  sendoutSecondStep(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие открыия превью рассылки
   */
  sendoutPreviewOpen() {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.PREVIEW_SENDOUT,
      params: { [QUERY_PARAMS.REQUEST]: '' },
    });
  },

  /**
   * Генерит событие перехода по ссылкам на инструкции
   */
  sendoutHintInstructionOpen(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие добавление емодзи и ввода текста
   */
  sendoutTextareaEvent(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие загрузки файла в рассылках
   */
  sendoutAddFile(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие ошибки при загрузки файла в рассылках
   */
  sendoutAddFileError(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие установки даты отправки рассылки
   */
  sendoutSetDate(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие создания отложенной рассылки
   */
  createScheduledSendout(data: TSendoutEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SHEDULE_SENDOUT, data });
  },

  /**
   * Генерит событие запуска рассылки
   */
  sendoutStart(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие остановки запущенной рассылки
   */
  sendoutStop(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие открытия страницы со статистикой рассылок
   */
  sendoutStartedPadeOpen(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие открытия страницы со статистикой рассылок
   */
  sendoutStatisticsPadeOpen(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие начала поиска рассылки на странице статисти рассылок
   */
  sendoutSearchStart(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие начала поиска рассылки на странице статисти рассылок
   */
  sendoutSearchByDateStart(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие начала поиска рассылки на странице статисти рассылок
   */
  sendoutSortStart(data: TCommonEventData) {
    return postEvent({ endpointUrl: MY_TRACKER_END_POINT_URL.SEND_CUSTOM_EVENT, data });
  },

  /**
   * Генерит событие открытия страницы с подробной статистикой рассылки
   */
  sendoutDetailStatisticsPadeOpen() {
    return postEvent({
      endpointUrl: MY_TRACKER_END_POINT_URL.SENDOUT_ADVANCED_STATS,
      params: { [QUERY_PARAMS.REQUEST]: '' },
    });
  },
};
