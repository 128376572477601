import React, { memo } from 'react';

type TProps = {
  /**
   * Ссылка на картинку
   * @param {string}
   */
  iconSrc: string;
  /**
   * Текст заголовка
   * @param {string}
   */
  headerText: string;
  /**
   * Опциональный текст, отображается под основным заголовком
   * @param {string}
   */
  subheaderText?: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ChannelHeader = memo(
  ({ iconSrc, headerText, subheaderText, className = '' }: TProps) => {
    return (
      <div className={className}>
        <div className='flex items-start'>
          <img
            src={iconSrc}
            alt='channel'
            height={50}
            width={50}
            className='mr-4 h-[3.125rem] w-[3.125rem]'
          />
          <div>
            <h3 className='font-bold text-blackText text-h6Mobile leading-8 tracking-[0.0075em]'>
              {headerText}
            </h3>
            {subheaderText ? (
              <span className='text-balticSea leading-6 tracking-[0.0275em] inline-block mt-2 whitespace-pre-line'>
                {subheaderText}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);

ChannelHeader.displayName = 'ChannelHeader';
