import { PATH_NAMES } from '@data/dict';
import { TMailingInstructions } from '@redux/mailing/models';
import { TAppLanguage, TSimpleStringObj } from '@models/index';

export const NEW_SEND_OUT_ID = '0';

export const ACTIVE_STEP_NUMBER = {
  FIRST_STEP: 0,
  SECOND_STEP: 1,
  THIRD_STEP: 2,
};

export enum ACTIVE_SEND_OUT_STATUSES {
  IS_PLANED = 'Запланирована',
  IS_STARTED = 'Запущена',
  IS_ON_PAUSE = 'На паузе',
}

export const MAILING_PAGES_NAME_DICT: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [PATH_NAMES.MAILING]: 'New mailing',
    [PATH_NAMES.STARTED_MAILING]: 'Started mailings',
    [PATH_NAMES.STATISTICS]: 'Statistics',
    [PATH_NAMES.MAILING_DETAIL_STATS]: 'Statistics',
  },
  rus: {
    [PATH_NAMES.MAILING]: 'Новая рассылка',
    [PATH_NAMES.STARTED_MAILING]: 'Запущенные рассылки',
    [PATH_NAMES.STATISTICS]: 'Статистика',
    [PATH_NAMES.MAILING_DETAIL_STATS]: 'Статистика',
  },
};

export const MAILING_STEPS: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    1: 'Recipients',
    2: 'Contents',
    3: 'Test and launch',
  },
  rus: {
    1: 'Получатели',
    2: 'Содержание',
    3: 'Тест и запуск',
  },
};

export const MAILING_IMPORTANT_INFORMATION_LINK1 = {
  name: 'Видео',
  iconName: 'videoInfo',
  href: 'https://www.youtube.com/watch?v=u64lNL_jNaw&list=PL0CHtlUqXL9aduJ8ZXSwQbK5IyIbq36XG&index=12',
};

export const MAILING_IMPORTANT_INFORMATION_LINK1_ENG = {
  name: 'Video',
  iconName: 'videoInfo',
  href: 'https://youtu.be/7LJXi9-8wqQ',
};

export const MAILING_IMPORTANT_INFORMATION_LINK2 = {
  name: 'Текстом',
  iconName: 'text',
  href: 'https://www.notion.so/botsarmy/37ecddbb17584843b4beffd0b2e44821',
};

export const MAILING_IMPORTANT_INFORMATION_LINK2_ENG = {
  name: 'Text',
  iconName: 'text',
  href: 'https://www.notion.so/botsarmy/37ecddbb17584843b4beffd0b2e44821',
};

export const MAILING_IMPORTANT_INFORMATION_LINK3 = {
  name: 'Примеры',
  iconName: 'mailingExample',
  href: 'https://telegra.ph/Primery-tekstov-rassylok-08-09',
};

export const MAILING_IMPORTANT_INFORMATION_LINK3_ENG = {
  name: 'Examples',
  iconName: 'mailingExample',
  href: 'https://telegra.ph/Primery-tekstov-rassylok-08-09',
};

export const INSTRUCTION_LINKS_DATA: Record<TAppLanguage, TMailingInstructions> = {
  eng: {
    video: MAILING_IMPORTANT_INFORMATION_LINK1_ENG,
    text: MAILING_IMPORTANT_INFORMATION_LINK2_ENG,
    examples: MAILING_IMPORTANT_INFORMATION_LINK3_ENG,
  },
  rus: {
    video: MAILING_IMPORTANT_INFORMATION_LINK1,
    text: MAILING_IMPORTANT_INFORMATION_LINK2,
    examples: MAILING_IMPORTANT_INFORMATION_LINK3,
  },
};

// export const MAILING_FILE_ACCEPT = ['image/*', 'video/*', '.pdf', '.doc', '.docx', '.txt'];

export const MAILING_FILE_ACCEPT = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.apng',
  '.svg',
  '.bmp',
  '.ico',
  '.mp4',
  '.mov',
  '.wmv',
  '.avi',
  '.avchd',
  '.flv',
  '.f4v',
  '.swf',
  '.mkv',
  '.pdf',
  '.doc',
  '.docx',
  '.txt',
];

export const CLIENT_FILE_ACCEPT =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export const MAILING_TOAST_MESSAGES = {
  MESSAGE_SEND: 'Сообщение отправлено',
  MAILING_SEND: 'Рассылка запущена',
  MAILING_SCHEDULED: 'Рассылка запланирована',
  MAILING_STOP: 'Рассылка {{stoppedSendOutId}} успешно остановлена',
  IS_ACTIVE_SEND_OUT_PRESENT:
    'Рассылка накладывается на уже существующую.\n Попробуйте запуск в {{date}}',
  XLSX_FILE_NOT_LOADED: 'Пожалуйста загрузите \n файл с номерами для рассылки',
  FILE_EXTENSION_ERROR: 'Файл должен иметь расширение .xlsx или .xls',
  FILE_READ_ERROR: 'Ошибка чтения. Проверьте файл.',
  XLSX_FILE_PARSE_ERROR: 'Ошибка обработки файла.\n Проверьте правильность загруженного файла.',
};

export const MAILING_TOAST_MESSAGES_ENG = {
  MESSAGE_SEND: 'Message sent',
  MAILING_SEND: 'Mail mailing started',
  MAILING_SCHEDULED: 'Mailout scheduled',
  MAILING_STOP: 'Mail mailing {{stoppedSendOutId}} stopped successfully',
  IS_ACTIVE_SEND_OUT_PRESENT:
    'The mailing is overlaid on an existing one.\n Try launching on {{date}}',
  XLSX_FILE_NOT_LOADED: 'Please upload \nfile with mailing numbers',
  FILE_EXTENSION_ERROR: 'The file must have a .xlsx or .xls extension',
  FILE_READ_ERROR: 'Read error. Check the file.',
  XLSX_FILE_PARSE_ERROR:
    'Error processing file.\n Please check that the file you uploaded is correct.',
};

export const MAILING_MESSAGES = {
  MESSAGE_SEND: 'MAILING_TOAST_MESSAGES.MESSAGE_SEND',
  MAILING_SEND: 'MAILING_TOAST_MESSAGES.MAILING_SEND',
  MAILING_SCHEDULED: 'MAILING_TOAST_MESSAGES.MAILING_SCHEDULED',
  XLSX_FILE_NOT_LOADED: 'MAILING_TOAST_MESSAGES.XLSX_FILE_NOT_LOADED',
};

export enum FORM_DATA_FIELD_NAME {
  MEDIA_FILE = 'mediafile',
  CLIENT_XLSX_FILE = 'clientFile',
  CONSENT_CATEGORIES = 'consent_categories',
  MESSAGE_TEXT = 'text',
  TEXT = 'text',
  KEYS = 'keys',
}

export enum DETAILED_MAILING_STATISTICS_TABLE {
  NO_DATA = '-',
  NO_TIME = 'NaT',
}

export enum SEND_OUT_DETAIL_STATISTICS_FILTERS {
  ALL = 'all',
  NOT_RESPOND = 'notRespond',
  RESPOND = 'respond',
  SIGNED_UP = 'signedUp',
}

export const allSendOutFiltersIsOn = [
  SEND_OUT_DETAIL_STATISTICS_FILTERS.NOT_RESPOND,
  SEND_OUT_DETAIL_STATISTICS_FILTERS.RESPOND,
  SEND_OUT_DETAIL_STATISTICS_FILTERS.SIGNED_UP,
];

export const DETAIL_STATISTIC_FILTER_ITEMS: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [SEND_OUT_DETAIL_STATISTICS_FILTERS.ALL]: 'All clients',
    [SEND_OUT_DETAIL_STATISTICS_FILTERS.NOT_RESPOND]: 'The client did not respond',
    [SEND_OUT_DETAIL_STATISTICS_FILTERS.RESPOND]: 'The client responded',
    [SEND_OUT_DETAIL_STATISTICS_FILTERS.SIGNED_UP]: 'The client has signed up',
  },
  rus: {
    [SEND_OUT_DETAIL_STATISTICS_FILTERS.ALL]: 'Все клиенты',
    [SEND_OUT_DETAIL_STATISTICS_FILTERS.NOT_RESPOND]: 'Клиент не отреагировал',
    [SEND_OUT_DETAIL_STATISTICS_FILTERS.RESPOND]: 'Клиент ответил',
    [SEND_OUT_DETAIL_STATISTICS_FILTERS.SIGNED_UP]: 'Клиент записался',
  },
};

export const monthsName: Record<TAppLanguage, string[]> = {
  eng: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  rus: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
};

export const weekDays: Record<TAppLanguage, string[]> = {
  eng: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  rus: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
};

export const weekDaysGetDayFormat: Record<TAppLanguage, string[]> = {
  eng: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  rus: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
};

export const recipients: Record<TAppLanguage, string[]> = {
  eng: ['recipient', 'recipients', 'recipients'],
  rus: ['получатель', 'получателя', 'получателей'],
};

export const MAILING_PAGE_TEXT = {
  sendOut: 'Рассылки',
  newSendOut: 'Новая рассылка',
  mailingTextInfo:
    'Очень рекомендуем использовать в тексте рассылки:\n{name} - для подстановки имени клиента\n\nВы можете сделать рассылку в виде:\n\n👉 текста\n👉 фото/видео\n👉 фото/видео с текстом\n❗️ Размер файла не может превышать 20 МБ',
  mailingFileInfo:
    'Скачайте файл с клиентами из YCLIENTS:\n\n👉 Зайдите в Клиенты - Клиентская база\n👉 Задайте фильтры чтобы сформировать список нужных клиентов\n👉 Справа наверху - скачайте список в Excel\n\nЕсли вы сами составляли этот файл а не скачали его из YCLIENTS:\n\n👉 столбик с именами должен называться "Имя"\n👉 столбик с телефонами должен называться "Телефон"',
  mailingExamplesBanner: {
    header: 'Шаблоны рассылок к 8 марта',
    button: 'Выбрать',
  },
  mailingInstruction: {
    title: 'Вы можете сделать рассылку, содержащую текст, фото и видео. Рекомендуем ознакомиться с',
    warning: 'предостережениями',
    instructions: 'инструкциями',
    and: 'и',
    examples: 'примерами.',
    instructionHeader: 'Инструкции',
  },
  sendOutPreview: 'Превью рассылки',
  nextButtonTitle: 'Для перехода на следующий шаг добавьте текст рассылки или загрузите файл',
  mailingFirstStepWarning: {
    title:
      'Вы можете сделать рассылку, содержащую текст, фото и видео. Рекомендуем ознакомиться с предостережениями',
    instruction: 'инструкциями',
    and: 'и',
    examples: 'примерами.',
    waiting: 'Внимание, это важно!',
    description: 'Наличие внешней ссылки в тексте повышает риск блокировки.',
    description2: `Используйте {name} в тексте сообщения, так вы делаете каждое сообщение уникальным.`,
  },
  textareaPlaceholder: 'Введите текст',
  showPreview: 'Показать превью',
  addMediaFileHeader: 'Добавить медиафайл (необязательно)',
  addMediaFile: 'Загрузить картинку или видео',
  continueButton: 'Продолжить',
  mailingBlockingWarningText: 'Мы не несём ответственности в случае блокировки вашего вотсапа',
  mailingSecondStepWarning: {
    title: 'Внимание, для уменьшения риска блокировки!',
    description:
      'Не отправляйте сообщения тем, кто вас не знает и не ждёт вашего сообщения. Такие "холодные" клиенты, скорее всего, будут жаловаться на спам и ваш номер может быть заблокирован.',
    description2:
      'Не делайте рассылки с новых номеров вотсапа. Минимальный возраст вотсапа для рассылок - 2 месяца.',
  },
  mailingSecondStepInfo: {
    firstMethod: {
      title: 'Способ 1: скачать файл из YCLIENTS',
      ul: {
        first: 'Зайдите в Клиенты - Клиентская база',
        second: 'Задайте фильтры чтобы сформировать список нужных клиентов',
        third: 'Справа наверху - скачайте список в Excel',
      },
    },
    secondMethod: {
      title: 'Способ 2: подготовить файл в xlsx',
      warningMessage: '❗Важно:',
      ul: {
        first: 'Первый столбец называется «Имя» и содержит имена',
        second: 'Второй столбец называется «Телефон» и содержит телефоны',
      },
    },
    fileExample: 'Шаблон файла для рассылок:',
    download: 'скачать',
  },
  addRecipientFile: 'Добавить файл с получателями',
  uploadRecipientFile: 'Загрузить файл c номерами',
  mailingStartTimeHeader: 'Дата и время старта',
  radioButton: {
    dateAndTime: '{{date}} в {{time}}',
    firstLabelRightNowSend: 'Прямо сейчас',
    firstHelperTextRightNowSend: 'Начнется сразу после запуска',
    firstLabelSunAsPossibleSend: 'Как можно скорее ({{additionalText}})',
    firstHelperTextSunAsPossibleSend: 'Начнется после других запланированных рассылок',
    secondLabel: 'Запланировать на дату',
    secondHelperText: 'Выберите дату и время в календаре',
    today: 'сегодня',
    tomorrow: 'завтра',
  },
  datePickerLabel: 'Выберите дату и время старта',
  inTime: 'в',
  testAndStart: 'Хотите посмотреть как выглядит сообщение?',
  thirdStepDescription:
    'Можете отправить тестовое сообщение себе на номер, ключи в тексте заменяться на значения взятые из первой строки',
  attention: 'Внимание',
  immediateLaunchWarning: 'Прямо сейчас запустить не получится, так как уже идет рассылка',
  sendTest: 'Отправить текст себе',
  rightNowStartSendOut: 'Начать рассылку',
  scheduledStartSendOut: 'Запланировать рассылку',
  startSunAsPossibleSendOut: 'Запустить как можно скорее',
  fileProcessed: 'Файл обрабатывается...',
  file: 'Файл:',
  maxFileSize: 'Размер файла до 20 Мб',
  calendarSendOutElementTitle: 'Рассылка завершается {{date}}',
  selectRecipients: {
    header: 'Укажите кому вы хотите отправить рассылку',
    file: 'Файл: {{fileName}}',
    consent: 'Согласие дали',
    noConsent: 'Нет согласия',
    reject: 'Отказались',
    selectedRecipient: 'Выбрано получателей',
    warning: 'В вашей рассылке нет получателей, которые дали согласие на рассылку!',
    sendAnyway: 'Все равно отправить',
  },
  parsedClientFileInfo: {
    message1: 'Вы собираетесь отправить рассылку для {{count}} {{recipient}}.',
    message2: 'Внимание, вы можете отправить в рассылке {{sendOutBatchLen}} {{messages}} в день',
    message3:
      '{{rest}} {{remainingMessages}} {{messages}} {{willBe}} {{sent}} в это же время {{sendDay}} {{next}} {{sendDays}} {{days}}.',
    message4: 'У которых в среднем:',
    message5: '{{visitsMedian}} {{visits}}',
    message6: '{{lastVisitTimeMedian}} {{days}} с последнего визита',
  },
  mailingTable: {
    searchButton: 'Найти',
    searchPlaceholder: 'Поиск',
    showMoreButton: 'Показать ещё',
    pagination: 'Показать строк:',
    moveButton: 'Перенести',
    stopButton: 'Остановить',
    statisticsButton: 'Статистика',
    id: 'Номер',
    date: 'Дата и время запуска',
    text: 'Сообщения',
    process: 'Отправлено',
    do: 'Действия',
    statusText: {
      planed: 'Запланирована',
      launched: 'Запущена',
      onPause: 'На паузе',
    },
  },
  statisticsTable: {
    searchButton: 'Найти',
    searchPlaceholder: 'Поиск',
    pagination: 'Показать строк:',
    id: 'Номер',
    date: 'Дата и время запуска',
    text: 'Сообщения',
    recipients: 'Получатели',
    answers: 'Ответили',
    signUp: 'Записались',
    conversionRate: 'Конверсия, %',
    sum: 'Сумма записей, ₽',
    actions: 'Действия',
    moreDetailsButton: 'Подробная статистика ',
    started: 'Запущена {{date}}, {{time}}',
    finished: 'Завершена {{date}}, {{time}}',
    stopped: 'Остановлена {{date}}, {{time}}',
    copyText: 'Скопировать текст',
    textCopied: 'Текст скопирован',
    sumOfRecords: 'Сумма записей',
    earningsFromOneSentMessage: 'Заработок с 1-го отправл. сообщения',
    conversion: 'Конверсия',
    segment: 'Сегмент',
    answered: 'Ответили',
    numberOfVisits: 'Кол-во визитов',
    numberOfDaysSinceLastVisit: 'Кол-во дней с последнего визита',
    sortBy: 'Сортировать по',
    paginatorText: 'Показать рассылок:',
    nothingFound: 'Ничего не найдено',
    noData: 'Нет данных',
  },
  mailingDetailStat: {
    allSendOutsButton: 'Все рассылки',
    sendOutId: 'Рассылка #{{sendOutId}}',
    searchButton: 'Найти',
    searchPlaceholder: 'Найти клиента',
    searchInputDescription: 'По номеру телефона',
    sendToTG: 'Отправить в TG',
    sendSuccessMessage: 'Файл отправлен в телеграм',
    pagination: 'Показать строк:',
    phone: 'Номер клиента',
    name: 'Имя клиента',
    replyTime: 'Дата и время ответа',
    recordTime: 'Дата и время записи',
    service: 'Услуга',
    summaryCost: 'Сумма, ₽',
    selected: 'Выбрано: {{number}}',
    selectFormList: 'Выберите из списка',
  },
  mailingCalendar: {
    modalTitle: 'Выберите дату старта',
    todayTag: 'Сегодня',
    limit: 'Лимит: {{daySendOutLimit}} {{messages}} в день',
    cancelButton: 'Отмена',
    selectButton: 'Выбрать',
    newSendOuts: 'Новая',
    commonCalendarError: 'Рассылка длиннее выбранного диапазона',
    sendOutCrossing: 'Выбранные дата/время\n пересекаются с имеющейся рассылкой',
    dateError: 'Неверная дата, ближайшая возможная дата {{date}}',
    reminderText: '{{remainDayLimit}} из {{daySendOutLimit}}',
  },
  sendOutDetailModal: {
    title: 'Недостаточный диапазон дат',
    warning:
      'Для вашей рассылки недостаточно дней со свободным лимитом сообщений. На выбранные даты уже запланирована рассылка',
    willBeStarted: 'Будет запущена {{startDataText}}',
    started: 'Запущена {{startDataText}}',
    numberOfRecipients: 'Кол-во получателей',
    visitsMedian: 'Среднее количество визитов',
    lastVisitTimeMedian: 'Среднее кол-во дней с последнего визита',
    sendOutText: 'Текст рассылки:',
  },
};

export const MAILING_PAGE_TEXT_ENG = {
  sendOut: 'Mailings',
  newSendOut: 'New mailing',
  mailingTextInfo:
    "We highly recommend using in the mailing text:\n{name} - to substitute the client's name\n\nYou can make a mailing in the form of:\n\n👉 text\n👉 photo/video\n👉 photo/video with text\n❗️ The file size cannot exceed 20 MB",
  mailingFileInfo:
    'Download the file with clients from YCLIENTS:\n\n👉 Go to Clients - Client base\n👉 Set filters to create a list of the required clients\n👉 On the right at the top - download the list to Excel\n\nIf you created this file yourself and did not download it from YCLIENTS:\n\n👉 the column with names should be called "Name"\n👉 the column with phones should be called "Phone"',
  mailingExamplesBanner: {
    header: 'March 8 newsletter templates',
    button: 'Select',
  },
  mailingInstruction: {
    title:
      'You can create a newsletter containing text, photos and videos. We recommend that you read',
    warning: 'warnings,',
    instructions: 'instructions',
    and: 'and',
    examples: 'examples.',
    instructionHeader: 'Instructions',
  },
  sendOutPreview: 'Newsletter preview',
  nextButtonTitle: 'To proceed to the next step, add newsletter text or upload a file',
  mailingFirstStepWarning: {
    title:
      'You can create a newsletter containing text, photos and videos. We recommend that you read the warnings,',
    instruction: 'instructions',
    and: 'and',
    examples: 'examples.',
    waiting: 'Attention, this is important!',
    description: 'The presence of an external link in the text increases the risk of blocking.',
    description2: `Use {name} in the message text, this way you make each message unique.`,
  },
  textareaPlaceholder: 'Enter text',
  showPreview: 'Show preview',
  addMediaFileHeader: 'Add media file (optional)',
  addMediaFile: 'Upload image or video',
  continueButton: 'Continue',
  mailingBlockingWarningText: 'We are not responsible if your WhatsApp is blocked',
  mailingSecondStepWarning: {
    title: 'Attention, to reduce the risk of blocking!',
    description:
      "Don't send messages to those who don't know you and aren't waiting for your message. Such 'cold' clients will most likely complain about spam and your number may be blocked.",
    description2:
      "Don't send messages from new WhatsApp numbers. The minimum age of WhatsApp for mailings is 2 months.",
  },
  mailingSecondStepInfo: {
    firstMethod: {
      title: 'Method 1: download a file from YCLIENTS',
      ul: {
        first: 'Go to Clients - Client base',
        second: 'Set filters to form a list of the required clients',
        third: 'On the right at the top - download the list to Excel',
      },
    },
    secondMethod: {
      title: 'Method 2: prepare a file in xlsx',
      warningMessage: '❗Important:',
      ul: {
        first: 'The first column is called "Name" and contains names',
        second: 'The second column is called "Phone" and contains phones',
      },
    },
    fileExample: 'File template for mailings:',
    download: 'download',
  },
  addRecipientFile: 'Add file with recipients',
  uploadRecipientFile: 'Upload file with numbers',
  mailingStartTimeHeader: 'Start date and time',
  radioButton: {
    dateAndTime: '{{date}} at {{time}}',
    firstLabelRightNowSend: 'Right now',
    firstHelperTextRightNowSend: 'Will start immediately after launch',
    firstLabelSunAsPossibleSend: 'As soon as possible ({{additionalText}})',
    firstHelperTextSunAsPossibleSend: 'Will start after other scheduled mailings',
    secondLabel: 'Schedule for date',
    secondHelperText: 'Select date and time in calendar',
    today: 'today',
    tomorrow: 'tomorrow',
  },
  datePickerLabel: 'Select start date and time',
  inTime: 'in',
  testAndStart: 'Would you like to see what the message looks like?',
  thirdStepDescription:
    'You can send a test message to your number, the keys in the text will be replaced with the values taken from the first line',
  attention: 'Attention',
  immediateLaunchWarning:
    "You won't be able to launch it right now, since the mailing is already in progress",
  sendTest: 'Send text to yourself',
  rightNowStartSendOut: 'Start the mailing',
  scheduledStartSendOut: 'Schedule the mailing',
  startSunAsPossibleSendOut: 'Run as soon as possible',
  fileProcessed: 'File is being processed...',
  file: 'File:',
  maxFileSize: 'Size file up to 20 MB',
  calendarSendOutElementTitle: 'Sending ends on {{date}}',
  selectRecipients: {
    header: 'Specify who you want to send the newsletter to',
    file: 'File: {{fileName}}',
    consent: 'Consent granted',
    noConsent: 'No consent',
    reject: 'Refused',
    selectedRecipient: 'Selected recipients',
    warning: 'There are no recipients in your newsletter who have given consent to the newsletter!',
    sendAnyway: 'Send anyway',
  },
  parsedClientFileInfo: {
    message1: 'You are about to send a newsletter to {{count}} {{recipient}}.',
    message2:
      'Please note, you can send {{sendOutBatchLen}} {{messages}} per day in the newsletter',
    message3:
      '{{rest}} {{remainingMessages}} {{messages}} {{willBe}} {{sent}} same time {{sendDay}} {{next}} {{sendDays}} {{days}}.',
    message4: 'Who have on average:',
    message5: '{{visitsMedian}} {{visits}}',
    message6: '{{lastVisitTimeMedian}} {{days}} since last visit',
  },
  mailingTable: {
    searchButton: 'Find',
    searchPlaceholder: 'Search',
    showMoreButton: 'Show more',
    pagination: 'Show rows:',
    moveButton: 'Move',
    stopButton: 'Stop',
    statisticsButton: 'Statistics',
    id: 'Number',
    date: 'Launch date and time',
    text: 'Messages',
    process: 'Sent',
    do: 'Actions',
    statusText: {
      planed: 'Scheduled',
      launched: 'Launched',
      onPause: 'Paused',
    },
  },
  statisticsTable: {
    searchButton: 'Find',
    searchPlaceholder: 'Search',
    pagination: 'Show rows:',
    id: 'Number',
    date: 'Launch date and time',
    text: 'Messages',
    recipients: 'Recipients',
    answers: 'Answered',
    signUp: 'Signed up',
    conversionRate: 'Conversion, %',
    sum: 'Sum of entries, ₽',
    actions: 'Actions',
    moreDetailsButton: 'Detailed statistics',
    started: 'Started {{date}}, {{time}}',
    finished: 'Completed {{date}}, {{time}}',
    stopped: 'Stopped {{date}}, {{time}}',
    copyText: 'Copy text',
    textCopied: 'Text copied',
    sumOfRecords: 'Sum of records',
    earningsFromOneSentMessage: 'Earnings from 1st sent. messages',
    conversion: 'Conversion',
    segment: 'Segment',
    answered: 'Answered',
    numberOfVisits: 'Number of visits',
    numberOfDaysSinceLastVisit: 'Number of days since last visit',
    sortBy: 'Sort by',
    paginatorText: 'Show mailings:',
    nothingFound: 'Nothing found',
    noData: 'No data',
  },
  mailingDetailStat: {
    allSendOutsButton: 'All mailings',
    sendOutId: 'Mailing #{{sendOutId}}',
    searchButton: 'Find',
    searchPlaceholder: 'Find client',
    searchInputDescription: 'By phone number',
    sendToTG: 'Send to TG',
    sendSuccessMessage: 'File sent to telegram',
    pagination: 'Show lines:',
    phone: 'Client number',
    name: 'Client name',
    replyTime: 'Date and time of reply',
    recordTime: 'Date and time of recording',
    service: 'Service',
    summaryCost: 'Amount, ₽',
    selected: 'Selected: {{number}}',
    selectFormList: 'Select from the list',
  },
  mailingCalendar: {
    modalTitle: 'Select start date',
    todayTag: 'Today',
    limit: 'Limit: {{daySendOutLimit}} {{messages}} per day',
    cancelButton: 'Cancel',
    selectButton: 'Select',
    newSendOuts: 'New',
    commonCalendarError: 'The mailing is longer than the selected range',
    sendOutCrossing: 'The selected date/time\n overlaps with an existing mailing',
    dateError: 'Invalid date, the closest possible date is {{date}}',
    reminderText: '{{remainDayLimit}} of {{daySendOutLimit}}',
  },
  sendOutDetailModal: {
    title: 'Not enough date range',
    warning:
      'There are not enough days with free message limit for your mailing. The mailing is already scheduled for the selected dates',
    willBeStarted: '{{startDataText}} will be started',
    started: '{{startDataText}} has been started',
    numberOfRecipients: 'Number of recipients',
    visitsMedian: 'Average number of visits',
    lastVisitTimeMedian: 'Average number of days since the last visit',
    sendOutText: 'Mailing text:',
  },
};

export const MESSAGES: Record<TAppLanguage, string[]> = {
  eng: ['message', 'messages', 'messages'],
  rus: ['сообщение', 'сообщения', 'сообщений'],
};

export const RECIPIENT: Record<TAppLanguage, string[]> = {
  eng: ['recipient', 'recipients', 'recipients'],
  rus: ['получателя', 'получателей', 'получателей'],
};

export const NEXT: Record<TAppLanguage, string[]> = {
  eng: ['next', 'next', 'next'],
  rus: ['следующей', 'следующие', 'следующие'],
};

export const REST: Record<TAppLanguage, string[]> = {
  eng: ['rest', 'rest', 'rest'],
  rus: ['остальное', 'остальные', 'остальные'],
};

export const WILL_BE: Record<TAppLanguage, string[]> = {
  eng: ['will be', 'will be', 'will be'],
  rus: ['будет', 'будут', 'будут'],
};

export const SENT: Record<TAppLanguage, string[]> = {
  eng: ['sent', 'sent', 'sent'],
  rus: ['отправлено', 'отправлены', 'отправлены'],
};

export const IN_ON: Record<TAppLanguage, string[]> = {
  eng: ['on', 'on', 'on'],
  rus: ['на', 'в', 'в'],
};

export const VISITS: Record<TAppLanguage, string[]> = {
  eng: ['visit', 'visits', 'visits'],
  rus: ['визит', 'визита', 'визитов'],
};

export const MAILING: Record<TAppLanguage, string[]> = {
  eng: ['mailing', 'mailings', 'mailings'],
  rus: ['рассылка', 'рассылки', 'рассылок'],
};

export const TEXT_EXAMPLES = [
  {
    title: 'Рассылка с акцией, вариант 1 (вставьте акцию в текст вместо подчеркивания)',
    text:
      '{name}, в преддверии 8 марта мы решили раскрыть секрет идеальной красоты, который удерживали в тайне столетиями. Готовы? Внимание, секрет заключается в... регулярных походах в наш салон! 🎩✨ Да-да, волшебные палочки и зелья отдыхают, когда на сцену выходят наши мастера.\n' +
      '\n' +
      'И чтобы вы не сомневались в магии наших рук, мы предлагаем вам уникальное предложение: \n' +
      '\n' +
      '__________. \n' +
      '\n' +
      'Помните, волшебство – это когда вы выходите от нас, забыв про зеркало, потому что все и так знают – вы прекрасны! 💖\n' +
      '\n' +
      '🌟 Какую волшебную процедуру вы хотели бы превратить в свой праздничный ритуал? Напишите нам и запишитесь на сеанс магии красоты!',
  },
  {
    title: 'Рассылка с акцией, вариант 2 (вставьте акцию в текст вместо подчеркивания)',
    text:
      '{name}, в этот 8 марта мы решили взять на себя миссию спасения мира. Как мы это сделаем? Конечно же, через вашу красоту! 💄🌍 Ведь когда вы красивы, вы счастливы, а когда вы счастливы – мир становится лучше.\n' +
      '\n' +
      'Наш супергеройский подход к делу – это не только высококлассные услуги, но и специальное предложение для наших любимых клиентов: \n' +
      '\n' +
      '__________. \n' +
      '\n' +
      '\n' +
      '💖 Готовы стать частью нашей супермиссии и подарить себе день необыкновенной красоты? Ответьте на это сообщение и забронируйте свое место среди супергероев красоты!',
  },
  {
    title: 'Вежливая рассылка с акцией',
    text:
      '{name}, добрый день!\n' +
      '\n' +
      'Приближается 8 марта, и {название салона} рад предложить вам специальные услуги для вашего праздничного обновления. Подарите себе моменты ухода и красоты с нашим уникальным предложением: __________.\n' +
      '\n' +
      'Наши мастера готовы подчеркнуть вашу индивидуальность и добавить уверенности в ваш образ. Запишитесь на процедуру уже сегодня и сделайте этот 8 марта незабываемым.\n' +
      '\n' +
      '🌷 Записать вас на удобное время?',
  },
  {
    title: 'Про красоту',
    text:
      '{name}, добрый день!\n' +
      '\n' +
      'Подготовьтесь к 8 марта в [название салона], где мы поможем вам обновить образ и почувствовать себя особенно в этот весенний праздник. Наши процедуры направлены не только на улучшение вашей внешности, но и на достижение внутренней гармонии.\n' +
      '\n' +
      'Мы создаем атмосферу расслабления и уюта, чтобы вы могли насладиться заботой о себе. Позвольте этот день стать источником вашего вдохновения и радости.\n' +
      '\n' +
      '🌷 Желаете подобрать удобное время для записи?',
  },
  {
    title: 'Весеннее обновление',
    text:
      '{name}, добрый день, весна – время обновления не только природы, но и вашего образа. Позвольте [название салона] оживить вашу красоту с новыми тенденциями и процедурами. Готовы пробудиться к весне во всей красе? 🌸\n' +
      '🌷 Хотите обновить свой весенний образ?',
  },
  {
    title: 'Сияй как звезда (вставьте название салона в текст вместо подчеркивания)',
    text:
      '✨ {name}, в преддверии праздника давайте сделаем так, чтобы ваша кожа и волосы сияли здоровьем и красотой. ________________ предлагает вам стать звездой этого сезона с нашими особенными уходами. Готовы блестеть? 💫\n' +
      '🌟 Мечтаете сиять как звезда? Напишите и мы подберем вам окошко для записи',
  },
];

export const imagesToMailing = [
  'https://storage.yandexcloud.net/bb.bucket/sendout_1.jpg',
  'https://storage.yandexcloud.net/bb.bucket/sendout_2.jpg',
  'https://storage.yandexcloud.net/bb.bucket/sendout_3.jpg',
  'https://storage.yandexcloud.net/bb.bucket/sendout_4.jpg',
  'https://storage.yandexcloud.net/bb.bucket/sendout_5.jpg',
];

export const INITIAL_SEND_OUT_START_TIME = '10:00';

export const SORT_DROPDOWN_VALUES = {
  DATE: 'date',
  ID: 'id',
  CONVERSION_SIGN_UP: 'conversionSignUp',
  CONVERSION_ANSWERS: 'conversionAnswers',
  ANSWERS: 'answers',
  SUM: 'sum',
};

export const STATISTICS_SORT_DROPDOWN: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [SORT_DROPDOWN_VALUES.DATE]: 'Creation date',
    [SORT_DROPDOWN_VALUES.ID]: 'Distribution number',
    [SORT_DROPDOWN_VALUES.CONVERSION_SIGN_UP]: 'Number of people signed up',
    [SORT_DROPDOWN_VALUES.CONVERSION_ANSWERS]: 'Number of respondents',
    [SORT_DROPDOWN_VALUES.ANSWERS]: 'Number of answers',
    [SORT_DROPDOWN_VALUES.SUM]: 'Sum of records',
  },
  rus: {
    [SORT_DROPDOWN_VALUES.DATE]: 'Дате создания',
    [SORT_DROPDOWN_VALUES.ID]: 'Номеру рассылки',
    [SORT_DROPDOWN_VALUES.CONVERSION_SIGN_UP]: 'Кол-во записавшихся',
    [SORT_DROPDOWN_VALUES.CONVERSION_ANSWERS]: 'Кол-во ответивших',
    [SORT_DROPDOWN_VALUES.ANSWERS]: 'Количеству ответов',
    [SORT_DROPDOWN_VALUES.SUM]: 'Сумме записей',
  },
};

export const SORT_TYPE_TO_REQUEST: Record<string, string> = {
  [SORT_DROPDOWN_VALUES.ID]: 'sendout_id',
  [SORT_DROPDOWN_VALUES.DATE]: 'time_start',
  [SORT_DROPDOWN_VALUES.SUM]: 'earned',
  [SORT_DROPDOWN_VALUES.ANSWERS]: 'answered',
  [SORT_DROPDOWN_VALUES.CONVERSION_ANSWERS]: 'conversion_answers',
  [SORT_DROPDOWN_VALUES.CONVERSION_SIGN_UP]: 'conversion_sign_up',
};

export const PAGINATION_ROWS_OPTIONS = [5, 8, 20];
