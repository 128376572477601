import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { COMMON_TOAST } from '@const/common';
import { TRejectResponseData } from '@models/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { TTemplateInfo, TTemplateInfoObj } from '@redux/templateList/types';
import { IInitialState, initialState } from '@redux/templateList/initialState';
import {
  deleteTemplate,
  switchTemplate,
  getTemplateList,
  duplicateTemplate,
} from '@redux/templateList/templateListThunks';

import i18n from 'i18n';

export const templateListSlice = createSlice({
  name: 'templateList',
  initialState,
  reducers: {
    setTemplateListData(state: IInitialState, action: PayloadAction<TTemplateInfo[]>) {
      state.data = action.payload;
    },
    setTemplateOrder(state: IInitialState, action: PayloadAction<number[]>) {
      state.templateListOrder = action.payload;
    },
    setTemplateIdInProgress(state: IInitialState, action: PayloadAction<string>) {
      state.templateIdInProgress = action.payload;
    },
    setIsFetching(state: IInitialState, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    setIsDeletingSuccess(state: IInitialState, action: PayloadAction<boolean>) {
      state.isDeletingSuccess = action.payload;
    },
    clearErrorsTL(state: IInitialState) {
      state.isError = false;
      state.isCopingError = false;
      state.isDeletingError = false;
      state.isTemplateSwitchingError = false;
    },
    clearSuccessStatuses(state: IInitialState) {
      state.isSuccess = false;
      state.isCopingSuccess = false;
      state.isDeletingSuccess = false;
      state.isTemplateSwitchingSuccess = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getTemplateList.pending, (state: IInitialState) => {
      const { isFetching } = state;
      if (!isFetching) {
        state.isLoading = true;
      }
      state.isError = false;
      state.isSuccess = false;
    });
    builder.addCase(
      getTemplateList.fulfilled,
      (
        state: IInitialState,
        action: PayloadAction<{ list: TTemplateInfo[]; order: number[] } | void>,
      ) => {
        if (action.payload) {
          const { isFetching } = state;
          const { list, order: orderFromApi } = action.payload;

          if (isFetching) {
            state.isFetching = false;
            state.isLoading = false;
          } else {
            state.isLoading = false;
          }

          let order: number[];

          if (!orderFromApi?.length) {
            order = list.map(item => +item.id.slice(1));
          } else {
            order = orderFromApi;
          }

          const templateInfoObj: TTemplateInfoObj = {};

          list.forEach(item => {
            templateInfoObj[item.id.slice(1)] = item;
          });

          state.data = list;
          state.templateListOrder = order;
          state.templateInfoObj = templateInfoObj;
        }

        if (state.isRetries) {
          state.retries = 1;
          state.isRetries = false;
        }
      },
    );
    builder.addCase(
      getTemplateList.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
              state.isRetries = true;
              state.retries = state.retries--;
            } else {
              state.isError = true;
              state.isLoading = false;
            }
          } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isTemplateListAccessDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          } else {
            state.isError = true;
            state.isLoading = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
    builder.addCase(switchTemplate.pending, (state: IInitialState) => {
      state.isTemplateSwitching = true;
      state.isTemplateSwitchingError = false;
      state.isTemplateSwitchingSuccess = false;
    });
    builder.addCase(
      switchTemplate.fulfilled,
      (state: IInitialState, action: PayloadAction<string>) => {
        const id = action.payload;
        state.data = state.data.map(item => {
          if (item.id === id) {
            return { ...item, active: !item.active };
          }
          return item;
        });
        state.isFetching = true;
        state.templateIdInProgress = '';
        state.isTemplateSwitching = false;
        state.isTemplateSwitchingSuccess = true;
      },
    );
    builder.addCase(
      switchTemplate.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isTemplateSwitching = false;
          } else {
            state.isTemplateSwitchingError = true;
            state.isTemplateSwitching = false;
          }
        } else if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isTemplateListAccessDenied = true;
          } else {
            state.isTemplateSwitchingError = true;
          }
          state.isTemplateSwitching = false;
        } else {
          state.isTemplateSwitchingError = true;
          state.isTemplateSwitching = false;
        }
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(deleteTemplate.pending, (state: IInitialState) => {
      state.isDeleting = true;
      state.isDeletingError = false;
      state.deletedTemplateId = '';
    });
    builder.addCase(
      deleteTemplate.fulfilled,
      (state: IInitialState, action: PayloadAction<string>) => {
        state.isFetching = true;
        state.isDeleting = false;
        state.isDeletingSuccess = true;
        state.deletedTemplateId = action.payload;

        state.successMessage = i18n.t('TEMPLATE_LIST_PAGE_TEXT.deleteTemplateSuccess', {
          id: state.deletedTemplateId,
        });
      },
    );
    builder.addCase(
      deleteTemplate.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
            state.isRetries = true;
            state.retries = state.retries--;
          } else {
            state.isDeletingError = true;
            state.isDeleting = false;
          }
        } else if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isTemplateListAccessDenied = true;
          } else {
            state.isDeletingError = true;
          }
          state.isDeleting = false;
        } else {
          state.isDeletingError = true;
          state.isDeleting = false;
        }
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(duplicateTemplate.pending, (state: IInitialState) => {
      state.isCoping = true;
    });
    builder.addCase(
      duplicateTemplate.fulfilled,
      (state: IInitialState, action: PayloadAction<string>) => {
        if (action.payload) {
          state.isCoping = false;
          state.isFetching = true;
          state.isCopingSuccess = true;
          state.successMessage = i18n.t('TEMPLATE_LIST_PAGE_TEXT.duplicateTemplateSuccess');
        }
      },
    );
    builder.addCase(
      duplicateTemplate.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
          }
          if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isTemplateListAccessDenied = true;
            }
          }
        }
        state.isCoping = false;
        state.isCopingError = true;
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
  },
});

export const templateListReducer = templateListSlice.reducer;
export const {
  clearErrorsTL,
  setIsFetching,
  setTemplateOrder,
  setTemplateListData,
  clearSuccessStatuses,
  setIsDeletingSuccess,
  setTemplateIdInProgress,
} = templateListSlice.actions;
