import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { AddFile } from '@components/addFile';
import { FormLabel } from '@blocks/formLabel';
import { useAppSelector } from '@store/store';
import { getAppData } from '@redux/app/selectors';
import { EmojiClickData } from 'emoji-picker-react';
import { MediaPreview } from '@blocks/mediaPreview';
import { DivWithLabel } from '@components/divWithLabel';
import { TEXTAREA_TEXT_MAX_LENGTH } from '@const/common';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { getTemplatePageData } from '@redux/template/selectors';
import { PreviewComponent } from '@components/previewComponent';
import { TemplateAdditionalInfo } from '@blocks/templateAdditionalInfo';
import { PLACEHOLDER_VARIANTS, TEMPLATE_FILE_ACCEPT } from '@const/templates';
import { TEMPLATE_DATA_KEY_NAMES, TTemplatePageData } from '@redux/template/models';
import {
  TClickHandler,
  TOnChangeHandler,
  TDivOnKeyUpHandler,
  TDivOnClickHandler,
  TOnEmojiViewChange,
  TDivOnInputHandler,
  TSetViewEmojiPicker,
  TShowPreviewHandlerInTemplates,
} from '@shared/types';

type TTemplateContentProps = {
  /**
   * Callback срабатывает ввод текста
   * @param {TDivOnInputHandler}
   */
  divOnInputHandler: TDivOnInputHandler;
  /**
   * Callback срабатывает на клик по диву
   * @param {TDivOnClickHandler}
   */
  divOnClickHandler: TDivOnClickHandler;
  /**
   * Callback устанавливает положение курсора
   * @param {TDivOnKeyUpHandler}
   */
  setCursorPosition: TDivOnKeyUpHandler;
  /**
   * Опциональный параметр callback для записи выбранного эмодзи в textarea
   * @param {(emoji: EmojiClickData, event: MouseEvent) => void}
   */
  setEmojiInTextarea?: (emoji: EmojiClickData, event: MouseEvent) => void;
  /**
   * Опциональный параметр
   * @param {TSetViewEmojiPicker}
   */
  setViewEmojiPicker?: TSetViewEmojiPicker;
  /**
   * Опциональный параметр callback для открытия emojiPicker
   * @param {() => void}
   */
  onEmojiViewChange: TOnEmojiViewChange;
  /**
   * Callback для добавления ключа в текст
   * @param {(key: string) => () => void}
   */
  setKeyInTextarea: (key: string) => () => void;
  /**
   * Callback для оборачивания выделенного текста в символы форматирования
   * @param {TClickHandler}
   */
  wrapSelectedTextInTextarea: TClickHandler;
  /**
   * Callback для открытия окна загрузки файла
   * @param {() => void}
   */
  addFileCallback: () => void;
  /**
   * Callback для обработки события change для элемента input
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Callback для инпута с файлом
   * @param {() => void}
   */
  clearInputCallback: () => void;
  /**
   * Данные шаблона
   * @param {TTemplatePageData}
   */
  data: TTemplatePageData;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const TemplateContent = forwardRef<HTMLInputElement, TTemplateContentProps>(
  (
    {
      onChangeHandler,
      addFileCallback,
      setKeyInTextarea,
      divOnClickHandler,
      setCursorPosition,
      divOnInputHandler,
      setEmojiInTextarea,
      setViewEmojiPicker,
      onEmojiViewChange,
      clearInputCallback,
      wrapSelectedTextInTextarea,
      isEditTemplatesAccess = false,
      data,
      className = '',
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);

    const [isSms, setIsSms] = useState<boolean>(false);

    const [previewText, setPreviewText] = useState<string>('');

    const previewDialogToggle = useCallback(
      (sms: boolean) => {
        if (isShowPreview) {
          setIsSms(false);
        } else {
          setIsSms(sms);
        }

        setIsShowPreview(prevState => !prevState);
      },
      [isShowPreview],
    );

    const { appLanguage } = useAppSelector(getAppData);

    const { textSms, mediaFileURL, textWhatsapp, mediaFileType, mediaFileName } = data;

    const { fullFileName, specialKeyDict, textareaKeysData, mediaFileTooBigError } =
      useAppSelector(getTemplatePageData);

    // Показывает модалку с превью текста шаблона
    const showPreviewHandler: TShowPreviewHandlerInTemplates = useCallback(
      (text, isSmsPreview) => () => {
        if (!text) return;
        setPreviewText(text);
        previewDialogToggle(isSmsPreview);
      },
      [previewDialogToggle],
    );

    const memoizedShowWhatsappPreviewButton = useMemo(
      () => (
        <Button
          dense
          type='default'
          view='outlined'
          color='default'
          rightIcon='application'
          text={t('TEMPLATE_PAGE_TEXT.showPreviewButton')}
          onClick={showPreviewHandler(textWhatsapp, false)}
          className='w-[11rem]'
        />
      ),
      [t, showPreviewHandler, textWhatsapp],
    );

    const memoizedShowSMSPreviewButton = useMemo(
      () => (
        <Button
          dense
          type='default'
          view='outlined'
          color='default'
          rightIcon='application'
          text={t('TEMPLATE_PAGE_TEXT.showPreviewButton')}
          onClick={showPreviewHandler(textSms, true)}
          className='w-[11rem]'
        />
      ),
      [t, showPreviewHandler, textSms],
    );

    const memoizedPreviewDialog = useMemo(
      () => (
        <Dialog
          type='default'
          view='raised'
          color='default'
          backdropOpacity={40}
          isOpen={isShowPreview}
          onClose={previewDialogToggle}
          title={t('TEMPLATE_PAGE_TEXT.previewTitle')}
          text={
            isShowPreview ? (
              <PreviewComponent
                description={previewText}
                isFileAdded={!!mediaFileName}
                mediaPreviewComponent={
                  !isSms ? (
                    <MediaPreview
                      isMessageStyle
                      isFileToBig={false}
                      fileURL={mediaFileURL}
                      fileName={mediaFileName}
                      fileType={mediaFileType}
                      clearInputCallback={() => ''}
                      className='mt-4 mb-4'
                    />
                  ) : null
                }
              />
            ) : (
              <span />
            )
          }
          className='whitespace-pre-line break-words report-preview'
        />
      ),
      [
        t,
        isSms,
        previewText,
        mediaFileURL,
        mediaFileName,
        mediaFileType,
        isShowPreview,
        previewDialogToggle,
      ],
    );

    return (
      <div className={className}>
        <h2 className='font-medium mb-4 text-black text-[1.25rem] leading-8 tracking-[0.004em]'>
          {t('TEMPLATE_PAGE_TEXT.templateContent')}
        </h2>
        <DivWithLabel
          isEmoji
          isRequired
          value={textWhatsapp}
          isError={!textWhatsapp}
          specialKeyDict={specialKeyDict}
          disabled={!isEditTemplatesAccess}
          textareaKeysData={textareaKeysData}
          maxLength={TEXTAREA_TEXT_MAX_LENGTH}
          divOnInputHandler={divOnInputHandler}
          divOnClickHandler={divOnClickHandler}
          setCursorPosition={setCursorPosition}
          setEmojiInTextarea={setEmojiInTextarea}
          setViewEmojiPicker={setViewEmojiPicker}
          htmlFor={TEMPLATE_DATA_KEY_NAMES.TEXT_WHATSAPP}
          placeholder={PLACEHOLDER_VARIANTS[appLanguage].textWhatsapp}
          showEmojiPicker={onEmojiViewChange({
            textAreaName: TEMPLATE_DATA_KEY_NAMES.TEXT_WHATSAPP,
            textAreaValue: textWhatsapp,
          })}
          className='mb-2'
        />
        <div className='mb-8'>{memoizedShowWhatsappPreviewButton}</div>
        <DivWithLabel
          isEmoji
          setEmojiInTextarea={setEmojiInTextarea}
          showEmojiPicker={onEmojiViewChange({
            textAreaName: TEMPLATE_DATA_KEY_NAMES.TEXT_SMS,
            textAreaValue: textSms,
          })}
          value={textSms}
          specialKeyDict={specialKeyDict}
          disabled={!isEditTemplatesAccess}
          textareaKeysData={textareaKeysData}
          divOnInputHandler={divOnInputHandler}
          divOnClickHandler={divOnClickHandler}
          setCursorPosition={setCursorPosition}
          setViewEmojiPicker={setViewEmojiPicker}
          htmlFor={TEMPLATE_DATA_KEY_NAMES.TEXT_SMS}
          placeholder={PLACEHOLDER_VARIANTS[appLanguage].textSms}
          className='mb-2'
        />
        <div className='mb-8'>{memoizedShowSMSPreviewButton}</div>
        <TemplateAdditionalInfo
          forMobile
          isWr={false}
          specialKeyDict={specialKeyDict}
          setKeyInTextarea={setKeyInTextarea}
          wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
          className='flex xl:hidden flex-col mb-8'
        />
        {memoizedPreviewDialog}
        <div className={`flex flex-wrap items-center ${mediaFileName ? 'hidden' : ''}`}>
          <FormLabel
            htmlFor=''
            textColor='text-stormGray'
            labelTextVariant='selectFileText'
            fontSize='text-body2 tracking-[0.0275em]'
            className='mb-1'
          />
          <AddFile
            name='files'
            ref={ref}
            iconVariant='addFile'
            addFileCallback={addFileCallback}
            disabled={!isEditTemplatesAccess}
            isFileToBig={mediaFileTooBigError}
            accept={TEMPLATE_FILE_ACCEPT.join(', ')}
            onChangeHandler={onChangeHandler}
            className='min-w-[13.875rem]'>
            {t('TEMPLATE_PAGE_TEXT.addMediaFile')}
          </AddFile>
          <IconWithTooltips tooltips='selectFileText' noIcon />
        </div>
        <MediaPreview
          fileURL={mediaFileURL}
          fileName={mediaFileName}
          fileType={mediaFileType}
          fullFileName={fullFileName}
          isFileToBig={mediaFileTooBigError}
          clearInputCallback={clearInputCallback}
          className={mediaFileURL ? 'mt-3' : ''}
        />
      </div>
    );
  },
);
