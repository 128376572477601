import i18n from 'i18next';
import { TAppLanguage } from '@models/index';
import { templateKeysDict } from '@data/dict';
import { TTemplatePageData } from '@redux/template/models';
import { TTemplateData, TTemplateDataToSave } from '@api/types';
import { mapToNumberArray, mapToStringArray, parseDivValue } from '@helpers/index';
import {
  ATTENDANCE,
  WHEN_SEND_VALUE,
  TYPE_OF_TEMPLATE,
  WHEN_SEND2_VALUE,
  CONSENT_KEY_NAMES,
  TEMPLATE_FORM_DATA_FIELD_NAMES,
} from '@const/templates';

/**
 * Эта функция вынесена для уменьшения дублирования кода и упрощения поддержки в будущем.
 * Она вычисляет значения whenSend2, whenSend2Days и whenSend2Min.
 *
 * @param {string} when_send - Время отправки.
 * @param {string} type - Тип шаблона уведомлений.
 * @returns {[string, string, string]} - Массив, содержащий значения whenSend2, whenSend2Days и whenSend2Min.
 */
const calculateWhenSendValues = (when_send: string, type: string): [string, string, string] => {
  const isAllDayRecNotification = type === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION;
  const isBirthday = type === WHEN_SEND_VALUE.BIRTHDAY;
  const isBeforeOrAfter =
    type === WHEN_SEND_VALUE.BEFORE_START || type === WHEN_SEND_VALUE.AFTER_END;

  const whenSend2 =
    isAllDayRecNotification || isBirthday
      ? +when_send > 0
        ? '1'
        : +when_send < 0
        ? '-1'
        : '0'
      : isBeforeOrAfter
      ? String(Math.floor(+when_send))
      : when_send;

  const whenSend2Days =
    isAllDayRecNotification || isBirthday
      ? String(+when_send * +whenSend2)
      : isBeforeOrAfter
      ? whenSend2
      : '1';

  const whenSend2Min = isBeforeOrAfter ? String((+when_send - +whenSend2) * 60) : '15';

  return [whenSend2, whenSend2Days, whenSend2Min];
};

/**
 * Функция для преобразования данных шаблона в формат, удобный для сохранения в хранилище Redux.
 *
 * @param {TTemplateData} templateData - Данные шаблона.
 * @returns {TTemplatePageData} - Объект с преобразованными данными шаблона в формат, удобным для сохранения в хранилище Redux.
 */
const mapDataToStore = ({
  id,
  title,
  active,
  text_whatsapp,
  text_sms,
  type,
  when_send,
  when_time,
  services,
  categories,
  goods,
  staff,
  client_category,
  attendance,
  visits,
  visits_frequency,
  blacklist,
  send_only_lost_client,
  online,
  confirm_record,
  send_confirm,
  declined_record,
  record_labels,
}: TTemplateData): TTemplatePageData => {
  const [whenSend2, whenSend2Days, whenSend2Min] = calculateWhenSendValues(when_send, type);

  const isConsent =
    whenSend2 === CONSENT_KEY_NAMES.AFTER_CREATE_RECORD ||
    whenSend2 === CONSENT_KEY_NAMES.ON_CONFIRM_RECORD;

  const whenSend = type === WHEN_SEND_VALUE.EVENT && isConsent ? WHEN_SEND_VALUE.CONSENT : type;

  return {
    id,
    title,
    oldTitle: title || `Шаблон #${id}`,
    statusEnable: active,
    textWhatsapp: text_whatsapp || '',
    textSms: text_sms || '',
    files: null,
    mediaFile: null,
    mediaFileType: null,
    mediaFileName: '',
    mediaFileURL: '',
    whenSend,
    whenSend2,
    whenSend2Days,
    whenSend2Min,
    whenTime: when_time,
    error: false,
    recordTag: record_labels.length ? mapToStringArray(record_labels) : [],
    services: mapToStringArray(services),
    categories: mapToStringArray(categories),
    products: mapToStringArray(goods),
    staff: mapToStringArray(staff),
    clientCategory: client_category,
    clientDidNotCome: attendance.includes(ATTENDANCE.CLIENT_DID_NOT_COME),
    clientExpectation: attendance.includes(ATTENDANCE.CLIENT_EXPECTATION),
    clientCame: attendance.includes(ATTENDANCE.CLIENT_CAME),
    clientConfirmed: attendance.includes(ATTENDANCE.CLIENT_CONFIRMED),
    visits: String(!visits_frequency ? '' : visits_frequency),
    quantityFrom: String(visits ? (+visits[0] >= 0 ? visits[0] : '') : ''),
    quantityTo: String(visits ? (!visits[1] ? '' : visits[1]) : ''),
    blacklist,
    sendOnlyLostClient: send_only_lost_client,
    onlineRecord: online || false,
    confirmRecord: send_confirm,
    textConfirmation: confirm_record,
    textCancel: declined_record,
    thoseWhoWrote: '1',
  };
};
/**
 * Код для вычисления when_send вынесен в отдельную функцию для упрощения чтения и поддержки.
 *
 * @param {string} whenSend - тип графика отправки.
 * @param {string} whenSend2 - второй параметр для определения времени отправки.
 * @param {string} whenSend2Days - количество дней для отправки.
 * @param {string} whenSend2Min - количество минут для отправки
 * @returns {string} - рассчитанное время отправки.
 */
const calculateWhenSend = (
  whenSend: string,
  whenSend2: string,
  whenSend2Days: string,
  whenSend2Min: string,
): string => {
  if (
    whenSend === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION ||
    whenSend === WHEN_SEND_VALUE.BIRTHDAY
  ) {
    return String(+whenSend2Days * +whenSend2);
  }

  if (whenSend === WHEN_SEND_VALUE.AFTER_END || whenSend === WHEN_SEND_VALUE.BEFORE_START) {
    return String(+whenSend2Days + +whenSend2Min / 60);
  }

  return whenSend2;
};

/**
 * Функция для преобразования данных шаблона в формат, удобный для сохранения в хранилище Redux.
 *
 * @param {File | null} file - Файл медиа для шаблона.
 * @param {TTemplatePageData} data - Данные шаблона.
 * @param {boolean} isCopy - Флаг копирования шаблона.
 * @returns {FormData} - Данные шаблона в формате FormData.
 */
const mapDataToSave = ({
  file,
  data: {
    clientDidNotCome,
    clientExpectation,
    clientCame,
    clientConfirmed,
    quantityFrom,
    quantityTo,
    id,
    blacklist,
    sendOnlyLostClient,
    title,
    textSms,
    textWhatsapp,
    textCancel,
    textConfirmation,
    recordTag,
    categories,
    clientCategory,
    confirmRecord,
    products,
    whenTime,
    whenSend,
    whenSend2,
    whenSend2Days,
    onlineRecord,
    staff,
    services,
    statusEnable,
    mediaFileName,
    visits,
    whenSend2Min,
  },
  isCopy,
}: {
  file: File | null;
  data: TTemplatePageData;
  isCopy?: boolean;
}): FormData => {
  const attendance: number[] = [];

  if (clientDidNotCome) {
    attendance.push(ATTENDANCE.CLIENT_DID_NOT_COME);
  }
  if (clientExpectation) {
    attendance.push(ATTENDANCE.CLIENT_EXPECTATION);
  }
  if (clientCame) {
    attendance.push(ATTENDANCE.CLIENT_CAME);
  }
  if (clientConfirmed) {
    attendance.push(ATTENDANCE.CLIENT_CONFIRMED);
  }
  const visitsArr: number[] = [];
  if (quantityFrom !== '' && quantityTo !== '') {
    visitsArr[0] = Number(quantityFrom);
    visitsArr[1] = Number(quantityTo);
  }

  const lang = i18n.language as TAppLanguage;

  const div = document.createElement('div');

  const type =
    whenSend2 === WHEN_SEND2_VALUE.INTEGRATION
      ? TYPE_OF_TEMPLATE.INTEGRATION
      : whenSend === WHEN_SEND_VALUE.BIRTHDAY
      ? TYPE_OF_TEMPLATE.BIRTHDAY
      : 'common';

  const specialKeyDict = templateKeysDict[lang][type];

  const templateDataToSave: TTemplateDataToSave = {
    id,
    send_only_lost_client: sendOnlyLostClient,
    attendance,
    title: isCopy ? `${title}${i18n.t('TEMPLATE_PAGE_TEXT.templateCopy.copyTag')}` : title,
    text_sms: textSms,
    text_whatsapp: parseDivValue(div)({
      text: textWhatsapp,
      selectionStart: 0,
      selectionEnd: 0,
      specialKeyDict,
    }),
    // text_whatsapp: textWhatsapp,
    blacklist,
    categories: categories.length ? mapToNumberArray(categories) : [],
    record_labels: recordTag.length ? mapToNumberArray(recordTag) : [],
    client_category: clientCategory,
    send_confirm: confirmRecord,
    confirm_record: textConfirmation,
    declined_record: textCancel,
    goods: products.length ? mapToNumberArray(products) : [],
    when_time: whenTime,
    when_send: calculateWhenSend(whenSend, whenSend2, whenSend2Days, whenSend2Min),
    online: onlineRecord,
    staff: staff.length ? mapToNumberArray(staff) : [],
    services: services.length ? mapToNumberArray(services) : [],
    type: whenSend === WHEN_SEND_VALUE.CONSENT ? WHEN_SEND_VALUE.EVENT : whenSend,
    active: isCopy ? false : statusEnable,
    visits: visitsArr,
    visits_frequency: visits !== '' ? Number(visits) : 0,
  };

  const formData = new FormData();

  Object.entries(templateDataToSave).map(item => formData.append(item[0], JSON.stringify(item[1])));

  if (file) {
    formData.append(TEMPLATE_FORM_DATA_FIELD_NAMES.MEDIA_FILE, file);
    formData.append(TEMPLATE_FORM_DATA_FIELD_NAMES.MEDIA_FILE_NAME, mediaFileName);
  }

  return formData;
};

export const templateMappers = { mapDataToStore, mapDataToSave };
