import { apiService } from '@api/ApiService';
import { ConfigENV } from '@const/httpConst';
import { END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';

import {
  TRequestStatus,
  TGetCalendarData,
  TMailingProcessData,
  TSendOutResponseData,
  TPostStopSendOutData,
  TConsentParseXLSXFile,
  TStartMailingPostData,
  TResponseWithOkAndData,
  TParseXlsxFileResponse,
  TGetCalendarResponseData,
  TParseXLSXFileRequestData,
  TGetSendOutDayResponseData,
  TSendTestMessageRequestData,
  TGetSendOutDetailStatisticsData,
  TSendOutDetailStatisticsResponse,
  TConsentParseXLSXFileRequestData,
} from './types';

/**
 * API страницы рассылок
 * @module mailingApi
 */

export const mailingApi = {
  /**
   * Метод запрашивает статус аккаунта оплачен/не оплачен
   * @method getPaymentStatus
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  getPaymentStatus() {
    return apiService.get<TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.MAILING_PAYMENT_STATUS}`,
    );
  },
  /**
   * Метод запрашивает список активных рассылок
   * @method getMailingData
   * @return {Promise<AxiosResponse<TResponseWithOkAndData<TSendOutResponseData> | TRequestStatus, any>>}
   */
  getMailingData() {
    return apiService.get<TResponseWithOkAndData<TSendOutResponseData> | TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.MAILING}`,
    );
  },
  /**
   * Метод отправляет тестовое сообщение в whatsApp
   * @method postTestMessage
   * @param data {TSendTestMessageRequestData} formData с сообщением
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  postTestMessage(data: TSendTestMessageRequestData) {
    return apiService.post<TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.TEST_MAILING}`,
      data.formData,
    );
  },
  /**
   * Метод останавливает запущенную рассылку
   * @method postStopSendOut
   * @param data {TPostStopSendOutData} id рассылки
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  stopSendOut(data: TPostStopSendOutData) {
    return apiService.delete<TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.STOP_MAILING}`,
      {},
      {
        params: {
          [QUERY_PARAMS.SENDOUT_ID]: data.sendOutId,
        },
      },
    );
  },
  /**
   * Метод отправляет XLSX файл для обработки, в ответ получает данные согласия по списку клиентов
   * @method consentXSLXFile
   * @param data {TParseXLSXFileRequestData}
   * @return {Promise<AxiosResponse<TRequestStatus | TResponseWithOkAndData<TMailingProcessData>, any>>}
   */
  consentXSLXFile(data: TConsentParseXLSXFileRequestData) {
    return apiService.post<TConsentParseXLSXFile | TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.CONSENT_XLSX_FILE}`,
      data.formData,
    );
  },
  /**
   * Метод отправляет XLSX файл для обработки, в ответ получает количество номеров для отправки, количество дней на отправку,
   * скольуо сообщений можно отправить в день
   * @method parseXSLXFile
   * @param data {TParseXLSXFileRequestData}
   * @return {Promise<AxiosResponse<TRequestStatus | TResponseWithOkAndData<TMailingProcessData>, any>>}
   */
  parseXSLXFile(data: TParseXLSXFileRequestData) {
    return apiService.post<TParseXlsxFileResponse<TMailingProcessData> | TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.PARSE_XLSX_FILE}`,
      data.formData,
      {
        params: {
          ...data.consentCategories,
        },
      },
    );
  },
  /**
   * Метод запускает рассылку
   * @method startMailing
   * @param data {TStartMailingPostData} formData с текстом сообщения, файлом со списком получателей и медиа файлом(если его загрузили)
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  startMailing(data: TStartMailingPostData) {
    return apiService.post<TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.START_MAILING}`,
      data.formData,
      {
        params: {
          [QUERY_PARAMS.SEND_OUT_TIME_START]: data.startTime,
        },
      },
    );
  },
  /**
   * Метод получает детальную рассылку по статистике
   * @param data {sendOutId: number} ID рассылки
   * @return {Promise<AxiosResponse<TSendOutDetailStatisticsResponse | TRequestStatus, any>>}
   */
  sendOutDetailStatistics(data: TGetSendOutDetailStatisticsData) {
    return apiService.get<TSendOutDetailStatisticsResponse | TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.STATISTICS}`,
      {
        [QUERY_PARAMS.SENDOUT_ID]: data.sendOutId,
        [QUERY_PARAMS.GET_EXEL_FILE]: data.getExcelFile,
      },
    );
  },
  /**
   * Метод сохраняет файл детальной статистики в формате xls
   * @param data {TGetSendOutDetailStatisticsData} данные для получения ссылки на файл
   * @return {Promise<AxiosResponse<string | TRequestStatus, any>> }
   */
  sendOutDetailStatisticsDownload(data: TGetSendOutDetailStatisticsData) {
    return apiService.get<string | TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.STATISTICS_DOWNLOAD}`,
      {
        [QUERY_PARAMS.SENDOUT_ID]: data.sendOutId,
        [QUERY_PARAMS.GET_EXEL_FILE]: data.getExcelFile,
      },
    );
  },
  /**
   * Метод получает данные о запущенных рассылках для отображения их в календаре
   * @param data {TGetCalendarData}
   * @return {Promise<AxiosResponse<TGetCalendarResponseData | TRequestStatus, any>> }
   */
  getCalendarData(data: TGetCalendarData) {
    return apiService.get<TGetCalendarResponseData | TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.SENDOUT_CALENDAR}`,
      {
        [QUERY_PARAMS.SEND_OUT_QUOTE_FOR_DAY]: data.quote_for_day,
      },
    );
  },
  /**
   * Метод получает данные о запущенных рассылках на указанную дату
   * @param date {Date}
   * @return {Promise<AxiosResponse<TGetSendOutDayResponseData[] | TRequestStatus, any>> }
   */
  getSendOutDayData(date: Date) {
    return apiService.get<TGetSendOutDayResponseData[] | TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.SENDOUT_DAY_DATA}`,
      {
        [QUERY_PARAMS.DATE]: date.toJSON().slice(0, -5),
      },
    );
  },
  /**
   * Метод проверяет возможность запустить рассылку на указанную дату
   * @param data данные для запуска рассылки
   * @return {Promise<AxiosResponse<boolean | TRequestStatus, any>> }
   */
  getAllowToRun(data: Record<string, string | number>) {
    return apiService.get<boolean | TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.SENDOUT_ALLOW_TO_RUN}`,
      {
        ...data,
      },
    );
  },
};
