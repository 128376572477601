import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import isEqual from '@helpers/isEqual';
import { Loader } from '@blocks/loader';
import { WhenSend } from '@blocks/whenSend';
import { getAppData } from '@redux/app/selectors';
import { WHEN_SEND_VALUE } from '@const/templates';
import { EmojiClickData } from 'emoji-picker-react';
import { FORM_DATA_FIELD_NAME } from '@const/mailing';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { getSelectionOffset } from '@helpers/selection';
import { emojiPickerToggle } from '@redux/emoji/emojiSlice';
import { getAccountsData } from '@redux/accounts/selectors';
import { TemplateHeader } from '@components/templateHeader';
import { ToastsMessages } from '@components/toastsMessages';
import { TemplateContent } from '@components/templateContent';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getTemplatePageData } from '@redux/template/selectors';
import { emptyTemplateMockData } from '@redux/template/mockData';
import { clearCurrentRefValue, parseDivValue } from '@helpers/index';
import { TemplateAdditionalInfo } from '@blocks/templateAdditionalInfo';
import { TemplateSendCondition } from '@components/templateSendCondition';
import { saveTemplateData, copyTemplateToBranchThunk } from '@redux/template/templateThunks';
import { TEMPLATE_DATA_KEY_NAMES, TTemplatePageData, TTextareaNames } from '@redux/template/models';
import {
  TClickHandler,
  TOnChangeHandler,
  TSetWhenSend2Days,
  TOnEmojiViewChange,
  TDivOnClickHandler,
  TDivOnInputHandler,
  TDivOnKeyUpHandler,
  TSetViewEmojiPicker,
  TSetEmojiInTextarea,
} from '@shared/types';
import {
  wrapText,
  clearFiles,
  setWhenSend,
  addMediaFile,
  setWhenSend2Min,
  onChangeTemplate,
  addKeyInTextarea,
  setTextareaValue,
  setTemplatesName,
  setWhenSend2Days,
  setWhenSendOption,
  clearTemplateState,
  roundedWhenSend2Min,
  setTemplatePageData,
  clearAllSuccessState,
  setAbandonedTextareaData,
} from '@redux/template/templateSlice';

interface ITemplateComponentProps {
  /**
   * Флаг загрузки данных старницы
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Данные шаблона
   * @param {TTemplatePageData}
   */
  data: TTemplatePageData;
  /**
   * Флаг нового шаблона
   * @param {boolean}
   */
  isNewTemplate?: boolean;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const TemplateComponent = ({
  isLoading,
  selectedFilialAccId,
  isNewTemplate = false,
  isEditTemplatesAccess = false,
  data,
  className = '',
}: ITemplateComponentProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { appLanguage } = useAppSelector(getAppData);

  const {
    id,
    files,
    whenTime,
    whenSend,
    whenSend2,
    textWhatsapp,
    whenSend2Min,
    mediaFileName,
    whenSend2Days,
  } = data;

  const {
    oldData,
    isFetching,
    errorMessage,
    specialKeyDict,
    successMessage,
    warningMessage,
    isFileTypeError,
    isSavingTemplate,
    isTemplateSaveError,
    blurFromTextareaName,
    isCopyTemplatesError,
    mediaFileTooBigError,
    whenSendSelectOption,
    isShowWarningMessage,
    isTemplateSaveSuccess,
    whenSend2SelectOption,
    isCopyTemplatesLoading,
    isCopyTemplatesSuccess,
    templateTestFieldsParsError,
  } = useAppSelector(getTemplatePageData);

  const { data: accounts } = useAppSelector(getAccountsData);

  // флаг успешного получения данных
  const isFetchingSuccess = !isFetching && (!!id || !!isNewTemplate);

  // Сохраняется выбранная пользователем div для возвращения фокуса после добавления спец ключей или обрамления текста
  const div = useRef<(EventTarget & HTMLDivElement) | null>(null);

  // ref на input тип file
  const mediaFileRef = useRef<HTMLInputElement>(null);

  // Очищает данные о загруженном файле, для предотвращения ошибочного отправления
  useEffect(() => {
    if (isNewTemplate) {
      dispatch(clearFiles());
      dispatch(setWhenSendOption());
      if (mediaFileRef.current) {
        mediaFileRef.current.value = '';
      }
    }
    return () => {
      dispatch(setTemplatePageData({ data: emptyTemplateMockData }));
    };
  }, [isNewTemplate, dispatch]);

  // Обработчик загрузки файлов, при подключении бэка, добавить сохранение файла в FormData или еще как)))
  const onChangeHandlerFileInput: TOnChangeHandler = useCallback(
    event => {
      const { files: file } = event.currentTarget;
      if (file) {
        dispatch(addMediaFile({ files: file }));
      }
    },
    [dispatch],
  );

  // Callback передается в компонент <TemplateAdditionalInfo />
  // добавляет спец ключ в текс 'выбранной' textarea
  const setKeyInTextarea = useCallback(
    (key: string) => () => {
      if (blurFromTextareaName && div.current) {
        dispatch(addKeyInTextarea({ innerText: key, callback: parseDivValue(div.current) }));
      }
    },
    [blurFromTextareaName, dispatch],
  );

  // Оборачивает выделенный текст в выбранные спецсимволы
  const wrapSelectedTextInTextarea: TClickHandler = useCallback(
    event => {
      if (blurFromTextareaName && div.current) {
        const { innerText } = event.currentTarget; // получаем выбранный спецсимвол вместе с примером текстом в функцию для формирования новой строки передается первый символ
        dispatch(wrapText({ innerText, callback: parseDivValue(div.current) }));
      }
    },
    [blurFromTextareaName, dispatch],
  );

  // функция валидации формы
  const validateForm = useCallback(
    () => !!textWhatsapp ?? textWhatsapp.length <= 2000,
    [textWhatsapp],
  );

  // Очищает state от загруженного/выбранного файла
  const clearMediaFileInput = useCallback(() => {
    dispatch(clearFiles());
    clearCurrentRefValue(mediaFileRef);
  }, [dispatch]);

  // При возникновении ошибки по типу файла, эффект очищает инпут, что бы файл не сохранился
  useEffect(() => {
    if (isFileTypeError || mediaFileTooBigError) {
      clearCurrentRefValue(mediaFileRef);
    }
  }, [isFileTypeError, mediaFileTooBigError, clearMediaFileInput]);

  // Функция проводит валидацию формы перед отправкой,
  // если форма не валидна ищет элементы с классом error
  // если элементы найдены, прокручивает экран на найденный элемент
  const saveTemplateHandler = useCallback(() => {
    const file = mediaFileName && mediaFileRef.current && mediaFileRef.current.files;

    if (!validateForm()) {
      const textareaWithError = document.querySelectorAll(
        '.innerError',
      ) as unknown as HTMLTextAreaElement[];
      if (textareaWithError.length) {
        textareaWithError[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        textareaWithError[0].focus();
      }
      return;
    }

    dispatch(saveTemplateData({ data, file: file ? file[0] : null }));
  }, [data, dispatch, validateForm, mediaFileName]);

  // Создает копии шаблона в указанных филиалах
  const copyTemplateToBrunch = useCallback(
    (templateId: string) => (filials: string[]) => {
      dispatch(copyTemplateToBranchThunk({ templateId, branchList: filials }));
    },
    [dispatch],
  );

  // Callback открывает диалоговое окно добавления файла
  const addFile = useCallback(
    (ref: React.RefObject<HTMLInputElement>) => () => {
      ref.current?.click();
    },
    [],
  );

  // Эффект в случае наличия сохраненного медиа-файла, создает новый файл и добавляет его в объект DataTransfer,
  // затем список файлов из этого объекта сохраняются в инпут по рефу
  useEffect(() => {
    if (files) {
      const newFile = new File([files], FORM_DATA_FIELD_NAME.MEDIA_FILE, { type: files.type });
      const dt = new DataTransfer();
      dt.items.add(newFile);
      if (mediaFileRef.current) {
        mediaFileRef.current.files = dt.files;
      }
    }
  }, [files]);

  // Устанавливает имя шаблона
  const setTemplateNameHandler: TOnChangeHandler = useCallback(
    event => {
      const { value } = event.currentTarget;
      dispatch(setTemplatesName({ title: value }));
    },
    [dispatch],
  );

  // Обработчик изменений параметра отправления шаблона (whenSend)
  const setWhenSendHandler = useCallback(
    (value: string) => () => {
      dispatch(setWhenSend({ whenSend: value }));
    },
    [dispatch],
  );

  // Обработчик изменений доп. параметра отправления шаблона (whenSend2)
  const setWhenSend2Handler = useCallback(
    (value: string) => () => {
      dispatch(onChangeTemplate({ name: TEMPLATE_DATA_KEY_NAMES.WHEN_SEND2, value }));
    },
    [dispatch],
  );

  // Обработчик изменений времени отправки шаблона
  const setWhenTimeHandler = useCallback(
    (value: string) => {
      dispatch(onChangeTemplate({ name: TEMPLATE_DATA_KEY_NAMES.WHEN_TIME, value }));
    },
    [dispatch],
  );

  // Тоггл для открытия/закрытия блока с эмодзи
  const setViewEmojiPicker: TSetViewEmojiPicker = useCallback(
    ({ name, isOpenEmoji, divRef }) => {
      if (div.current) {
        div.current = divRef || null;
        dispatch(emojiPickerToggle({ isOpen: isOpenEmoji, name }));
      }
    },
    [dispatch],
  );

  // добавляет эмодзи в див
  const setEmojiInTextarea: TSetEmojiInTextarea = useCallback(
    (emoji: EmojiClickData) => {
      if (blurFromTextareaName && div.current) {
        dispatch(
          addKeyInTextarea({ innerText: emoji.emoji, callback: parseDivValue(div.current) }),
        );
        div.current.focus();
      }
    },
    [blurFromTextareaName, dispatch],
  );

  // Callback для установки имени textarea в которой открыт EmojiPicker
  const onEmojiViewChange: TOnEmojiViewChange = useCallback(
    ({ textAreaName }) =>
      () => {
        if (div.current) {
          const [start, end] = getSelectionOffset(div.current);

          dispatch(
            setAbandonedTextareaData({
              name: textAreaName as TTextareaNames,
              selectionStart: start,
              selectionEnd: end,
            }),
          );
        }
      },
    [dispatch],
  );

  // устанавливает дни в блоке настройки отправки шаблона
  const setWhenSend2DaysHandler: TSetWhenSend2Days = useCallback(
    ({ maxValue, minValue }) =>
      event => {
        const { value } = event.currentTarget;

        dispatch(setWhenSend2Days({ value, maxValue, minValue }));
      },
    [dispatch],
  );

  // устанавливает минуты в блоке настройки отправки шаблона
  const setWhenSend2MinOnChangeHandler: TOnChangeHandler = useCallback(
    event => {
      const { value } = event.currentTarget;

      dispatch(setWhenSend2Min(value));
    },
    [dispatch],
  );

  // округляет минуты в блоке настройки отправки шаблона
  const roundedMinutes = useCallback(() => {
    dispatch(roundedWhenSend2Min());
  }, [dispatch]);

  // устанавливает минуты в блоке настройки отправки шаблона
  const setWhenSend2MinHandler = useCallback(
    (minutes: string) => () => {
      dispatch(setWhenSend2Min(minutes));
    },
    [dispatch],
  );

  // обрабатывает ввод текста в диве
  const divOnInputHandler: TDivOnInputHandler = useCallback(
    ({ name, value, selectionEnd, selectionStart, newTextareaKeysData }) => {
      dispatch(
        setTextareaValue({
          name: name as TTextareaNames,
          value,
          newTextareaKeysData,
          selectionEnd,
          selectionStart,
        }),
      );
    },
    [dispatch],
  );

  // По клику на див устанавливает данные дива в редакс и записывает див реф
  const divOnClickHandler: TDivOnClickHandler = useCallback(
    event => {
      const {
        dataset: { name },
      } = event.currentTarget;

      const [start, end] = getSelectionOffset(event.currentTarget);

      div.current = event.currentTarget;

      dispatch(
        setAbandonedTextareaData({
          name: name as TTextareaNames,
          selectionStart: start,
          selectionEnd: end,
        }),
      );
    },
    [dispatch],
  );

  // устанавливает положение курсора
  const setCursorPositionHandler: TDivOnKeyUpHandler = useCallback(
    event => {
      const {
        dataset: { name },
      } = event.currentTarget;

      const [start, end] = getSelectionOffset(event.currentTarget);

      div.current = event.currentTarget;

      dispatch(
        setAbandonedTextareaData({
          name: name as TTextareaNames,
          selectionStart: start,
          selectionEnd: end,
        }),
      );
    },
    [dispatch],
  );

  // Callback для очистки флагов ошибок
  const clearAllErrorStateHandler = useCallback(() => {
    dispatch(clearTemplateState());
  }, [dispatch]);

  // Callback для очистки флагов успешных действий
  const clearAllSuccessStateHandler = useCallback(() => {
    dispatch(clearAllSuccessState());
  }, [dispatch]);

  const isTemplateDataNotChanged = isEqual(oldData, data);

  const isSaveButtonDisabled =
    isTemplateDataNotChanged || isSavingTemplate || !isEditTemplatesAccess;

  const errorMessageToToast = templateTestFieldsParsError || errorMessage;

  const isShowErrorToast =
    (isTemplateSaveError && !!templateTestFieldsParsError) ||
    isCopyTemplatesError ||
    isFileTypeError ||
    mediaFileTooBigError;

  const isShowSuccessToast = isTemplateSaveSuccess || isCopyTemplatesSuccess;

  return (
    <div className={className}>
      {/* headerComponent */}
      <TemplateHeader
        accounts={accounts}
        accountsLength={accounts.length}
        copyHandler={copyTemplateToBrunch(id)}
        isTemplatesCopying={isCopyTemplatesLoading}
        isEditTemplatesAccess={isEditTemplatesAccess}
        isCopyTemplatesSuccess={isCopyTemplatesSuccess}
        data={data}
        className='mb-10'
      />
      {/* headerComponent */}
      {!isLoading ? (
        <div className='flex xl:flex-row-reverse'>
          <div className='hidden xl:flex flex-col pl-[3.7vw] w-[63%]'>
            <TemplateAdditionalInfo
              isWr={false}
              forMobile={false}
              specialKeyDict={specialKeyDict}
              setKeyInTextarea={setKeyInTextarea}
              wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
              className='hidden xl:flex flex-col'
            />
          </div>
          <div className='flex flex-col mr-auto max-w-[47rem] w-full'>
            <div className='mb-10'>
              <p className='text-gray2 tracking-[0.0275em] mb-10'>
                {t('TEMPLATE_PAGE_TEXT.templateRequired')}
                <span className='text-[red] ml-[0.2em]'>*</span>
              </p>
              <p className='font-medium text-black leading-8 text-h3Mobile tracking-[0.004em] mb-4'>
                {t('TEMPLATE_PAGE_TEXT.templateName')}
                <span className='text-statusRed text-base ml-1'>*</span>
              </p>
              <TextInput
                fill
                spellCheck
                view='outlined'
                color='default'
                value={data.title}
                disabled={!isEditTemplatesAccess}
                onChange={setTemplateNameHandler}
                placeholder={t('TEMPLATE_PAGE_TEXT.inputPlaceholder')}
              />
            </div>
            <div className='mb-10'>
              <WhenSend
                isRequired
                isFirstTooltip
                whenSend={whenSend}
                whenTime={whenTime}
                whenSend2={whenSend2}
                onBlurCallback={roundedMinutes}
                whenSend2Minutes={whenSend2Min}
                whenSend2Days={whenSend2Days}
                isNewTemplate={isNewTemplate}
                setWhenSend={setWhenSendHandler}
                setWhenTime={setWhenTimeHandler}
                disabled={!isEditTemplatesAccess}
                setWhenSend2={setWhenSend2Handler}
                setWhenSend2Days={setWhenSend2DaysHandler}
                setWhenSend2MinutesHandler={setWhenSend2MinHandler}
                whenSendSelectOption={whenSendSelectOption[appLanguage]}
                whenSend2SelectOption={whenSend2SelectOption[appLanguage]}
                setWhenSend2MinutesOnChangeHandler={setWhenSend2MinOnChangeHandler}
              />
            </div>
            {/* templateContent */}
            <TemplateContent
              ref={mediaFileRef}
              setKeyInTextarea={setKeyInTextarea}
              divOnInputHandler={divOnInputHandler}
              divOnClickHandler={divOnClickHandler}
              onEmojiViewChange={onEmojiViewChange}
              addFileCallback={addFile(mediaFileRef)}
              setViewEmojiPicker={setViewEmojiPicker}
              setEmojiInTextarea={setEmojiInTextarea}
              clearInputCallback={clearMediaFileInput}
              onChangeHandler={onChangeHandlerFileInput}
              setCursorPosition={setCursorPositionHandler}
              isEditTemplatesAccess={isEditTemplatesAccess}
              wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
              data={data}
            />
            {/* templateContent */}
            {/* sendConditions */}
            <TemplateSendCondition
              divOnInputHandler={divOnInputHandler}
              divOnClickHandler={divOnClickHandler}
              onEmojiViewChange={onEmojiViewChange}
              isFetchingSuccess={isFetchingSuccess}
              setEmojiInTextarea={setEmojiInTextarea}
              setViewEmojiPicker={setViewEmojiPicker}
              selectedFilialAccId={selectedFilialAccId}
              setCursorPosition={setCursorPositionHandler}
              isEditTemplatesAccess={isEditTemplatesAccess}
              data={data}
            />
            {/* sendConditions */}
            <Button
              dense
              type='action'
              view='filled'
              color='success'
              loading={isSavingTemplate}
              onClick={saveTemplateHandler}
              disabled={isSaveButtonDisabled}
              text={t('TEMPLATE_PAGE_TEXT.saveButton')}
              className={`${whenSend === WHEN_SEND_VALUE.BIRTHDAY ? '' : 'mt-10'} w-fit`}
            />
            <ToastsMessages
              successMessage={successMessage}
              warningMessage={warningMessage}
              errorMessage={errorMessageToToast}
              isShowErrorToast={isShowErrorToast}
              isShowSuccessToast={isShowSuccessToast}
              isShowWarningToast={isShowWarningMessage}
              callClearSuccessStatusCallbackTimeOut={2000}
              clearErrorCallback={clearAllErrorStateHandler}
              clearSuccessStatusCallback={clearAllSuccessStateHandler}
            />
          </div>
        </div>
      ) : (
        <Loader className='h-[50vh]' />
      )}
    </div>
  );
};
