import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { H1 } from '@atoms/h1';
import { Button } from '@uikit/Button';
import { useAppDispatch } from '@store/store';
import { VIDEO_INSTRUCTIONS } from '@const/common';
import { EmojiClickData } from 'emoji-picker-react';
import { emojiPickerToggle } from '@redux/emoji/emojiSlice';
import { ToastsMessages } from '@components/toastsMessages';
import { TAutoResponseItem } from '@redux/autoResponse/models';
import { AutoResponseItem } from '@components/autoResponseItem';
import { NEW_AUTO_RESPONSE_ID, LINK_TO_INSTRUCTION } from '@const/autoResponse';
import { VideoInstructionWithDropdown } from '@components/videoInstructionWithDropdown';
import {
  createAutoResponseThunk,
  deleteAutoResponseItemThunk,
  updateAutoResponseItemThunk,
  autoResponseItemActivitySwitchThunk,
} from '@redux/autoResponse/autoResponseThunk';
import {
  TSetViewEmojiPickerAR,
  TTextareaOnChangeHandler,
  TSetCursorPositionHandler,
  TClientRequestFieldOnChangeHandlerP,
  TToggleAutoResponseItemActivityHandler,
} from '@shared/types';
import {
  setCursorPosition,
  addEmojiInTextarea,
  textareaOnChangeAR,
  clearAllStatusesAR,
  addNewAutoResponseElement,
  deleteAutoResponseElement,
  onChangeClientRequestField,
} from '@redux/autoResponse/autoResponseSlice';

type TAutoResponseTemplateProps = {
  /**
   * Флаг сохранения изменений на странице
   * @param {boolean}
   */
  isSaving: boolean;
  /**
   * Флаг загрузки данных
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Флаг удаления блока автоответов
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Сообщение об ошибке
   * @param {string}
   */
  errorMessage: string;
  /**
   * Сообщение об успешном действии
   * @param {string}
   */
  successMessage: string;
  /**
   * Id блока с которым производятся действия сохранения или удаления
   * @param {string}
   */
  activeItemId: string;
  /**
   * Данные страницы
   * @param {TAutoResponseItem[]}
   */
  data: TAutoResponseItem[];
  /**
   * Флаг отключения кнопки сохранения
   * @param {boolean}
   */
  isSaveButtonDisable: boolean;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
};

export const AutoResponseTemplate = memo(
  ({
    isSaving,
    isLoading,
    isDeleting,
    activeItemId,
    errorMessage,
    successMessage,
    isSaveButtonDisable,
    data,
    className = '',
  }: TAutoResponseTemplateProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // Обработчик изменений в поле ответа
    const textareaOnChangeHandler = useCallback<TTextareaOnChangeHandler>(
      index => event => {
        const { name, value } = event.currentTarget;

        dispatch(textareaOnChangeAR({ value, name, blockIndex: index }));
      },
      [dispatch],
    );

    // Показывает/скрывает блок с эмодзи
    const setViewEmojiPickerAR: TSetViewEmojiPickerAR = useCallback(
      blockId =>
        ({ name, isOpenEmoji }) => {
          dispatch(emojiPickerToggle({ isOpen: isOpenEmoji, name, stepIdEmoji: blockId }));
        },
      [dispatch],
    );

    // Вставляет выбранный змодзи в textarea
    const setEmojiInTextarea = useCallback(
      (blockId: string) => (emoji: EmojiClickData) => {
        dispatch(addEmojiInTextarea({ emoji: emoji.emoji, blockId }));
      },
      [dispatch],
    );

    // Записывает в стейт позицию курсора
    const setCursorPositionHandler: TSetCursorPositionHandler = useCallback(
      ({ selectionStart, selectionEnd }) => {
        dispatch(setCursorPosition({ selectionStart, selectionEnd }));
      },
      [dispatch],
    );

    // Обработчик изменений в поле запроса клиента
    const clientRequestFieldOnChangeHandler = useCallback<TClientRequestFieldOnChangeHandlerP>(
      blockIndex =>
        ({ fieldIndex, value }) => {
          dispatch(onChangeClientRequestField({ blockIndex, fieldIndex, value }));
        },
      [dispatch],
    );

    // Переключает активность блока
    const toggleAutoResponseItemActivityHandler =
      useCallback<TToggleAutoResponseItemActivityHandler>(
        clientRequest => event => {
          const { checked } = event.currentTarget;
          dispatch(autoResponseItemActivitySwitchThunk({ clientRequest, isActive: checked }));
        },
        [dispatch],
      );

    // Сохраняет изменения в блоке
    const postNewAutoResponseItemsHandler = useCallback(
      (id: string) => (callback: () => void) => {
        if (id === NEW_AUTO_RESPONSE_ID) {
          dispatch(createAutoResponseThunk({ data, activeItemId: id, callback }));
        } else {
          dispatch(updateAutoResponseItemThunk({ data, activeItemId: id, callback }));
        }
      },
      [dispatch, data],
    );

    // Удаляет блок по id
    const deleteAutoResponseItemHandler = useCallback(
      (id: string) => () => {
        if (id === NEW_AUTO_RESPONSE_ID) {
          dispatch(deleteAutoResponseElement({ id }));
        } else {
          dispatch(deleteAutoResponseItemThunk({ data, activeItemId: id }));
        }
      },
      [data, dispatch],
    );

    // Флаг проверяет возможность добавления нового блока, новый блок добавляется если заполнен предыдущий
    const isCanAddNewItem =
      (!!data[data.length - 1]?.responseToClient && !!data[data.length - 1]?.clientRequest[0]) ||
      !data.length;

    // Добавляет новый пустой блок
    const addEmptyElement = useCallback(() => {
      dispatch(addNewAutoResponseElement());
    }, [dispatch]);

    // Очищает статусы ошибок и успехов для предотвращения показа тостов при повторном открытии страницы
    const clearAllToastMessages = useCallback(() => {
      dispatch(clearAllStatusesAR());
    }, [dispatch]);

    // Массив блоков автоответа для рендеринга
    const autoResponseItems = data.map((item, index) => {
      const { responseToClient, clientRequest, isActive, id } = item;

      return (
        <AutoResponseItem
          key={id}
          id={id}
          isActive={isActive}
          clientRequest={clientRequest}
          responseToClient={responseToClient}
          setEmojiCallback={setEmojiInTextarea(id)}
          isSaveButtonDisable={isSaveButtonDisable}
          isSaving={id === activeItemId && isSaving}
          setCursorPosition={setCursorPositionHandler}
          isDeleting={id === activeItemId && isDeleting}
          setViewEmojiPickerCallback={setViewEmojiPickerAR(id)}
          textareaOnChangeHandler={textareaOnChangeHandler(index)}
          deleteAutoResponseItemsHandler={deleteAutoResponseItemHandler(id)}
          postNewAutoResponseItemsHandler={postNewAutoResponseItemsHandler(id)}
          clientRequestFieldOnChangeHandler={clientRequestFieldOnChangeHandler(index)}
          toggleAutoResponseItemActivityHandler={toggleAutoResponseItemActivityHandler(
            clientRequest,
          )}
          className={index === data.length - 1 ? '' : 'mb-6'}
        />
      );
    });

    return (
      <div id='autoResponse' className={`flex flex-col scroll-smooth ${className}`}>
        <div className='flex flex-wrap items-center justify-between mb-6'>
          <H1 className='mb-4'>{t('AUTO_RESPONSE_PAGE_TEXT.header')}</H1>
          <VideoInstructionWithDropdown
            isKinescopeVideo
            isVkVideo={false}
            instructionData={VIDEO_INSTRUCTIONS.autoResponse}
            className='mb-4 !z-[61]'
          />
        </div>
        <div className='flex justify-end mb-6'>
          <Button
            dense
            icon='plus'
            type='action'
            view='filled'
            color='success'
            onClick={addEmptyElement}
            disabled={isLoading || !isCanAddNewItem}
            text={t('AUTO_RESPONSE_PAGE_TEXT.createAutoResponseButton')}
            className='relative z-[60]'
          />
        </div>
        <p className='text-black leading-6 tracking-[0.0275em] mb-6 whitespace-pre-line max-w-[57.5rem] w-full'>
          {t('AUTO_RESPONSE_PAGE_TEXT.pageDescription')}
          <a
            target='_blank'
            rel='noreferrer noopener'
            href={LINK_TO_INSTRUCTION.link}
            className='relative cursor-pointer text-link text-body2 ml-2 underline z-[60]'>
            {t('AUTO_RESPONSE_PAGE_TEXT.LINK_TO_INSTRUCTION.text')}
          </a>
        </p>
        {!isLoading ? (
          <div>
            {autoResponseItems.length
              ? autoResponseItems
              : t('AUTO_RESPONSE_PAGE_TEXT.noAutoResponseItems')}
          </div>
        ) : null}
        <ToastsMessages
          errorMessage={errorMessage}
          successMessage={successMessage}
          isShowErrorToast={!!errorMessage}
          isShowSuccessToast={!!successMessage}
          clearErrorCallback={clearAllToastMessages}
          clearSuccessStatusCallback={clearAllToastMessages}
          className='z-[999]'
        />
      </div>
    );
  },
);

AutoResponseTemplate.displayName = 'AutoResponseTemplate';
