import React, { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import vkWallpaper from '@img/inviteFriend/vkWallpaper.jpg';
import { useCloseByOutSideClick } from '@hooks/useCloseByOutSideClick';
import { ReactComponent as PlayButtonIcon } from '@img/inviteFriend/play_circle.svg';

type TProps = {
  /**
   * Флаг автоматического запуска видео
   * @param {boolean}
   */
  isAutoplay: boolean;
  /**
   * Ссылка на заставку для видео
   * @param {string}
   */
  wallpaper?: string;
  /**
   * Ссылка на видео в VK
   * @param {string}
   */
  videoLink: string;
  /**
   * Время на которое нужно перемотать ролик
   * @param {string}
   */
  startTime: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const KinescopeVideoInstruction = memo(
  ({ videoLink, startTime, wallpaper, isAutoplay, className = '' }: TProps) => {
    const { t } = useTranslation();
    const [isShowWallpaper, setIsShowWallpaper] = useState(!!wallpaper);

    // Реф для iframe
    const ref = useRef<HTMLIFrameElement>(null);

    // Показывает заставку(картинку) и ставит на паузу воспроизведение
    const showWallpaper = useCallback(() => {
      if (wallpaper) {
        setIsShowWallpaper(true);
      }
    }, [wallpaper]);

    // Хук для закрытия плеера в случае клика вне зоны компонента
    useCloseByOutSideClick({ ref, closeElementCallback: wallpaper ? showWallpaper : () => '' });

    // Скрывает заставку запускает создание плеера и устанавливает время начала воспроизведения
    const hideWallpaper = useCallback(() => {
      setIsShowWallpaper(false);
    }, []);

    return (
      <div id='wrapper' className={className}>
        <div
          onClick={hideWallpaper}
          className='relative cursor-pointer flex items-center justify-center w-full'>
          <PlayButtonIcon
            className={`absolute ${isShowWallpaper ? '' : 'hidden'} h-[5rem] w-[5rem]`}
          />
          <img
            alt='vkWallpaper'
            src={wallpaper || vkWallpaper}
            className={`rounded-lg ${isShowWallpaper ? '' : 'hidden'} h-auto w-full`}
          />
        </div>
        {!isShowWallpaper ? (
          <iframe
            ref={ref}
            frameBorder='0'
            allowFullScreen
            title='kinecope'
            src={`${videoLink}?t=${startTime}${isAutoplay ? '&autoplay=1' : ''}`}
            allow='autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
            className={`aspect-video rounded-lg ${isShowWallpaper ? 'hidden' : ''} w-full`}
          />
        ) : null}
        {wallpaper ? (
          <p className='text-black font-medium text-center leading-6 tracking-[0.022em] mt-3 w-full'>
            {t('INVITE_FRIEND_PAGE_TEXT.videoInstructionHeader')}
          </p>
        ) : null}
      </div>
    );
  },
);

KinescopeVideoInstruction.displayName = 'KinescopeVideoInstruction';
