import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { DAYS } from '@const/common';
import { Callout } from '@uikit/Callout';
import { numWord } from '@helpers/index';
import { TAppLanguage } from '@models/index';
import { TConsentParseXLSXFileData, TParsedXlsxFileData } from '@redux/mailing/models';
import { TSetSelectedRecipientHandler } from '@shared/types';
import { MailingSelectRecipients } from '@components/mailingSelectRecipients';
import {
  REST,
  NEXT,
  SENT,
  IN_ON,
  VISITS,
  WILL_BE,
  MESSAGES,
  RECIPIENT,
  ACTIVE_STEP_NUMBER,
} from '@const/mailing';

type ParsedClientFileInfoProps = {
  /**
   * Имя загруженного файла с номерами для рассылки
   * @param {string}
   */
  clientFileName: string;
  /**
   * Очищает инпут с загруженным файлом
   * @param {() => void}
   */
  clearClientFileHandler: () => void;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Данные о загруженном файле со списком клиентов
   * @param {TParsedXlsxFileData}
   */
  parsedXSLXFileData: TParsedXlsxFileData;
  /**
   * Номер шага рассылки
   * @param {number}
   */
  stepNumber: number;
  /**
   * Обрабатывает клики на на блоках выбора получателей рассылки
   * @param {TSetSelectedRecipientHandler}
   */
  setSelectedRecipientHandler: TSetSelectedRecipientHandler;
  /**
   * Отправить клиентам согласившимся на рассылку
   * @param {boolean}
   */
  sendToConsentingRecipients: boolean;
  /**
   * Отправить клиентам от которых не получено согласие на рассылку
   * @param {boolean}
   */
  sendToNeutralRecipient: boolean;
  /**
   * Данные согласия по списку клиентов
   * @param {TConsentParseXLSXFileData | null}
   */
  consentParseFileData: TConsentParseXLSXFileData | null;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ParsedClientFileInfo = memo(
  ({
    stepNumber,
    appLanguage,
    clientFileName,
    parsedXSLXFileData,
    consentParseFileData,
    clearClientFileHandler,
    sendToNeutralRecipient,
    sendToConsentingRecipients,
    setSelectedRecipientHandler,
    className = '',
  }: ParsedClientFileInfoProps) => {
    const { t } = useTranslation();

    // Деструктуризация parsedXSLXFileData
    const { count, sendOutBatchLen, sendDays, visitsMedian, lastVisitTimeMedian } =
      parsedXSLXFileData;

    // Получаем количество сообщений оставшееся после первого дня рассылки
    const remainingMessages = count - sendOutBatchLen;

    // Подготавливаем объект с сообщениями для формирования массива
    const messages = useMemo(
      () => ({
        message1: t('MAILING_PAGE_TEXT.parsedClientFileInfo.message1', {
          count,
          recipient: numWord(count, RECIPIENT[appLanguage]),
        }),
        message2: t('MAILING_PAGE_TEXT.parsedClientFileInfo.message2', {
          sendOutBatchLen,
          messages: numWord(sendOutBatchLen, MESSAGES[appLanguage]),
        }),
        message3: t('MAILING_PAGE_TEXT.parsedClientFileInfo.message3', {
          rest: numWord(remainingMessages, REST[appLanguage]),
          remainingMessages,
          messages: numWord(remainingMessages, MESSAGES[appLanguage]),
          willBe: numWord(remainingMessages, WILL_BE[appLanguage]),
          sent: numWord(remainingMessages, SENT[appLanguage]),
          sendDay: numWord(sendDays, IN_ON[appLanguage]),
          next: numWord(sendDays, NEXT[appLanguage]),
          sendDays: sendDays === 1 ? '' : sendDays,
          days: numWord(sendDays, DAYS[appLanguage]),
        }),
        message4: t('MAILING_PAGE_TEXT.parsedClientFileInfo.message4'),
        message5: t('MAILING_PAGE_TEXT.parsedClientFileInfo.message5', {
          visitsMedian,
          visits: numWord(visitsMedian, VISITS[appLanguage]),
        }),
        message6: t('MAILING_PAGE_TEXT.parsedClientFileInfo.message6', {
          lastVisitTimeMedian,
          days: numWord(lastVisitTimeMedian, DAYS[appLanguage]),
        }),
      }),
      [
        t,
        count,
        sendDays,
        appLanguage,
        visitsMedian,
        sendOutBatchLen,
        remainingMessages,
        lastVisitTimeMedian,
      ],
    );

    // формируем массив сообщений для сборки сообщения в callout
    const messagesToState: string[] = useMemo(() => {
      const message: string[] = [];

      message[0] = messages.message1;
      message[1] = messages.message4;
      message[2] = messages.message5;
      message[3] = messages.message6;
      message[4] = messages.message2;
      message[5] = count > 500 ? messages.message3 : '';
      if (count > 500) {
        message[4] = message[4].concat(',');
      } else {
        message[4] = message[4].concat('.');
      }

      return message;
    }, [count, messages]);

    // сообщение в callout
    const messageToRendering = useMemo(
      () =>
        messagesToState.map((message, index) => {
          switch (index) {
            case 0:
              return (
                <div className='m-0 mb-6' key={`${index + 11}messageKey`}>
                  {message}
                </div>
              );
            case 2:
              return (
                <div className='m-0' key={`${index + 11}messageKey`}>
                  {`- ${message}`}
                </div>
              );
            case 3:
              return (
                <div className='m-0 mb-6' key={`${index + 11}messageKey`}>
                  {`- ${message}`}
                </div>
              );
            default:
              return (
                <div className='m-0' key={`${index + 11}messageKey`}>
                  {message}
                </div>
              );
          }
        }),
      [messagesToState],
    );

    // Общее количество абонентов
    const totalRecipients = Object.values(consentParseFileData || {}).reduce<number>(
      (accum, currentValue) => {
        return accum + currentValue;
      },
      0,
    );

    return (
      <div className={className}>
        {stepNumber === ACTIVE_STEP_NUMBER.FIRST_STEP ? (
          <MailingSelectRecipients
            fileName={clientFileName}
            appLanguage={appLanguage}
            totalRecipients={totalRecipients}
            clearClientFileHandler={clearClientFileHandler}
            sendToNeutralRecipient={sendToNeutralRecipient}
            sendToConsentingRecipients={sendToConsentingRecipients}
            setSelectedRecipientHandler={setSelectedRecipientHandler}
            rejectRecipientsLength={consentParseFileData?.declinedCount || 0}
            consentingRecipientsLength={consentParseFileData?.consentCount || 0}
            noConsentingRecipientsLength={consentParseFileData?.neutralCount || 0}
          />
        ) : null}
        {stepNumber === ACTIVE_STEP_NUMBER.THIRD_STEP ? (
          <Callout icon='info-sign' view='smooth' color='primary'>
            <div className='flex flex-col pt-[0.375rem]'>{messageToRendering}</div>
          </Callout>
        ) : null}
      </div>
    );
  },
);

ParsedClientFileInfo.displayName = 'ParsedClientFileInfo';
