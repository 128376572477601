import { TConnectionStatus } from '@redux/connection/types';
import { TAppLanguage, TButtonText, TSimpleStringObj } from '@models/index';

export enum CONNECT_API_ACTIONS {
  GET_QR_CODE = 'GET_QR',
  LOGOUT_BOT = 'LOGOUT',
  CLEAN_MESSAGE_QUERY = 'CLEAR_MESSAGE_Q',
  REBOOT_BOT = 'REBOOT',
  GET_AUTH_CODE = 'GET_AUTH_CODE',
}

export const BUTTON_TEXT: TButtonText = {
  restart: 'Перезагрузить Бота',
  clean: 'Очистить очередь',
  stop: 'Остановить Бота',
  qr: 'Получить QR-код',
};

export const BUTTON_TEXT_ENG: TButtonText = {
  restart: 'Restart Bot',
  clean: 'Clear queue',
  stop: 'Stop Bot',
  qr: 'Get QR code',
};

export const QR_LIFE_TIME = 60000;

export const CONNECTION_TG_STATUS: Record<string, keyof TConnectionStatus> = {
  OK: 'authenticated',
  NO_CONNECT: 'gotQrCode',
  ERROR: 'error',
  BLOCKED: 'blocked',
  LOADING: 'loading',
  NOT_PAID: 'notPaid',
  NOT_SET_UP: 'notAuthenticated',
  NOT_CONNECTED: 'notConnected',
  REQUEST_ERROR: 'requestError',
};

export const CONNECTION_TG_STATUS_MAPPER: Record<string, keyof TConnectionStatus> = {
  authenticated: 'authenticated',
  loading: 'loading',
  blocked: 'blocked',
  not_authenticated: 'notAuthenticated',
  error: 'error',
  no_payed: 'notPaid',
};

export const FILIAL_CONNECT_STATUSES = ['authenticated', 'blocked', 'loading', 'gotQrCode'];

export const connectionStatusText: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    authenticated: 'Connected',
    blocked: 'Blocked',
    error: 'Error',
    gotQrCode: 'Not connected',
    loading: 'Connection',
    notPaid: 'Not paid',
    notAuthenticated: 'Not set up',
    notConnected: 'Not connected',
  },
  rus: {
    authenticated: 'Подключен',
    blocked: 'Заблокирован',
    error: 'Ошибка',
    gotQrCode: 'Не подключен',
    loading: 'Подключение',
    notPaid: 'Не оплачен',
    notAuthenticated: 'Не настроен',
    notConnected: 'Не подключен',
  },
};

export const connectionStatusFallback: Record<string, Record<string, string>> = {
  eng: {
    fallbackStatus: 'Status unknown',
  },
  rus: {
    fallbackStatus: 'Статус неизвестен',
  },
};

export const GET_UNIQUE_CODE_TIMEOUT_MIN = 2;
export const GET_UNIQUE_CODE_TIMEOUT_SEC = 50;

export const GET_UNIQUE_CODE_TIMEOUT_MS = 160000;

export enum TELEGRAM_BOT_CONNECTION_STEPS {
  FIRST,
  SECOND,
  THIRD,
  LOADING_NEW_STATUS,
  ERROR,
  INPUT_ERROR,
}

export const CONNECTION_PAGE_TEXT = {
  header: 'Подключение',
  connectionStatus: 'Статус подключения:',
  filial: 'Филиал: {{filialAdders}}',
  botConnection: {
    header: 'Подключить канал в Whatsapp',
    botConnectedHeader: 'Канал в Whatsapp подключен',
    botLoadingHeader: 'Канал в Whatsapp подключается',
    botBlockedHeader: 'Канал в Whatsapp заблокирован',
    botErrorHeader: 'Ошибка',
    subHeader: 'Подключите бота любым удобным для вас способом ниже',
    qrConnection: 'Подключить по QR-коду',
    phoneConnection: 'Подключить по номеру телефона',
    or: 'Или',
    phoneConnectionButton: 'Подключить по номеру',
    botWhatsApp: 'Канал в Whatsapp',
    waStatusCallout: 'Чтобы увидеть статус подключения перезагрузите страницу',
    reloadButton: 'Обновить страницу',
    waPhoneNumber: 'Ваш номер WhatsApp',
    sendCode: 'Отправить код',
    yourPhone: 'Ваш телефон:',
    changePhoneNumberButton: 'Изменить номер телефона',
    enterUniqueCode: 'Введите уникальный код в ваш WhatsApp',
    copyButton: 'Копировать',
    getANewUniqueCodeVia: 'Получить новый код можно через {{time}}',
    getANewUniqueCode: 'Получить новый код',
    ul: {
      first: 'Зайдите в WhatsApp на телефоне',
      second: 'Откройте меню (настройки) вотсапа',
      third: 'Далее - Связанные (привязанные) устройства',
      fourth: 'Нажмите «Привязка устройства», откроется камера для сканирования QR кода',
      sixth: 'Нажмите кнопку «Получить QR код» под этим сообщением',
      seventh: 'Отсканируйте полученный QR код камерой Whatsapp',
    },
    ulPhone: {
      first: 'Зайдите в WhatsApp на телефоне',
      second: 'Откройте меню WhatsApp, далее - Связанные (привязанные) устройства',
      third: 'Нажмите «Связывание устройства», откроется камера для сканирования QR кода',
      fourth: 'Внизу нажмите «Связать по номеру телефона»',
      sixth: 'Введите код с этой страницы',
    },
    secondUl: {
      header: 'На этой странице',
      ul: {
        first: 'запросите QR код, нажмите кнопку "Получить QR код" под этим сообщением',
        second: 'отсканируйте полученный QR код камерой Whatsapp',
      },
    },
  },
  telegramBotConnection: {
    botTelegram: 'Канал в Telegram',
    payBotHeader: 'Оплатить канал в Telegram',
    payBotDescription:
      'Ваши клиенты пользуются Telegram в качестве основного мессенджера?\n Мы добавили его как новый канал отправки сообщений.\n Стоимость подключения дополнительного канала зависит от числа сотрудников\n и начинается от 990 рублей в месяц.',
    payButton: 'Оплатить',
    notSetUpHeader: 'Подключить Бьюти Бот к Telegram',
    notSetUpDescription:
      'Введите номер телефона который использует Telegram, с него будут отправляться сообщения вашим клиентам.',
    notSetUpBlock: {
      connectByPhone: 'Подключить по номеру телефона',
      inputLabel: 'Ваш номер Telegram',
      inputPlaceholder: 'Введите номер из Telegram',
      inputError: 'Неверный формат: телефон должен\n содержать код страны и 10 цифр',
      sendCodeButton: 'Отправить код',
      changePhoneNumberButton: 'Изменить номер телефона',
      pinInputLabel: 'Введите код авторизации, который пришел в ваш Telegram.',
      pinErrorDescription: 'Пароль введен неверно. Пожалуйста, начните подключение заново.',
      restartConnectionButton: 'Начать заново',
      timeOut: 'Выслать код в СМС через {{time}}',
      codeError: 'Неверный код',
      passwordDescription:
        'Введите  пароль, который вы установили в  Telegram для двухфакторной авторизации.',
      passwordLabel: 'Пароль',
      passwordPlaceholder: 'Пароль Telegram',
      connectButton: 'Подключить',
      requestErrorLabel: 'Ошибка',
      requestErrorDescription: 'Ошибка\n перезагрузите страницу \n и попробуйте еще раз',
    },
    loadingHeader: 'Подключение Telegram канала',
    authenticatedHeader: 'Канал в Telegram подключен',
    authenticatedDescription:
      'Теперь вы можете отправлять автоматизированные сообщения клиентам в Telegram',
    authenticatedDisableButton: 'Отключить',
    authenticatedRestartButton: 'Перезагрузить Бота',
    notConnectedHeader: '',
    notConnectedDescription: '',
    notConnectedButton: 'Обновить подключение',
    loadingDescription:
      'Мы начали процесс настройки вашего Бота в Telegram.\n Это может занять до 30 минут.\n После успешного завершения настройки статус изменится на "Подключен"',
    blockedHeader: 'Канал в Telegram заблокирован',
    blockedDescription: 'Сожалеем, но ваш телеграм заблокирован',
    blockedButton: 'Обратиться в тех.поддержку',
    errorHeader: 'Ошибка',
    errorDescription: 'Произошла ошибка, обратитесь в тех. поддержку',
    errorButton: 'Обратиться в тех.поддержку',
  },
  clearMessageQueryModal: {
    title: 'Очистить очередь сообщений?',
    description:
      'Пока бот был отключен, в очереди накопилось несколько сообщений, которые не были отправлены вовремя. Они будут отправлены автоматически после подключения бота.',
    cancelButton: 'Оставить сообщения',
    deleteButton: 'Удалить сообщения',
  },
  BUTTON_TEXT,
  table: {
    headerWhatsApp: 'Очередь сообщений WhatsApp',
    headerTelegram: 'Очередь сообщений Telegram',
    number: '№',
    phone: 'Номер телефона',
    message: 'Сообщение',
    searchInput: {
      buttonText: 'Найти',
      placeholder: 'Поиск',
    },
    pagination: 'Показать строк:',
    showMore: 'Показать ещё',
    noMessages: 'Нет сообщений',
  },
};

export const CONNECTION_PAGE_TEXT_ENG = {
  header: 'Connecting',
  connectionStatus: 'Connection status:',
  filial: 'Branch: {{filialAdders}}',
  botConnection: {
    header: 'Connect channel in Whatsapp',
    botConnectedHeader: 'Channel in Whatsapp connected',
    botLoadingHeader: 'Channel in Whatsapp is connecting',
    botBlockedHeader: 'Channel in Whatsapp blocked',
    botErrorHeader: 'Error',
    subHeader: 'Connect the bot in any convenient way below',
    qrConnection: 'Connect by QR code',
    phoneConnection: 'Connect by phone number',
    or: 'Or',
    phoneConnectionButton: 'Connect by number',
    botWhatsApp: 'Channel in Whatsapp',
    waStatusCallout: 'To see the connection status, reload the page',
    reloadButton: 'Refresh the page',
    waPhoneNumber: 'Your WhatsApp number',
    sendCode: 'Send code',
    yourPhone: 'Your phone:',
    changePhoneNumberButton: 'Change phone number',
    enterUniqueCode: 'Enter a unique code in your WhatsApp',
    copyButton: 'Copy',
    getANewUniqueCodeVia: 'You can get a new code in {{time}}',
    getANewUniqueCode: 'Get a new code',
    ul: {
      first: 'Go to WhatsApp on your phone',
      second: 'Open the WhatsApp menu (settings)',
      third: 'Next - Linked (linked) devices',
      fourth: 'Click "Link device", the camera will open to scan the QR code',
      sixth: 'Click the "Get QR code" button below this message',
      seventh: 'Scan the received QR code with the Whatsapp camera',
    },
    ulPhone: {
      first: 'Go to WhatsApp on your phone',
      second: 'Open the WhatsApp menu, then - Linked (linked) devices',
      third: 'Click "Link device", the camera will open to scan the QR code',
      fourth: 'At the bottom, click "Link by phone number"',
      sixth: 'Enter the code from this page',
      seventh: 'Refresh this page to check the status',
    },
    secondUl: {
      header: 'On this page',
      ul: {
        first: 'request the QR code, click the "Get QR code" button below this message',
        second: 'scan the received QR code with the Whatsapp camera',
      },
    },
  },
  telegramBotConnection: {
    botTelegram: 'Channel in Telegram',
    payBotHeader: 'Pay for channel in Telegram',
    payBotDescription:
      'Do your clients use Telegram as their main messenger?\n We have added it as a new channel for sending messages.\n The cost of connecting an additional channel depends on the number of employees\n and starts from 990 rubles per month.',
    payButton: 'Pay',
    notSetUpHeader: 'Connect Beauty Bot to Telegram',
    notSetUpDescription:
      'Enter the phone number that Telegram uses, messages will be sent to your clients from it.',
    notSetUpBlock: {
      connectByPhone: 'Connect by phone number',
      inputLabel: 'Your Telegram number',
      inputPlaceholder: 'Enter the number from Telegram',
      inputError: 'Invalid format: phone must contain country code and 10 digits',
      sendCodeButton: 'Send code',
      changePhoneNumberButton: 'Change phone number',
      pinInputLabel: 'Enter the authorization code that came to your Telegram.',
      pinErrorDescription: 'The password is incorrect. Please restart the connection.',
      restartConnectionButton: 'Restart',
      timeOut: 'Send code via SMS in {{time}}',
      codeError: 'Incorrect code',
      passwordDescription: 'Enter the password you set in Telegram for two-factor authentication.',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Telegram password',
      connectButton: 'Connect',
      requestErrorLabel: 'Error',
      requestErrorDescription: 'Error\n reload the page\n and try again',
    },
    loadingHeader: 'Connecting Telegram channel',
    authenticatedHeader: 'Channel in Telegram connected',
    authenticatedDescription: 'Now you can send automated messages to clients in Telegram',
    authenticatedDisableButton: 'Disable',
    authenticatedRestartButton: 'Restart Bot',
    notConnectedHeader: '',
    notConnectedDescription: '',
    notConnectedButton: 'Refresh connection',
    loadingDescription:
      'We have started the process of setting up your Bot in Telegram.\n This may take up to 30 minutes.\n Once the setup is successfully completed, the status will change to "Connected"',
    blockedHeader: 'The channel in Telegram is blocked',
    blockedDescription: 'We are sorry, but your telegram is blocked',
    blockedButton: 'Contact technical support',
    errorHeader: 'Error',
    errorDescription: 'An error has occurred, please contact technical support',
    errorButton: 'Contact technical support',
  },
  clearMessageQueryModal: {
    title: 'Clear the message queue?',
    description:
      'While the bot was disconnected, there were some messages in the queue that were not sent on time. They will be sent automatically after the bot is connected.',
    cancelButton: 'Leave messages',
    deleteButton: 'Delete messages',
  },
  BUTTON_TEXT,
  table: {
    headerWhatsApp: 'WhatsApp message queue',
    headerTelegram: 'Telegram message queue',
    number: '№',
    phone: 'Phone number',
    message: 'Message',
    searchInput: {
      buttonText: 'Find',
      placeholder: 'Search',
    },
    pagination: 'Show lines:',
    showMore: 'Show more',
    noMessages: 'No messages',
  },
};
