import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { EMPLOYEES } from '@const/pay';
import { Loader } from '@blocks/loader';
import { TPayData } from '@redux/pay/models';
import { AutoPay } from '@components/autoPay';
import { PayCards } from '@components/payCards';
import { getAppData } from '@redux/app/selectors';
import { PayButtons } from '@components/payButtons';
import { getPayPageState } from '@redux/pay/selectors';
import { downloadByLink, numWord } from '@helpers/index';
import { FilialPayData } from '@components/filialPayData';
import { PayPageModals } from '@components/payPageModals';
import { getAccountsData } from '@redux/accounts/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { PayHistoryTable } from '@components/payHistoryTable';
import { useAppDispatch, useAppSelector } from '@store/store';
import { HeaderWithButton } from '@components/headerWithButton';
import { TCardSelectHandler, TOnChangeHandler } from '@shared/types';
import { createInvoiceThunk, sendInvoiceToTg } from '@redux/pay/payThunks';
import {
  setPromoCode,
  clearStatuses,
  hideGetBillModal,
  hideChangeCardModal,
  setSelectedCardNumber,
  hideAutoPayDisableModal,
  hideConnectAutoPayModal,
  showAutoPayDisableModal,
} from '@redux/pay/paySlice';

interface IPayProps {
  /**
   * Callback для повторной загрузки данных
   * @param {function}
   */
  refetch: () => void;
  /**
   * Сообщение об ошибке
   * @param {string}
   */
  errorMessage: string;
  /**
   * Callback для получения ссылки на автоплатеж
   * @param {function}
   */
  getAutoPayLinkHandler: () => void;
  /**
   * Ссылка на автоплатеж
   * @param {string}
   */
  autoPayLink: string;
  /**
   * Состояние загрузки ссылки на автоплатеж
   * @param {boolean}
   */
  isFetchingAutoPayLink: boolean;
  /**
   * Ошибка при получении ссылки на автоплатеж
   * @param {boolean}
   */
  isFetchingAutoPayLinkError: boolean;
  /**
   * Callback для изменения карты
   * @param {function}
   */
  changeCardHandler: () => void;
  /**
   * Ссылка для изменения карты
   * @param {string}
   */
  changeCardDataLink: string;
  /**
   * Состояние загрузки при изменении карты
   * @param {boolean}
   */
  changeCardIsLoading: boolean;
  /**
   * Ошибка при изменении карты
   * @param {boolean}
   */
  changeCardIsError: boolean;
  /**
   * Callback для отключения автоплатежа
   * @param {function}
   */
  turnOffAutoPaymentHandler: () => void;
  /**
   * Состояние загрузки при отключении автоплатежа
   * @param {boolean}
   */
  turnOffAutoPaymentIsLoading: boolean;
  /**
   * Успешно ли было отключение автоплатежа
   * @param {boolean}
   */
  turnOffAutoPaymentIsSuccess: boolean;
  /**
   * Ошибка при отключении автоплатежа
   * @param {boolean}
   */
  turnOffAutoPaymentIsError: boolean;
  /**
   * Данные для отображения на странице
   * @param {TPayData}
   */
  data: TPayData;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
}

export const PayTemplate = memo(
  ({
    refetch,
    autoPayLink,
    errorMessage,
    changeCardIsError,
    changeCardHandler,
    changeCardDataLink,
    changeCardIsLoading,
    getAutoPayLinkHandler,
    isFetchingAutoPayLink,
    turnOffAutoPaymentIsError,
    turnOffAutoPaymentHandler,
    isFetchingAutoPayLinkError,
    turnOffAutoPaymentIsLoading,
    turnOffAutoPaymentIsSuccess,
    data,
    className = '',
  }: IPayProps) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const {
      promoCode,
      isLoading,
      invoiceData,
      filteredData,
      successMessage,
      isChangeModalOpen,
      selectedCardNumber,
      isSendingInvoiceToTg,
      isFetchingInvoiceLink,
      isBillDownloadModalOpen,
      selectedCardTotalAmount,
      selectedCardMonthAmount,
      isAutoPayDisableModalOpen,
      isConnectAutoPayModalOpen,
      isSendingInvoiceToTgError,
      isFetchingInvoiceLinkError,
    } = useAppSelector(getPayPageState);

    const { appLanguage } = useAppSelector(getAppData);

    // Получаем имя сохраняется при выборе филиала на странице /accounts
    const { selectedFilial } = useAppSelector(getAccountsData);

    const filialName = selectedFilial?.address || '';

    // Деструктуризируем data
    const {
      amount,
      branch,
      payLinks,
      discount,
      payStatus,
      monthCost,
      createBill,
      payTillDate,
      reviewBanner,
      numberOfMonth,
      isAutoPayEnable,
      branchEmployees,
      numberOfGiftWeek,
      cardLastFourDigits,
    } = data;

    // Формируем строку описания филиала
    const rate = t('PAY_PAGE_TEXT.rate', {
      filialName,
      branch,
      branchEmployees,
      employersText: numWord(+branchEmployees, EMPLOYEES[appLanguage]),
      amount,
    });

    // Формируем текст скидки
    const discountText = discount ? t('PAY_PAGE_TEXT.discount', { discount }) : '';

    // Callback для закрытия всех модалок
    const closeAllModal = useCallback(() => {
      dispatch(hideChangeCardModal());
      dispatch(hideConnectAutoPayModal());
      dispatch(hideAutoPayDisableModal());
    }, [dispatch]);

    // Callback для управлением модалкой изменения карты
    const changeModalClose = useCallback(() => {
      dispatch(hideChangeCardModal());
    }, [dispatch]);

    // Callback для управлением модалкой подключения автоплатежа
    const connectAutoPayModalClose = useCallback(() => {
      dispatch(hideConnectAutoPayModal());
    }, [dispatch]);

    // Callback для управлением модалкой отключения автоплатежа
    const autoPayDisableModalOpen = useCallback(() => {
      dispatch(showAutoPayDisableModal());
    }, [dispatch]);

    // Callback для открытия модалки получения счетна на оплату
    const getBillModalOpen = useCallback(() => {
      dispatch(
        createInvoiceThunk({ amount: selectedCardTotalAmount, months: selectedCardMonthAmount }),
      );
    }, [selectedCardMonthAmount, selectedCardTotalAmount, dispatch]);

    // Callback для закрытия модалки получения счетна на оплату
    const getBillModalClose = useCallback(() => {
      dispatch(hideGetBillModal());
    }, [dispatch]);

    // Callback для отправки счета в telegram
    const sendBillInTGHandler = useCallback(() => {
      if (invoiceData) {
        dispatch(sendInvoiceToTg(invoiceData));
      }
    }, [invoiceData, dispatch]);

    // Callback для скачивания счета в формате pdf
    const downloadBillHandler = useCallback(
      (link: string) => () => {
        downloadByLink(link);
      },
      [],
    );

    // Callback для повторного запроса данных страницы
    const reloadPage = useCallback(() => {
      refetch();
    }, [refetch]);

    // Функция осуществляет переход по ссылке
    const changePageOnClick = useCallback(() => {
      const target = '_blank';

      const newWindow = window.open(
        payLinks[selectedCardNumber - 1],
        target,
        'noopener, noreferrer',
      );

      if (newWindow) {
        newWindow.opener = null;
      }
    }, [payLinks, selectedCardNumber]);

    // Функция обрабатывает выбор карточки для оплаты
    const cardSelectHandler: TCardSelectHandler = useCallback(
      cardNumber => (totalAmount, monthAmount) => {
        dispatch(setSelectedCardNumber({ cardNumber, totalAmount, monthAmount }));
      },
      [dispatch],
    );

    // Функция очищает все статусы запросов
    const clearStatusesHandler = useCallback(() => {
      dispatch(clearStatuses());
    }, [dispatch]);

    // Обрабатывает изменения поля ввода промокода
    const setPromoCodeHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;

        dispatch(setPromoCode(value));
      },
      [dispatch],
    );

    // Применяет введенный промокод
    const applyPromoCode = useCallback(() => {
      // console.log(promoCode);
    }, []);

    const isShowErrorToast =
      turnOffAutoPaymentIsError ||
      changeCardIsError ||
      isFetchingAutoPayLinkError ||
      isFetchingInvoiceLinkError ||
      isSendingInvoiceToTgError;

    return (
      <div className={`flex flex-col ${className}`}>
        <HeaderWithButton headerText={t('PAY_PAGE_TEXT.header')} className='px-4 sm:px-0 mb-10' />
        {isLoading || !data.branch ? (
          <Loader className='h-[50vh]' />
        ) : (
          <div className='flex'>
            <div className={`${reviewBanner ? `lg:pr-6` : ''} w-full`}>
              <div className='bg-gray1 sm:rounded-lg px-6 pt-6 max-w-[57.5rem]'>
                <FilialPayData
                  amount={amount}
                  branch={branch}
                  payStatus={payStatus}
                  filialName={filialName}
                  appLanguage={appLanguage}
                  payTillDate={payTillDate}
                  discountText={discountText}
                  isAutoPayEnable={isAutoPayEnable}
                  branchEmployees={branchEmployees}
                  className='mb-6'
                />
                <AutoPay
                  isAutoPayEnable={isAutoPayEnable}
                  changeCardHandler={changeCardHandler}
                  cardLastFourDigits={cardLastFourDigits}
                  selectedCardNumber={selectedCardNumber}
                  changeCardIsLoading={changeCardIsLoading}
                  isFetchingAutoPayLink={isFetchingAutoPayLink}
                  getAutoPayLinkHandler={getAutoPayLinkHandler}
                  autoPayDisableModalOpen={autoPayDisableModalOpen}
                />
                <div className='mb-6 overflow-x-auto'>
                  <PayCards
                    amount={amount}
                    payLinks={payLinks}
                    monthCost={monthCost}
                    appLanguage={appLanguage}
                    numberOfMonth={numberOfMonth}
                    isAutoPayEnable={isAutoPayEnable}
                    numberOfGiftWeek={numberOfGiftWeek}
                    cardSelectCallback={cardSelectHandler}
                    selectedCardNumber={selectedCardNumber}
                    className='w-screen lg:w-full'
                  />
                </div>
                <PayButtons
                  isHidePromoCodeBlock
                  promoCode={promoCode}
                  createBill={createBill}
                  applyPromoCode={applyPromoCode}
                  isLoading={isFetchingAutoPayLink}
                  isAutoPayEnable={isAutoPayEnable}
                  getBillModalOpen={getBillModalOpen}
                  onChangeHandler={setPromoCodeHandler}
                  changePageOnClick={changePageOnClick}
                  selectedCardNumber={selectedCardNumber}
                  getAutoPayLinkHandler={getAutoPayLinkHandler}
                  isFetchingInvoiceLink={isFetchingInvoiceLink}
                  selectedCardTotalAmount={selectedCardTotalAmount}
                />
                <PayPageModals
                  rate={rate}
                  amount={amount}
                  branch={branch}
                  filialName={filialName}
                  reloadPage={reloadPage}
                  appLanguage={appLanguage}
                  autoPayLink={autoPayLink}
                  closeAllModal={closeAllModal}
                  branchEmployees={branchEmployees}
                  changeModalClose={changeModalClose}
                  isChangeModalOpen={isChangeModalOpen}
                  getBillModalClose={getBillModalClose}
                  changeCardDataLink={changeCardDataLink}
                  sendBillInTGHandler={sendBillInTGHandler}
                  isSendingInvoiceToTg={isSendingInvoiceToTg}
                  isBillDownloadModalOpen={isBillDownloadModalOpen}
                  selectedCardTotalAmount={selectedCardTotalAmount}
                  connectAutoPayModalClose={connectAutoPayModalClose}
                  isAutoPayDisableModalOpen={isAutoPayDisableModalOpen}
                  isConnectAutoPayModalOpen={isConnectAutoPayModalOpen}
                  turnOffAutoPaymentHandler={turnOffAutoPaymentHandler}
                  turnOffAutoPaymentIsSuccess={turnOffAutoPaymentIsSuccess}
                  turnOffAutoPaymentIsLoading={turnOffAutoPaymentIsLoading}
                  downloadBillHandler={downloadBillHandler(invoiceData?.pdf_url || '')}
                  className=''
                />
              </div>
              <PayHistoryTable filteredData={filteredData} className='px-4 sm:px-0' />
              <ToastsMessages
                errorMessage={errorMessage}
                successMessage={successMessage}
                isShowErrorToast={isShowErrorToast}
                isShowSuccessToast={!!successMessage}
                clearErrorCallback={clearStatusesHandler}
                clearSuccessStatusCallback={clearStatusesHandler}
              />
            </div>
          </div>
        )}
      </div>
    );
  },
);

PayTemplate.displayName = 'PayTemplate';
