import { TTemplatePageData } from '@redux/template/models';
import { TCreateTemplateEventData, TEditTemplateEventData } from '@helpers/myTracker';

export const emptyTemplateMockData: TTemplatePageData = {
  id: '',
  statusEnable: false,
  textWhatsapp: '',
  title: '',
  oldTitle: 'Новый шаблон',
  textSms: '',
  files: null,
  mediaFile: null,
  mediaFileName: '',
  mediaFileType: null,
  mediaFileURL: null,
  error: false,
  whenSend: 'before_start',
  whenSend2: '0.25',
  whenSend2Days: '0',
  whenSend2Min: '15',
  whenTime: '00:00',
  services: [],
  categories: [],
  products: [],
  staff: [],
  recordTag: [],
  clientCategory: [],
  clientDidNotCome: false,
  clientExpectation: false,
  clientCame: false,
  clientConfirmed: false,
  visits: '',
  quantityFrom: '',
  quantityTo: '',
  blacklist: [],
  sendOnlyLostClient: false,
  onlineRecord: false,
  confirmRecord: false,
  textConfirmation: '',
  textCancel: '',
  thoseWhoWrote: '1',
};

export const templatePageMockData: TTemplatePageData = {
  id: '#23037',
  statusEnable: true,
  textWhatsapp: '⏳ Ждём вас в {start_time}.',
  title: '',
  oldTitle: 'Шаблон#23037',
  textSms: 'Ждём вас через час, 79258811022',
  files: null,
  mediaFile: null,
  mediaFileName: '',
  mediaFileType: null,
  mediaFileURL: null,
  error: false,
  whenSend: 'before_start',
  whenSend2Min: '15',
  whenSend2: '0',
  whenTime: '00:00',
  whenSend2Days: '1',
  recordTag: ['123', '345'],
  services: ['432432', '4324324'],
  categories: ['432432'],
  products: ['32432432'],
  staff: ['432432'],
  clientCategory: [],
  clientDidNotCome: true,
  clientExpectation: true,
  clientCame: true,
  clientConfirmed: true,
  visits: '',
  quantityFrom: '0',
  quantityTo: '1',
  blacklist: ['79167848284'],
  sendOnlyLostClient: false,
  onlineRecord: false,
  confirmRecord: false,
  textConfirmation: 'text',
  textCancel: 'text',
  thoseWhoWrote: '1',
};

export const getTemplateMockData = {
  id: '1',
  title: 'Подтверждение',
  active: true,
  text_whatsapp:
    '☕ Доброе утро,  {name}\r\n\r\nВы записаны к нам завтра в {start_time} \r\n\r\nПожалуйста, отправьте *+* чтобы подтвердить визит\r\n\r\nудалить запись:\r\n{record_link}',
  text_sms: 'Вы записаны к нам на завтра, на {start_time} 79219930877',
  type: 'all_day_recs_notifications',
  when_send: '12',
  when_time: '11:00',
  attendance: [-1, 0, 1, 2],
  services: [123],
  goods: [123],
  staff: [],
  categories: [123],
  client_category: ['123'],
  blacklist: ['123'],
  visits: [0, 1],
  visits_frequency: null,
  online: true,
  send_only_lost_client: false,
  send_confirm: false,
  confirm_record: '',
  declined_record: '',
  file: null,
};

export const dashboardData = [
  {
    label: 'Ответили',
    data: [30, 45, 25],
  },
  {
    label: 'Не отвечали',
    data: [10, 10, 80],
  },
];

export const createTemplateEventEmptyData: TCreateTemplateEventData = {
  sample_name: '',
  sample_trigger: '',
  sample_text: '',
  sample_condition: {},
  sample_black: false,
  sample_online: false,
  sample_confirm: false,
  sample_media: false,
};

export const editTemplateEventEmptyData: TEditTemplateEventData = {
  sample_on: false,
  sample_off: false,
  sample_name: '',
  sample_trigger: '',
  sample_text: '',
  sample_condition: {},
  sample_black: false,
  sample_online: false,
};
