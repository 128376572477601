import { TConnectStatuses } from '@models/index';
import { TMessages } from '@redux/connection/types';
import { TConsentCategories, TCustomerKeys } from '@redux/mailing/models';
import { TGetFormData, TGetFormQueryParams } from '@redux/autoConnect/models';

export type TPaymentsType = 'bonus_days' | 'bonus' | 'link_paid' | 'bill' | 'auto_payment' | '-';

export type TChannels = 'WA' | 'TG' | 'loyalty';

export type TGetRequestData = {
  userId: number;
  accId: string;
  branchId: string;
  sendOutAccount: boolean;
  accessToken: string;
};

export type TAuthResponse = {
  access_token: string;
  user_id: number;
  auth: boolean;
};

export type THeaderBannerInfo = {
  type: string;
  status: string;
};

export type TAuthErrorResponse = {
  message: string;
};

export type TRejectResponse = {
  ok: boolean;
  status: string;
  error: string;
  loyalty_permission_status?: boolean;
};

export type TRejectResponse2 = {
  detail: string;
};

export type TRejectValue = TRejectResponse | TRejectResponse2;

export enum REJECT_RESPONSE_KEY {
  OK = 'ok',
  STATUS = 'status',
  ERROR = 'error',
  MESSAGE = 'message',
}

export enum REJECT_RESPONSE_2_KEY {
  DETAIL = 'detail',
}

export enum AUTH_RESPONSE_KEYS {
  ACCESS_TOKEN = 'access_token',
  USER_ID = 'user_id',
  AUTH = 'auth',
}

export enum AUTH_ERROR_RESPONSE_KEY {
  MESSAGE = 'message',
}

export type TAuthTransformResponse = {
  accessToken: string;
  userId: number;
  auth: boolean;
};

export type TFilialDataResponse = {
  status: string;
  address: string;
  quantity_of_templates: number;
  acc_id: string;
  sendout_account: boolean;
  branch_id: string;
  is_admin_bot_integrated: boolean;
  pay_period: string;
  pay_status: boolean;
  action_sendout: number;
  rights: string[];
  test_loyalty: boolean;
};

export enum ACCOUNT_RESPONSE_DATA_KEYS {
  ACCOUNTS = 'accounts',
  ACCOUNTS_DATA = 'accounts_data',
  ACC_COUNT = 'acc_count',
  DATA = 'data',
  CONSULTANT = 'consultant',
  CONSULTANT_DATA = 'consultant_data',
  COUNT = 'count',
}

export type TAccountsResponse = {
  acc_count: number;
  accounts: boolean;
  accounts_data: TFilialDataResponse[];
  consultant: boolean;
  consultant_data: TConsultantResponse;
  banner_data: TBannerData;
};

export type TPaymentsRes = {
  amount: number;
  date: string;
  first_payment: string;
  paid_till: string;
};

export type TPayments_detailRes = {
  branch: string;
  payed: boolean;
  branch_name: string;
  payments: TPaymentsRes[];
};

export type TConsultantResponse = {
  bonus_days: number;
  name: string;
  one_time_pay_rate: number;
  partner_chat_url: string;
  payments_detail: TPayments_detailRes[];
  phone: string;
  promo_code: string;
  regular_pay_rate: number;
  telegram_id: number;
  one_time_discount: number;
  one_time_payment: number;
};

type TWithAccessToken = {
  access_token: string;
};

export type TBannerData = {
  image_url: string;
  info_link: string;
};

export type TGetResponseWithConsultant<T> = {
  consultant: boolean;
  data: T;
  banner_data: TBannerData;
};

export interface IGetResponseWithDataAndAccessToken<T> extends TWithAccessToken {
  count: number;
  data: T;
  banner_data: TBannerData;
}

export type TGetResponseWithData<T> = {
  count: number;
  data: T;
  banner_data: TBannerData;
  rights: string[];
};

export type TGetResponseWithDataAndOrder<T> = {
  count: number;
  data: T;
  order: number[];
};

export enum GET_RESPONSE_WITH_DATA_KEY_NAMES {
  COUNT = 'count',
  DATA = 'data',
  BANNER_DATA = 'banner_data',
  RIGHTS = 'rights',
}

export type TGetSettingResponseData = {
  exception_masters_names: string[];
  phones_dont_show_in_bot: string[];
  sms_provider: string | null;
  sms_apikey: string | null;
  sms_sendername: string | null;
  sms_login: string | null;
  sms_pass: string | null;
  bill_data: {
    inn: string;
    name: string;
    kpp: string;
  } | null;
  reply_msg_check: number;
  save_phones_ycl: boolean;
  sms_confirm: boolean;
  enable_reply_in_bot: number;
  send_contact: boolean;
  phone: string;
  message_sending_schedule: string[] | null;
  disable_chat: boolean;
};

export type TChannel = 'WA' | 'TG' | 'SMS';

export type TGetPriorityChannelResponseData = {
  [key: string]: TChannel;
};

export enum SETTINGS_RESPONSE_KEYS {
  BILL_DATA = 'bill_data',
  REPLY_MSG_CHECK = 'reply_msg_check',
}

export type TGetAccessesResponseData<T> = {
  data: T;
};

export enum BLACKLIST_RESPONSE_KEYS {
  DATA = 'data',
}

export type TAddDeleteNumberInBlackListQueryData = {
  phone: string;
};

export type TGetConnectionStatusResponse = {
  reference: string;
  messages: TMessages[];
  status: TConnectStatuses;
  phone: string | null;
};

export enum GET_CONNECTION_STATUS_KEY_NAMES {
  REFERENCE = 'reference',
  MESSAGES = 'messages',
  STATUS = 'status',
}

export type TGetQRCodeResponse = {
  ok: boolean;
  result: string;
  qr_code: string;
};

export type TGetAuthCodeResponse = {
  ok: boolean;
  auth_code: string;
};

export enum GET_QR_CODE_RESPONSE_KEY_NAMES {
  OK = 'ok',
  RESULT = 'result',
  QR_CODE = 'qr_code',
}

export enum CONNECTION_ACTION_RESULTS {
  QR_CREATE = 'QR create',
  LOGOUT = 'success',
  REBOOT = 'reboot',
  CLEAR_MESSAGE_Q = 'cleared',
}

export type TRequestStatus = {
  ok: boolean;
  status: string;
  error: string;
};

export type TPaymentsDataResponse_new = {
  date: string;
  period: string;
  amount: string;
  modules: TChannels[];
  type: TPaymentsType;
};

export type TPaymentsDataResponse = {
  date: string;
  period: string;
  summ: string;
};

export type TLinks = {
  month_1: string;
  month_3: string;
  month_6: string;
  month_12: string;
};

export type TMonthData = {
  [key: string]: number;
  '1': number;
  '3': number;
  '6': number;
  '12': number;
};

export type TCurrency = 'RUB' | 'USD';

export type TPayResponseData_new = {
  pay_status: boolean;
  pay_date: string;
  branch_employees: string;
  amount: string;
  bonus_week: TMonthData;
  month_cost: TMonthData;
  discount: number;
  autopay: boolean;
  create_bill: boolean;
  modules_price: Record<TChannels, number>;
  active_modules: TChannels[];
  last_pay_length: number;
  last_4: string;
  currency: TCurrency;
  is_test_period: boolean;
};

export type TPayResponseData = {
  pay_status: boolean;
  pay_date: string;
  branch_employees: string;
  branch: string;
  amount: string;
  autopay: boolean;
  last_4: string;
  payments: TPaymentsDataResponse[];
  links: TLinks;
  create_bill: boolean;
  review_banner: boolean;
  month_cost: TMonthData;
  bonus_week: TMonthData;
  discount: number;
};

export enum PAY_RESPONSE_KEY {
  PAY_STATUS = 'pay_status',
  PAY_DATE = 'pay_date',
  BRANCH_EMPLOYEES = 'branch_employees',
  BRANCH = 'branch',
  AMOUNT = 'amount',
}

export type TGetCalculatedPrice = {
  pay_period: string;
  amount: number;
  modules: TChannels[];
  months: number;
  currency: TCurrency;
};

export type TGetPayLinkData = {
  months: number;
  modules: TChannels[];
};

export type TPaymentHistoryResponseData = TPaymentsDataResponse[];

export type TModuleCost = {
  module: TChannels;
  months: number | null;
  days: number | null;
  cost: number;
};

export type TCalculatedPrice = {
  full_cost: number;
  modules_cost: TModuleCost[];
  new_data_if_pay_days: string | null;
  currency: TCurrency;
};

export type TGetPayLinkResponse = {
  ok: boolean;
  link: string;
};

export enum PAY_LINK_RESPONSE_KEYS {
  OK = 'ok',
  LINK = 'link',
}

export enum CREATE_INVOICE_RESPONSE_KEY_NAMES {
  BRANCHES = 'branches',
}

export type TActiveSendOutsStatuses = 'Запущена' | 'Запланирована' | 'На паузе';

export type TSendOutResponseData = {
  sendout_id: number;
  msg: string;
  date: string;
  process: string;
  status: TActiveSendOutsStatuses;
}[];

export type TResponseWithOkAndData<T> = {
  ok: boolean;
  data: T;
};

export type TParseXlsxFileResponse<T> = {
  ok: boolean;
  allowed_start: string;
  data: T;
};

export enum SEND_OUT_RESPONSE_KEYS {
  OK = 'ok',
  DATA = 'data',
}

export type TSendTestMessageRequestData = {
  formData: FormData;
};

export type TSendTestMessagePostData = {
  file: File | null;
  keys: TCustomerKeys;
  text: string;
};

export type TTopic = 'reference' | 'review';

export type TFeedbackPostData = {
  file: File | null;
  text: string;
  topic: TTopic;
  filialName: string;
  showSuccessModalCallback?: () => void;
};

export type TPostStopSendOutData = {
  sendOutId: string;
};

export type TParseXLSXFileRequestData = {
  formData: FormData;
  consentCategories: TConsentCategories;
};

export type TConsentParseXLSXFileRequestData = {
  formData: FormData;
};

export type TConsentParseXLSXFile = {
  consent_count: number;
  declined_count: number;
  neutral_count: number;
  active_count: number;
};

export type TMailingProcessData = {
  visits_median: number;
  last_visit_time_median: number;
  count: number;
  send_days: number;
  duration: number;
  sendout_batch_len: number;
  instance_id: string;
  time_to_msk: number;
  keys: TCustomerKeys;
};

export enum PARSE_XLSX_FILE_RESPONSE_KEYS {
  OK = 'ok',
  DATA = 'data',
}

export type TStartMailingPostData = {
  formData: FormData;
  startTime?: string;
};

export type TStartMailingData = {
  formData: FormData;
  startTime?: string;
  callback?: () => void;
};

export type TGetSendOutStatisticsMethod = 'all' | 'record' | 'reply' | 'ignore';

export type TGetSendOutDetailStatisticsData = {
  method: TGetSendOutStatisticsMethod;
  sendOutId: string;
  getExcelFile: boolean;
};

export type TGetCalendarData = {
  quote_for_day: number;
};

export type TCalendarResponseData = {
  start_date: string;
  end_date: string;
  duration: number;
  quota_balance: number;
};

export type TCalendarSendOutsData = {
  start_date: string;
  end_date: string;
  sendout_id: number;
};

export type TGetCalendarResponseData = {
  not_allowed: TCalendarResponseData[];
  sendout_list: TCalendarSendOutsData[];
};

export type TGetSendOutDayResponseData = {
  sendout_id: number;
  msg: string;
  time_start: string;
  visits_median: number;
  last_visit_time_median: number;
  batch_len: number;
};

export type TGetStatisticTableData = {
  method: TGetSendOutStatisticsMethod;
  sendout_id: number;
};

export type TStatisticsTableData = {
  sendout_id: number;
  text: string;
  date: string;
  recipients: number;
  answers: number;
  sign_up: number;
  summ: number;
  summ_one_msg: number;
  conversion_sign_up: number;
  conversion_answers: number;
  visits: number;
  last_visit_days: number;
  date_finish_sendout: string | null;
  sendout_stop_flag: boolean | null;
}[];

export type TStatisticsTableDataQueryParams = {
  sort: string;
  page: number;
  page_size: number;
  order_desc: boolean;
  date_start: string;
  date_end: string;
  text: string;
};

export type TStatisticsTableDataCountQueryParams = Pick<
  TStatisticsTableDataQueryParams,
  'date_start' | 'date_end' | 'text'
>;

export enum STATISTICS_RESPONSE_KEYS {
  COUNT = 'count',
  DATA = 'data',
}

export type TTemplatesStatisticsRequestData = {
  id: string;
  date_to?: string;
  date_from?: string;
  group_by?: 'month' | 'week';
};

export type TTemplatesStatisticsResponseVersionItem = {
  id: string;
  version: string;
  send_count: number;
  answered_count: number;
  recorded_count: number;
  earned_count: number;
  answered_rate: number;
  recorded_rate: number;
  earn_one_msg: number;
  text: string;
  time: string;
};

export type TTemplatesStatisticsResponseData = {
  detail: string;
  data: {
    id: string;
    count_versions: number;
    versions: TTemplatesStatisticsResponseVersionItem[];
  }[];
};

export type TTemplateStatisticDataItem = {
  answered_count: number;
  answered_rate: number;
  sent: string;
  earned_count: number;
  id: string;
  recorded_count: number;
  recorded_rate: number;
  send_after_days: string;
  send_count: number;
  send_rate: number;
  send_time: string;
  text: string;
  version: number;
  earn_one_msg: number;
};

export type TTemplateStatisticDataItemByTime = {
  id: string;
  versions: number[];
  period: string;
  send_count: number;
  answered_count: number;
  recorded_count: number;
  earned_count: number;
  send_rate: number;
  answered_rate: number;
  recorded_rate: number;
  earn_one_msg: number;
};

export type TTemplateStatisticDataResponse = {
  count: number;
  data: TTemplateStatisticDataItem[];
};

export type TTemplateStatisticDataResponseByTime = {
  count: number;
  data: TTemplateStatisticDataItemByTime[];
};

export type TTemplateListData = {
  active: boolean;
  event: string;
  id: string;
  name: string;
  title: string;
  time_mark: string;
  time: string;
  send_only_lost_client: boolean;
};

export type TTemplateOrderType = 'all' | 'event' | 'timing' | 'review' | 'loyalty';

export type TTemplateListRequestData = {
  em: boolean;
  tm: boolean;
};

export enum TEMPLATE_RESPONSE_KEYS {
  COUNT = 'count',
  DATA = 'data',
}

export type TTemplateRequestData = {
  id: string;
};

export interface ITemplateOperationData extends TTemplateRequestData {
  state: boolean;
  tm: boolean;
  em: boolean;
}

export type TTemplateData = {
  attendance: number[];
  blacklist: string[];
  categories: number[];
  record_labels: number[];
  client_category: string[];
  send_confirm: boolean;
  confirm_record: string;
  declined_record: string;
  file: string | null;
  goods: number[];
  id: string;
  online: boolean;
  send_only_lost_client: boolean;
  services: number[];
  staff: number[];
  active: boolean;
  text_sms: string | null;
  text_whatsapp: string;
  type: string;
  visits_frequency: number | null;
  visits: number[] | null;
  when_send: string;
  when_time: string;
  title: string;
};

export enum TEMPLATE_DATA_RESPONSE_KEY_NAMES {
  CONFIRM_RECORD = 'confirm_record',
}

export type TTemplateDataToSave = {
  id: string;
  active: boolean;
  text_whatsapp: string;
  title: string;
  text_sms: string;
  attendance: number[];
  type: string;
  when_send: string;
  when_time: string;
  services: number[];
  goods: number[];
  staff: number[];
  record_labels: number[];
  categories: number[];
  client_category: string[];
  blacklist: string[];
  send_only_lost_client: boolean;
  visits: number[];
  online: boolean;
  visits_frequency: number | '';
  send_confirm: boolean;
  confirm_record: string;
  declined_record: string;
};

export interface IWrTemplateDataToSave {
  id: string;
  status_enable: boolean;
  text_whatsapp: string;
  text_sms: string;
  when_send: string;
  when_time: string;
  blacklist: string[];
}

export type TSaveWrTemplateData = {
  data: FormData;
  templateId: string;
};

export type TGetOneReviewSecondStep = {
  active: boolean;
  body: string;
};

export type TGetOneReviewData = {
  first_step: {
    WA_alerts: string[];
    title: string;
    active: boolean;
    after_end: number | null;
    all_day_recs_notifications: {
      day: string;
      time: string;
    } | null;
    attendance: number[];
    blacklist: string[];
    body: string;
    categories: number[];
    record_labels: number[];
    client_category: string[];
    id: string;
    services: number[];
    staff: number[];
    visits: number[];
    visits_frequency: number | null;
    tg_alerts: number[];
  };
  second_step: TGetOneReviewSecondStep[];
  last_step: {
    active: boolean;
    body: string;
    fail_text: string;
  };
};

export enum GET_ONE_REVIEW_DATA_KEY_NAMES {
  FIRST_STEP = 'first_step',
}

export type TReviewFirstStepInListData = {
  id: string;
  title: string;
  active: boolean;
  body: string;
  time: string;
};

export type TReviewSecondStepInListData = {
  active: boolean;
  body: string;
};

export enum T_STAFF_LIST_RESPONSE_KEYS {
  COUNT = 'count',
  DATA = 'data',
}

export type TSwitchReviewChainStepData = {
  templateId: string;
  stepNumber: number;
  switch: boolean;
  data: TGetRequestData;
};

export type TStaffListItem = {
  id: number;
  name: string;
};

export type TDeleteReviewStepData = Pick<
  TSwitchReviewChainStepData,
  'templateId' | 'stepNumber' | 'data'
>;

export type TDeleteReviewByIdData = Pick<TSwitchReviewChainStepData, 'templateId' | 'data'>;

export type ISwitchReviewChain = {
  templateId: string;
  stepSwitchStatus: boolean;
  data: TGetRequestData;
};

export type TGetReviewData = Pick<TSwitchReviewChainStepData, 'templateId'>;

export type TSaveReview = { userId: number; accId: string; data: TGetOneReviewData };
export type TUpdateReview = { templateId: string; data: TGetOneReviewData };

export type TGetFullTableData = {
  branch: string;
  branch_name: string;
  created_at: string;
  pay_status: string;
  first_payment_date: string;
};

export type TPromoData = {
  qr_code: string;
  promo_url: string;
  promo_code: string;
};

export type TGetFullTableResponse<T> = {
  data: T[];
  qr_code: string;
  promo_url: string;
  promo_code: string;
  total_accrued: string;
};

export enum GET_FULL_TABLE_RESPONSE_KEY_NAME {
  DATA = 'data',
  PROMO_URL = 'promo_url',
  PROMO_CODE = 'promo_code',
  TOTAL_ACCRUED = 'total_accrued',
}

export type TGetConsultantFullTableData = {
  branch: string;
  branch_name: string;
  first_payment_date: string;
  paid_till: string;
};

export type TGetOperationListTableData = {
  operation_date: string;
  income_amount: number;
  accrued_amount: number;
  branch: string;
  branch_name: string;
  status: string;
};

export type TGetPayHistoryConsultantTableData = {
  period: string;
  amount: number;
  payed: boolean;
};

export type TGetPayHistorySalonTableData = Record<string, number>;

export type TPayments = {
  amount: number;
  data: string;
  first_payment: string;
  paid_till: string;
};

export type TPaymentsData = {
  amount: number;
  date: string;
  firstPayment: string;
  paidTill: string;
};

export type TPaymentsDetail = {
  branch: string;
  branchName: string;
  payments: TPaymentsData[];
};

export type TConsultantData = {
  bonusDay: number;
  name: string;
  oneTimePayRate: number;
  partnerChatUrl: string;
  paymentsDetail: TPaymentsDetail[];
  phone: string;
  promoCode: string;
  regularPayRate: number;
  telegramId: number;
  oneTimeDiscount: number;
  oneTimePayment: number;
};

export type TPayments_detail = {
  branch: string;
  branch_name: string;
  payments: TPayments[];
};

export type TConsultantResponse2 = {
  bonus_days: number;
  name: string;
  one_time_pay_rate: number;
  partner_chat_url: string;
  payments_detail: TPayments_detail[];
  phone: string;
  promo_code: string;
  regular_pay_rate: number;
  telegram_id: number;
};

export type TChatHistoryMessageType = 'text' | 'file' | 'contact' | 'geo';
export type TChatHistoryMessageDirection = 'outgoing' | 'incoming';
export type TChatHistoryMessageFileData = {
  file_name: string;
  size: string;
  content_type: string;
};

export type TChatHistoryMessageDataItem = {
  phone: string;
  body: string;
  time: string | number;
  name: string | null;
  type: TChatHistoryMessageType;
  direction: TChatHistoryMessageDirection;
  sent_api: boolean;
  file_data: TChatHistoryMessageFileData | null;
  caption?: string | null;
};

export type TChatHistoryResponse = {
  count: number;
  messages: TChatHistoryMessageDataItem[];
};

export enum CHAT_RESPONSE_KEY_NAMES {
  COUNT = 'count',
  MESSAGES = 'messages',
}

export type TSendOutDetailsStatisticsParsingResponse = {
  phone: string;
  name: string;
  record_time: string | null;
  reply_time: string | null;
  services: string[] | null;
  summary_cost: number | null;
};

export type DetailReviewStatisticsServicesField = {
  id: number;
  title: string;
  cost: number;
  cost_per_unit: number;
  first_cost: number;
  amount: number;
};

export type TSendOutDetailStatisticsResponse = {
  phone: string[];
  name: string[];
  record_time: string[] | null;
  reply_time: string[] | null;
  services: string[][] | null;
  summary_cost: number[] | null;
};

export type TLeadsData = {
  promoCode: string;
  phone: string;
  branchId: string;
  leadName: string;
  comment: string;
};

export type TCreateLeadsData = {
  promo_code: string;
  branch_id: string;
  phone: string;
  branch_name: string;
  comment: string;
};

export type TGetLeadsData = {
  promoCode: string;
};

export type TGetLeadsResponseData = {
  promo_code: string;
  phone: string;
  branch: string;
  branch_name: string;
  comment: string;
  created_branches: string[];
  created_at: string;
};

export type TAutoResponseItemData = {
  words: string[];
  reply: string;
  caption: string;
  active: boolean;
};

export type TGetAutoResponseData = {
  items: TAutoResponseItemData[];
};

export enum AUTO_RESPONSE_DATA_KEY_NAMES {
  ITEMS = 'items',
}

export type TUpdateAutoResponseItemSendData = {
  reply: string;
  caption: string;
  active: boolean;
  old_words: string[];
  new_words: string[];
};

export type TTemplateDetailStatsResponseItem = {
  phone: string;
  version: number;
  answer_process: boolean;
  created_at: string;
  record_process: boolean;
  updated_at: string;
  record_time: string | null;
  reply_time: string | null;
  sum: number | null;
  name: string | null;
  services: string[] | null;
};

export type TTemplateDetailStatsResponse = {
  data: TTemplateDetailStatsResponseItem[];
};

export type TTemplateDetailStatsItemData = {
  phone: string;
  version: number;
  answerProcess: boolean;
  createdAt: string;
  recordProcess: boolean;
  updatedAt: string;
  recordTime: string | null;
  replyTime: string | null;
  sum: number | null;
  name: string | null;
  services: string[] | string | null;
};

export type TTemplateDetailStatsData = TTemplateDetailStatsItemData[];

export type TTemplatesDetailStatsTableFilter = {
  value: string;
  filteredDetailTableDropdown: string[];
};

export type TPromoBonusData = {
  bonus_days: number;
  discount: number;
};

export type TAutoConnectionFormData = {
  form_step: number;
  branch_name: string;
  owner_phone: string;
  // salon_type: string;
  emoji_list: string[];
  emoji: string;
  salon_phone: string;
  wa_life_time: string;
  main_url: string;
  other_urls: string[];
  address: string;
  deep_link: string;
  qr_code: string;
  from_ref: string;
  promo_bonus: TPromoBonusData;
};

export enum AUTO_CONNECTION_RESPONSE_DATA_KEY_NAMES {
  FORM_STEP = 'form_step',
}

export type TAcceptFormStepData = {
  params: TGetFormData;
  data: TAutoConnectionFormData;
};

export type TSendDeepLinkToWaData = {
  phoneNumber: string;
  params: TGetFormQueryParams;
};

export type TGetCallbackCallData = {
  phoneNumber: string;
  params: TGetFormData;
};

export type TInviteActionRecordLabel = {
  id: number;
  title: string;
};

export type TAllDayRecsNotifications = {
  day: string;
  time: string;
};

export type TInviteFriendsCard = {
  id: number;
  title: string;
  salon_group_id: number;
};

export type TInviteFriendsActionResponse = {
  title: string;
  card_type: TInviteFriendsCard | null;
  bonus_type: string;
  loyalty_points: number | null;
  interest_regular_customer: number | null;
  new_customer_points: number | null;
  range_of_visits: number[] | null;
  first_message: string;
  second_message: string;
  services: number[] | null;
  categories: number[] | null;
  staff: number[] | null;
  record_labels: TInviteActionRecordLabel[] | null;
  client_category: string[] | null;
  after_end: number | null;
  before_start: number | null;
  all_day_recs_notifications: TAllDayRecsNotifications | null;
  visits: number[] | null;
  blacklist: string[] | null;
  active: boolean;
  sending_bonuses: boolean;
  date_create_template: string | null;
  date_first_activate_template: string | null;
  id: string;
  loyalty_permission_status?: boolean;
};

export type TInviteFriendsActionListResponse = {
  title: string;
  card_type: TInviteFriendsCard | null;
  bonus_type: string;
  active: boolean;
  id: string;
};

export type TSwitchActionRequestData = {
  template_id: string;
  status_template: boolean;
};

export type TGetInviteFriendsAnalytics = {
  template_id: string;
  template_name: string;
  amount_accrued_bonuses_parents: number | null;
  amount_accrued_bonuses_children: number | null;
  count_child: number | null;
  count_parent: number | null;
  amount_payments_child: number | null;
  median_payments_child: number | null;
  median_children_records: number | null;
  date_first_activate_template: string | null;
  child_payment_ratio: number | null;
  count_child_paid: number | null;
  child_signup_ratio: number | null;
  child_have_records: number | null;
};

export type TGetInviteFriendsCartType = {
  id: number;
  title: string;
  salon_group_id: number;
};

export type TMinimalPriceResponseData = {
  id: 0;
  title: string;
  category_id: 0;
  price_min: 0;
};

export type TCommunicationMethod = 'whatsapp' | 'phone';

export type TStartPageAutoConnectionData = {
  lead_id: string;
  phone: string;
  salon_id: string;
  user_name: string;
  communication_method: TCommunicationMethod;
};

export type TSendOutBlackLitResponse = {
  acc_id: string;
  phone: string;
  active: boolean;
  client_text: string;
  consent: boolean;
  consent_at: string;
  created_at: string;
};
