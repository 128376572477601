import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { TKeyData } from '@redux/template/models';
import { getKeysData } from '@helpers/contentMaker';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { reviewsEmptyStep24 } from '@redux/reviews/mockData';
import { COMMON_TOAST, COMMON_TOAST_MESSAGES } from '@const/common';
import { getListOfCategoriesTS } from '@redux/template/templateThunks';
import { TBalloonsKeyNames, TParseDivValueToSlice } from '@shared/types';
import { REVIEW_MESSAGE, WHEN_SEND2_SELECT_OPTION_REVIEWS } from '@const/reviews';
import { addNewLines, createAWhenSend2ReviewItemList, getWrapperText } from '@helpers/index';
import {
  TStaffListItem,
  TRejectResponse,
  TRejectResponse2,
  REJECT_RESPONSE_KEY,
  REJECT_RESPONSE_2_KEY,
} from '@api/types';
import {
  TReviewData,
  TReviewsStep1Data,
  TReviewsStep5Data,
  TReviewsStep24Data,
  TReviewInListMapData,
  REVIEW_STEP_ONE_KEY_NAMES,
} from '@redux/reviews/models';
import {
  saveReview,
  updateReview,
  deleteReview,
  getReviewById,
  getReviewsList,
  reviewStepDelete,
  switchChainActivity,
  switchChainStepActivity,
} from '@redux/reviews/reviewsThunks';

import { IInitialState, initialState } from './initialState';

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setOneReviewData(state: IInitialState, action: PayloadAction<TReviewData>) {
      state.oneReviewData = action.payload;
    },
    setReviewOrderListData(state: IInitialState, action: PayloadAction<number[]>) {
      state.reviewsLitOrder = action.payload;
    },
    clearChainErrors(state: IInitialState) {
      state.isDeletingError = false;
      state.isSwitchingError = false;
      state.isSaveError = false;
      state.errorMessage = '';
    },
    clearSuccessStatus(state: IInitialState) {
      state.isChainDeletingSuccess = false;
      state.isStepDeletingSuccess = false;
      state.isSaveSuccess = false;
      state.isDeleteStepSuccess = false;
      state.warningMessage = '';
    },
    setChainName(state: IInitialState, action: PayloadAction<{ title: string }>) {
      state.oneReviewData.templates.firstStep.title = action.payload.title;
    },
    setChainStatus(state: IInitialState, action: PayloadAction<{ checked: boolean }>) {
      state.oneReviewData.status = action.payload.checked;
    },
    setIdChainDeleted(state: IInitialState, action: PayloadAction<{ deleteChainId: string }>) {
      state.deleteChainId = action.payload.deleteChainId;
    },
    setAbandonedTextareaDataRV(
      state: IInitialState,
      action: PayloadAction<{
        name: string;
        stepId: number;
        selectionStart: number;
        selectionEnd: number;
      }>,
    ) {
      const { specialKeyDict } = state;

      const { name, stepId, selectionStart, selectionEnd } = action.payload;

      let text = '';

      if (stepId === 0) {
        text = state.oneReviewData.templates.firstStep[name] as string;
      }
      if (stepId > 0 && stepId < 5) {
        text = state.oneReviewData.templates.secondStep[stepId - 1][name] as string;
      }
      if (stepId === 5) {
        text = state.oneReviewData.templates.lastStep[name] as string;
      }

      state.textareaKeysData = getKeysData(text, specialKeyDict);

      state.stepId = stepId;
      state.blurFromTextareaName = name;
      state.selectionEnd = selectionEnd;
      state.selectionStart = selectionStart;
    },
    wrapTextRV(
      state: IInitialState,
      action: PayloadAction<{ innerText: string; callback: TParseDivValueToSlice }>,
    ) {
      const { innerText, callback } = action.payload;
      const { stepId, blurFromTextareaName, selectionEnd, selectionStart, specialKeyDict } = state;

      const wrapSymbol = innerText.slice(0, 1);

      let wrapTextSymbols = '';
      if (wrapSymbol === '`') {
        wrapTextSymbols = '```';
      } else {
        wrapTextSymbols = wrapSymbol;
      }

      let newTextAreaValue = '';
      if (stepId === 0) {
        const textAreaValue = String(state.oneReviewData.templates.firstStep[blurFromTextareaName]);

        newTextAreaValue = getWrapperText(
          textAreaValue,
          wrapTextSymbols,
          selectionStart,
          selectionEnd,
        );

        state.oneReviewData.templates.firstStep[blurFromTextareaName] = newTextAreaValue;
      }
      if (stepId > 0 && stepId < 5) {
        const textAreaValue = String(
          state.oneReviewData.templates.secondStep[stepId - 2][blurFromTextareaName],
        );

        newTextAreaValue = getWrapperText(
          textAreaValue,
          wrapTextSymbols,
          selectionStart,
          selectionEnd,
        );

        state.oneReviewData.templates.secondStep[stepId - 2][blurFromTextareaName] =
          newTextAreaValue;
      }
      if (stepId === 5) {
        const textAreaValue = String(state.oneReviewData.templates.lastStep[blurFromTextareaName]);
        newTextAreaValue = getWrapperText(
          textAreaValue,
          wrapTextSymbols,
          selectionStart,
          selectionEnd,
        );

        state.oneReviewData.templates.lastStep[blurFromTextareaName] = newTextAreaValue;
      }

      callback({
        text: newTextAreaValue,
        selectionStart: wrapTextSymbols.length + selectionEnd + 1,
        selectionEnd: wrapTextSymbols.length + selectionEnd + 1,
        specialKeyDict,
      });
    },
    addKeyInTextareaRV(
      state: IInitialState,
      action: PayloadAction<{ innerText: string; callback: TParseDivValueToSlice }>,
    ) {
      const { stepId, specialKeyDict } = state;
      const { innerText, callback } = action.payload;
      const { blurFromTextareaName, selectionEnd, selectionStart } = state;

      let newTextAreaValue = '';

      const lastStepIndex = state.oneReviewData.templates.secondStep.length + 2;

      const innerTextLength = innerText.length;

      const selectionStartOffset = selectionStart + innerTextLength;

      const selectionEndOffset = selectionEnd + innerTextLength;

      if (stepId === 0) {
        const textAreaValue = String(state.oneReviewData.templates.firstStep[blurFromTextareaName]);

        newTextAreaValue =
          textAreaValue.substring(0, selectionStart) +
          innerText +
          textAreaValue.substring(selectionEnd);

        state.oneReviewData.templates.firstStep[blurFromTextareaName] = newTextAreaValue;

        state.textareaKeysData = getKeysData(newTextAreaValue, specialKeyDict);
      }
      if (stepId > 0 && stepId < lastStepIndex) {
        const textAreaValue = String(
          state.oneReviewData.templates.secondStep[stepId - 1][blurFromTextareaName],
        );
        newTextAreaValue =
          textAreaValue.substring(0, selectionStart) +
          innerText +
          textAreaValue.substring(selectionEnd);

        state.oneReviewData.templates.secondStep[stepId - 1][blurFromTextareaName] =
          newTextAreaValue;

        state.textareaKeysData = getKeysData(newTextAreaValue, specialKeyDict);
      }
      if (stepId === 5) {
        const textAreaValue = String(state.oneReviewData.templates.lastStep[blurFromTextareaName]);

        newTextAreaValue =
          textAreaValue.substring(0, selectionStart) +
          innerText +
          textAreaValue.substring(selectionEnd);

        state.oneReviewData.templates.lastStep[blurFromTextareaName] = newTextAreaValue;

        state.textareaKeysData = getKeysData(newTextAreaValue, specialKeyDict);
      }

      callback({
        text: newTextAreaValue,
        selectionStart: selectionStartOffset,
        selectionEnd: selectionEndOffset,
        specialKeyDict,
      });

      state.selectionEnd = selectionStartOffset;
      state.selectionStart = selectionEndOffset;
    },
    setNewBalloonValueRV(
      state: IInitialState,
      action: PayloadAction<{ name: string; value: string }>,
    ) {
      const { name, value } = action.payload;
      if (name === REVIEW_STEP_ONE_KEY_NAMES.TG_ALERTS) {
        state[`${name}NewValue`] = value.replace(/[^-?0-9]/, '');
      } else {
        state[`${name}NewValue`] = value.replace(/\D/g, '');
      }
    },
    addBalloonRV(
      state: IInitialState,
      action: PayloadAction<{ keyName: TBalloonsKeyNames; value: string }>,
    ) {
      const { keyName, value } = action.payload;
      if (!value) return;
      const newArray = state.oneReviewData.templates.firstStep[keyName] as string[];

      if (newArray.find(item => item === value)) {
        state[`${keyName}NewValue`] = '';
        return;
      }

      state.oneReviewData.templates.firstStep[keyName] = newArray.concat(value);
      state[`${keyName}NewValue`] = '';
    },
    deleteBalloonRV(
      state: IInitialState,
      action: PayloadAction<{ keyName: TBalloonsKeyNames; value: string }>,
    ) {
      const { keyName, value } = action.payload;
      const newArray = state.oneReviewData.templates.firstStep[keyName] as string[];
      state.oneReviewData.templates.firstStep[keyName] = newArray.filter(item => item !== value);
    },
    clearNewBalloonValueRV(state: IInitialState, action: PayloadAction<{ name: string }>) {
      const { name } = action.payload;
      state[`${name}NewValue`] = '';
    },
    clearErrors(state: IInitialState) {
      state.tooManyStepsError = false;
      state.tooManyReviewsError = false;
    },
    addStep(state: IInitialState) {
      if (state.oneReviewData.templates.secondStep.length >= 3) {
        state.tooManyStepsError = true;
      } else {
        const emptyStep = {
          ...reviewsEmptyStep24,
          id: state.oneReviewData.templates.secondStep.length + 2,
        };
        state.oneReviewData.templates.secondStep = [
          ...state.oneReviewData.templates.secondStep,
          emptyStep,
        ];
      }
    },
    deleteStepInOneChain(state: IInitialState, action: PayloadAction<{ stepId: number }>) {
      const { stepId } = action.payload;
      state.oneReviewData.templates.secondStep = state.oneReviewData.templates.secondStep.filter(
        item => item.id !== stepId,
      );
    },
    onChange(
      state: IInitialState,
      action: PayloadAction<{
        stepId: number;
        stepData: TReviewsStep1Data | TReviewsStep24Data[] | TReviewsStep5Data;
      }>,
    ) {
      const { stepId, stepData } = action.payload;
      if (stepId === 0) {
        state.oneReviewData.templates.firstStep = stepData as unknown as TReviewsStep1Data;
      }
      if (Array.isArray(stepData)) {
        state.oneReviewData.templates.secondStep = state.oneReviewData.templates.secondStep.map(
          (item, index) => {
            if (index === stepId - 1) {
              return stepData[0];
            }
            return item;
          },
        );
      }
      if (stepId === 5) {
        state.oneReviewData.templates.lastStep = stepData as unknown as TReviewsStep5Data;
      }
    },
    setOldTitle(state: IInitialState, action: PayloadAction<{ title: string }>) {
      const { title } = action.payload;
      state.oneReviewData.templates.firstStep.oldTitle = title;
      state.oneReviewData.templates.firstStep.title = title;
    },
    clearUpdateNavId(state: IInitialState) {
      state.updateNavId = '';
    },
    setTextareaValueRV(
      state: IInitialState,
      action: PayloadAction<{
        stepId: number;
        value: string;
        name: string;
        selectionEnd: number;
        selectionStart: number;
        newTextareaKeysData: TKeyData[];
      }>,
    ) {
      const { stepId, value, selectionStart, selectionEnd, newTextareaKeysData, name } =
        action.payload;

      state.selectionStart = selectionStart;
      state.selectionEnd = selectionEnd;

      state.textareaKeysData = newTextareaKeysData;

      if (stepId === 0) {
        state.oneReviewData.templates.firstStep[name] = value;
      }
      if (stepId > 0 && stepId < 5) {
        state.oneReviewData.templates.secondStep[stepId - 1][name] = value;
      }
      if (stepId === 5) {
        state.oneReviewData.templates.lastStep[name] = value;
      }
    },
    showVisitsWarningMessage(state: IInitialState) {
      state.warningMessage = COMMON_TOAST_MESSAGES.VISITS_QUANTITY_ERROR;
    },
  },
  extraReducers: builder => {
    builder.addCase(getReviewsList.pending, (state: IInitialState) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(
      getReviewsList.fulfilled,
      (
        state: IInitialState,
        action: PayloadAction<{ list: TReviewInListMapData[]; order: number[] } | void>,
      ) => {
        if (action.payload) {
          const { list, order: orderFomApi } = action.payload;

          let order: number[];

          if (!orderFomApi?.length) {
            order = list.map(item => +item.firstStep.id);
          } else {
            order = orderFomApi;
          }

          const reviewsOrderedList = list.reduce<Record<string, TReviewInListMapData>>(
            (accum, item) => {
              return { ...accum, [item.firstStep.id]: { ...item } };
            },
            {} as Record<string, TReviewInListMapData>,
          );

          state.reviewsList = list;
          state.reviewsLitOrder = order;
          state.reviewsObj = reviewsOrderedList;
        }
        if (state.isRetries) {
          state.retries = 1;
          state.isRetries = false;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(
      getReviewsList.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
            state.isRetries = true;
            state.retries = state.retries--;
          } else {
            state.isError = true;
            state.isLoading = false;
          }
        } else if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isError = true;
          }
          state.isLoading = false;
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
    builder.addCase(switchChainStepActivity.pending, (state: IInitialState) => {
      state.isSwitchingError = false;
    });
    builder.addCase(
      switchChainStepActivity.fulfilled,
      (
        state: IInitialState,
        action: PayloadAction<{ templateId: string; stepNumber: string; switch: boolean } | void>,
      ) => {
        if (action.payload) {
          state.isSwitchingError = false;
          const { templateId, stepNumber, switch: switchChain } = action.payload;

          state.reviewsList = state.reviewsList.map(item => {
            const lastStepIndex = !item.secondStep.length ? 2 : item.secondStep.length + 2;

            if (item.firstStep.id === templateId) {
              if (+stepNumber === 1) {
                item.firstStep.active = switchChain;
              }

              if (+stepNumber === lastStepIndex) {
                item.lastStep.active = switchChain;
              }

              if (+stepNumber > 0 && +stepNumber < lastStepIndex) {
                item.secondStep = item.secondStep.map((step, index) => {
                  if (+stepNumber === index + 2) {
                    step.active = switchChain;
                  }
                  return step;
                });
              }
            }

            if (templateId === item.firstStep.id) {
              state.reviewsObj = { ...state.reviewsObj, [item.firstStep.id]: item };
            }

            return item;
          });
        }
      },
    );
    builder.addCase(switchChainStepActivity.rejected, (state: IInitialState) => {
      state.isSwitchingError = true;
      state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
    });
    builder.addCase(getReviewById.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
      state.isAccessDenied = false;
    });
    builder.addCase(
      getReviewById.fulfilled,
      (state: IInitialState, action: PayloadAction<TReviewData | void>) => {
        state.isLoading = false;
        if (action.payload) {
          state.oneReviewData = action.payload;
          state.oldOneReviewData = action.payload;

          const { templates } = action.payload;
          const { timeType, whenSend } = templates.firstStep;

          if (!WHEN_SEND2_SELECT_OPTION_REVIEWS.eng[timeType][whenSend]) {
            state.whenSend2SelectOption = createAWhenSend2ReviewItemList({
              whenSend: timeType,
              whenSend2: whenSend,
              optionList: WHEN_SEND2_SELECT_OPTION_REVIEWS,
            });
          }
          if (state.isRetries) {
            state.retries = 1;
            state.isRetries = false;
          }
        }
      },
    );
    builder.addCase(
      getReviewById.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
            state.isRetries = true;
            state.retries = state.retries--;
          } else {
            state.isError = true;
            state.isLoading = false;
          }
        } else if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isError = true;
          }
          state.isLoading = false;
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
    builder.addCase(saveReview.pending, (state: IInitialState) => {
      state.isError = false;
      state.isSave = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(
      saveReview.fulfilled,
      (state: IInitialState, action: PayloadAction<{ id: string }>) => {
        state.isSave = false;
        state.isSaveSuccess = true;
        state.updateNavId = action.payload.id;
        state.oldOneReviewData = state.oneReviewData;
        state.successMessage = REVIEW_MESSAGE.SAVE_SUCCESS;
      },
    );
    builder.addCase(
      saveReview.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isSaveError = true;
          }
          state.isSave = false;
        }
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isSaveError = true;
            state.isSave = false;
          }
        }
        if (action.payload && REJECT_RESPONSE_KEY.ERROR in action.payload) {
          state.isSaveError = true;
          state.isSave = false;
          state.errorMessage = addNewLines(action.payload.error);
        }
        if (!action.payload) {
          state.isSaveError = true;
          state.isSave = false;
          state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(updateReview.pending, (state: IInitialState) => {
      state.isError = false;
      state.isSave = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(updateReview.fulfilled, (state: IInitialState) => {
      state.isSave = false;
      state.isSaveSuccess = true;
      state.oldOneReviewData = state.oneReviewData;
      state.successMessage = REVIEW_MESSAGE.SAVE_SUCCESS;
    });
    builder.addCase(
      updateReview.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
            state.isRetries = true;
            state.retries = state.retries--;
          } else {
            state.isSaveError = true;
            state.isSave = false;
          }
        }
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isSaveError = true;
          }
          state.isSave = false;
        }
        if (action.payload && REJECT_RESPONSE_KEY.ERROR in action.payload) {
          state.isSave = false;
          state.isSaveError = true;
          state.errorMessage = addNewLines(action.payload.error);
        }
        if (!action.payload) {
          state.isSaveError = true;
          state.isSave = false;
          state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(deleteReview.pending, (state: IInitialState) => {
      state.isDeleting = true;
      state.isDeletingError = false;
      state.isChainDeletingSuccess = false;
    });
    builder.addCase(
      deleteReview.fulfilled,
      (state: IInitialState, action: PayloadAction<string | void>) => {
        state.isRefresh = false; // Данная строка позволяет достать данные после удаления
        state.isDeleting = false;
        state.isChainDeletingSuccess = true;

        if (action.payload) {
          const newReviewList = state.reviewsList.filter(
            item => item.firstStep.id !== action.payload,
          );

          const order: number[] = newReviewList.map(item => +item.firstStep.id);

          const reviewsOrderedList = newReviewList.reduce<Record<string, TReviewInListMapData>>(
            (accum, item) => {
              return { ...accum, [item.firstStep.id]: { ...item } };
            },
            {} as Record<string, TReviewInListMapData>,
          );

          state.reviewsLitOrder = order;
          state.reviewsList = newReviewList;
          state.reviewsObj = reviewsOrderedList;
        }
        state.successMessage = `Цепочка ${state.deleteChainId} \n успешно удалена`;
      },
    );
    builder.addCase(
      deleteReview.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
            state.isRetries = true;
            state.retries = state.retries--;
          } else {
            state.isDeletingError = true;
            state.isDeleting = false;
          }
        } else if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isDeletingError = true;
          }
          state.isDeleting = false;
        } else {
          state.isDeletingError = true;
          state.isDeleting = false;
        }
        state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
      },
    );
    builder.addCase(reviewStepDelete.pending, (state: IInitialState) => {
      state.isDeleting = true;
      state.isDeletingError = false;
      state.isDeleteStepSuccess = false;
    });
    builder.addCase(
      reviewStepDelete.fulfilled,
      (
        state: IInitialState,
        action: PayloadAction<{ templateId: string; stepNumber: string } | void>,
      ) => {
        state.isRefresh = false; // Данная строка позволяет достать данные после удаления
        state.isDeleting = false;
        state.isDeleteStepSuccess = true;

        if (action.payload) {
          const { templateId, stepNumber } = action.payload;

          const newReviewChain = state.reviewsList.map(item => {
            if (item.firstStep.id === templateId) {
              item.secondStep = item.secondStep.filter((_, index) => index + 2 !== +stepNumber);
            }

            return item;
          });
          if (state.isRetries) {
            state.retries = 1;
            state.isRetries = false;
          }
          state.reviewsList = newReviewChain;
        }
        state.successMessage = `Шаг из цепочки ${state.deleteChainId} \n успешно удален`;
      },
    );
    builder.addCase(
      reviewStepDelete.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        state.isDeleteStepSuccess = false;

        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
            state.isRetries = true;
            state.retries = state.retries--;
          } else {
            state.isDeletingError = true;
            state.isDeleting = false;
          }
        } else if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isDeletingError = true;
          }
          state.isDeleting = false;
        } else {
          state.isDeletingError = true;
          state.isDeleting = false;
        }
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(switchChainActivity.pending, (state: IInitialState) => {
      state.isSwitchingError = false;
    });
    builder.addCase(
      switchChainActivity.fulfilled,
      (
        state: IInitialState,
        action: PayloadAction<{ templateId: string; stepSwitchStatus: boolean } | void>,
      ) => {
        state.isSwitchingError = false;
        if (action.payload) {
          const { templateId, stepSwitchStatus } = action.payload;

          state.reviewsList = state.reviewsList.map(item => {
            if (item.firstStep.id === templateId) {
              item.firstStep.active = stepSwitchStatus;

              state.reviewsObj = { ...state.reviewsObj, [templateId]: item };
            }

            return item;
          });
        }
      },
    );
    builder.addCase(switchChainActivity.rejected, (state: IInitialState) => {
      state.isSwitchingError = true;
      state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
    });
    builder.addCase(
      getListOfCategoriesTS.fulfilled,
      (
        state: IInitialState,
        action: PayloadAction<{ data: TStaffListItem[]; categoryName: string } | void>,
      ) => {
        if (!action.payload) return;
        const { data, categoryName } = action.payload;
        const newStaffList = data.map(item => [String(item.id), item.name]);
        if (newStaffList.length) {
          state.categoriesDataList[categoryName] = Object.fromEntries(newStaffList);
        } else {
          state.categoriesDataList[categoryName] = { '-': 'Нет записей' };
        }
      },
    );
    builder.addCase(
      getListOfCategoriesTS.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessDenied = true;
          } else {
            state.isGettingStaffListError = true;
          }
        }
        if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isGettingStaffListError = true;
          }
        }
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
  },
});

export const reviewsReducer = reviewsSlice.reducer;
export const {
  addStep,
  onChange,
  wrapTextRV,
  clearErrors,
  setOldTitle,
  addBalloonRV,
  setChainName,
  setChainStatus,
  deleteBalloonRV,
  clearUpdateNavId,
  setOneReviewData,
  clearChainErrors,
  setIdChainDeleted,
  addKeyInTextareaRV,
  clearSuccessStatus,
  setTextareaValueRV,
  deleteStepInOneChain,
  setNewBalloonValueRV,
  setReviewOrderListData,
  clearNewBalloonValueRV,
  showVisitsWarningMessage,
  setAbandonedTextareaDataRV,
} = reviewsSlice.actions;
