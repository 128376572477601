import React, { useEffect, lazy, Suspense, memo } from 'react';
import { Route, useNavigate, useSearchParams } from 'react-router-dom';
//
import Page404 from '@pages/404';
import PayPage from '@pages/pay';
import { Loader } from '@blocks/loader';
import ReviewsPage from '@pages/reviews';
import MailingPage from '@pages/mailing';
import AccountsPage from '@pages/accounts';
import SettingsPage from '@pages/settings';
import TemplatePage from '@pages/template';
import { storageDb } from '@api/storageApi';
import ChatHistory from '@pages/chatHistory';
import BlackListPage from '@pages/blackList';
import ConnectionPage from '@pages/connection';
import PartnersPage from '@pages/partnersPage';
import { BaseTemplate } from '@templates/base';
import AutoResponsePage from '@pages/autoResponse';
import TemplateListPage from '@pages/templateList';
import InviteFriendPage from '@pages/inviteFriends';
import { getAuthData } from '@redux/auth/selectors';
import { PrivateRoute } from '@components/privedRoute';
import { setAuthUserData } from '@redux/auth/authSlice';
import { NO_FILIAL_ERROR, PATH_NAMES } from '@data/dict';
import withErrorBoundary from '@components/ErrorBoundary';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import InviteFriendsActionPage from '@pages/inviteFriendsAction';
import { setSelectedFilial, showNoFilialError } from '@redux/accounts/accountsSlice';

import './App.css';

const LoginPageL = lazy(() => import('@pages/login/'));
const YclientsStartPageL = lazy(() => import('@pages/yclientsStartPage/'));
const AutoConnectL = lazy(() => import('@pages/autoConnect/'));
const AutoConnectFormL = lazy(() => import('@pages/autoConnectForm/'));

const AppComponent = memo(() => {
  /**
   * Объект dispatch для запуска экшинов
   * @const
   * @type {ThunkDispatch}
   */
  const dispatch = useAppDispatch();
  /**
   * Результат выполнения хука useNavigate()
   * метод для изменения страниц в приложении
   * @const
   * @type {NavigateFunction}
   */
  const navigate = useNavigate();
  /**
   * Получаем id филиала из searchParams
   * @const
   */
  const [searchParams] = useSearchParams();
  const accId = searchParams.get('acc_id');
  /**
   * Получаем из localStorage данные пользователя
   * @const
   * @type {ITelegramAuthData}
   */
  const { userData } = storageDb.getUserData();
  /**
   * С помощью хука useAppSelector
   * получаем выбранный филиал и список аккаунтов
   * @const
   */
  const {
    isSalon,
    selectedFilial,
    data: accounts,
    isSalonConsultant,
  } = useAppSelector(getAccountsData);
  /**
   * С помощью хука useAppSelector
   * получаем данные авторизованного пользователя
   */
  const { authUserData } = useAppSelector(getAuthData);
  /**
   * Получаем из localStorage id и имя филиала
   * @const
   */
  const { accId: accIdLS, filialName: filialNameLS } = storageDb.getSelectedFilialData();

  /**
   * Эффект проверяет полученные accIdLS и filialNameLS на содержание в них
   * "noFilial" ошибка которая записывается в LS при безуспешной попытке найти в списке филиалов
   * accId филиала переданного в search параметрах
   * если ошибка есть диспатчится экшен записывающий ошибку в store
   * @function useEffect1
   */
  useEffect(() => {
    if (
      accIdLS &&
      accIdLS === NO_FILIAL_ERROR.TEXT &&
      filialNameLS &&
      filialNameLS === NO_FILIAL_ERROR.TEXT
    ) {
      dispatch(showNoFilialError());
    }
  }, [accIdLS, filialNameLS, accId, dispatch]);

  /**
   * При наличии данных в userData и отсутствии данных в store, записывает их в store
   * @function useEffect2
   */
  useEffect(() => {
    if (userData && !authUserData.id) {
      dispatch(setAuthUserData(userData));
    }
  }, [userData, authUserData.id, dispatch]);

  /**
   * Эффект сравнивает accId полученный из search параметра и сохраненный id выбранного филиала
   * если ID не равны в списке филиалов пытается найти филиал если филиал найден диспатчится экшен
   * который записывает новый филиал в выбранный филиал в store
   * если филиал не найден в store в поля accId и filialName записывается текст ошибки "noFilial"
   * @function useEffect3
   */
  useEffect(() => {
    if (accId && selectedFilial && accId !== selectedFilial.accId) {
      const [filial] = accounts.filter(item => item.accId === accId);
      if (filial) {
        dispatch(
          setSelectedFilial({
            accId,
            branchId: filial.branchId,
            filialName: filial.address,
            isSendOutAccount: filial.sendOutAccount,
            rights: filial.rights,
          }),
        );
        // amplitudeSetUserId(filial.branchId);
      } else {
        dispatch(
          setSelectedFilial({
            branchId: '',
            accId: NO_FILIAL_ERROR.TEXT,
            filialName: NO_FILIAL_ERROR.TEXT,
            isSendOutAccount: false,
            rights: [],
          }),
        );
        navigate(PATH_NAMES.ACCOUNTS);
      }
    }
  }, [accId, selectedFilial, accounts, dispatch, navigate]);

  return (
    <div className='mx-auto'>
      <BaseTemplate
        isError={false}
        isLoading={false}
        isAccessDenied={false}
        isSalon={isSalon || isSalonConsultant}>
        <PrivateRoute>
          <Route
            path={PATH_NAMES.START_PAGE}
            element={
              <Suspense fallback={<Loader className='h-[50Vh]' />}>
                <LoginPageL />
              </Suspense>
            }
          />
          <Route
            path={PATH_NAMES.YCLIENTS_START_PAGE}
            element={
              <Suspense fallback={<Loader className='h-[50Vh]' />}>
                <YclientsStartPageL />
              </Suspense>
            }
          />
          <Route
            path={PATH_NAMES.AUTO_CONNECT}
            element={
              <Suspense fallback={<Loader className='h-[50Vh]' />}>
                <AutoConnectL />
              </Suspense>
            }
          />
          <Route
            path={PATH_NAMES.AUTO_CONNECT_FORM}
            element={
              <Suspense fallback={<Loader className='h-[50Vh]' />}>
                <AutoConnectFormL />
              </Suspense>
            }
          />
          <Route path={PATH_NAMES.ACCOUNTS} element={<AccountsPage />} />
          <Route path={PATH_NAMES.CONNECTION} element={<ConnectionPage />} />
          <Route path={PATH_NAMES.NEW_TEMPLATE} element={<TemplateListPage isEm={false} isTm />} />
          <Route path={PATH_NAMES.TEMPLATE_LIST_ALL} element={<TemplateListPage isEm isTm />} />
          <Route
            path={PATH_NAMES.TEMPLATE_LIST_EVENT}
            element={<TemplateListPage isEm isTm={false} />}
          />
          <Route
            path={PATH_NAMES.TEMPLATE_LIST_TIME}
            element={<TemplateListPage isEm={false} isTm />}
          />
          <Route path={PATH_NAMES.TEMPLATE} element={<TemplatePage />} />
          <Route
            path={PATH_NAMES.TEMPLATE_LIST_STATISTICS}
            element={<TemplateListPage isEm={false} isTm={false} />}
          />
          <Route
            path={PATH_NAMES.TEMPLATE_STAT}
            element={<TemplateListPage isEm={false} isTm={false} />}
          />
          <Route
            path={PATH_NAMES.REVIEWS}
            element={<TemplateListPage isEm={false} isTm={false} />}
          />
          <Route path={PATH_NAMES.NEW_REVIEW} element={<ReviewsPage isNewReviews />} />
          <Route path={PATH_NAMES.REVIEW} element={<ReviewsPage isNewReviews={false} />} />
          <Route path={PATH_NAMES.MAILING} element={<MailingPage />} />
          <Route path={PATH_NAMES.STARTED_MAILING} element={<MailingPage />} />
          <Route path={PATH_NAMES.MAILING_DETAIL_STATS} element={<MailingPage />} />
          <Route path={PATH_NAMES.STATISTICS} element={<MailingPage />} />
          <Route path={PATH_NAMES.INVITE_FRIEND_LIST} element={<InviteFriendPage />} />
          <Route
            path={PATH_NAMES.INVITE_FRIEND_TEMPLATE}
            element={<InviteFriendsActionPage isNewAction={false} />}
          />
          <Route
            path={PATH_NAMES.INVITE_FRIEND_NEW_TEMPLATE}
            element={<InviteFriendsActionPage isNewAction />}
          />
          <Route path={PATH_NAMES.INVITE_FRIEND_STATISTICS} element={<InviteFriendPage />} />
          <Route path={PATH_NAMES.AUTO_RESPONSE} element={<AutoResponsePage />} />
          <Route path={PATH_NAMES.BLACKLIST} element={<BlackListPage />} />
          <Route path={PATH_NAMES.SETTINGS} element={<SettingsPage isSettingsPage />} />
          <Route path={PATH_NAMES.ACCESSES} element={<SettingsPage isSettingsPage={false} />} />
          <Route path={PATH_NAMES.PARTNERS_PROGRAM} element={<PartnersPage />} />
          <Route path={PATH_NAMES.LIST_OF_OPERATION} element={<PartnersPage />} />
          <Route path={PATH_NAMES.PAYMENT_HISTORY} element={<PartnersPage />} />
          <Route path={PATH_NAMES.ADD_LEADS} element={<PartnersPage />} />
          <Route path={PATH_NAMES.CHAT_HISTORY} element={<ChatHistory />} />
          <Route path={PATH_NAMES.PAY} element={<PayPage />} />
          <Route path={PATH_NAMES.PAY_HISTORY} element={<PayPage />} />
          <Route path={PATH_NAMES.PAGE_404} element={<Page404 />} />
        </PrivateRoute>
      </BaseTemplate>
    </div>
  );
});

const App = withErrorBoundary(AppComponent);

export default App;
